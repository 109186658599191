<template>
  <div class="header__inner">
    <div class="header__logo">
      <router-link :to="{ name: 'Landing' }" class="logo-link">
        <img
          src="/static/images/logos/lettersmith-logo.svg"
          alt="Lettersmith"
          class="lettersmith-logo"
        />
        <img
          src="/static/images/logos/lettersmith-mark.svg"
          alt="Lettersmith"
          class="lettersmith-logo-mark"
        />
      </router-link>
    </div>
    <nav class="nav-items">
      <router-link :to="{ name: 'AboutUs' }" class="button-link blue-dark about-us"> About Us </router-link>
      <router-link v-if="!loggedIn" :to="{ name: 'Login' }" class="landing__btn button-secondary">
        Login
      </router-link>
      <router-link v-else :to="{ name: 'Dashboard' }" class="landing__btn button-secondary">
        Dashboard
      </router-link>
    </nav>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      loggedIn: (state) => state.loggedIn,
      user: (state) => state.user,
    }),
  },
};
</script>
