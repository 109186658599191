<template>
  <ls-modal :visible="true" @close="$emit('close')">
    <template #content>
      <h2 id="dialog_label" class="text-med">Join Collection</h2>
      <div class="modal-form-field">
        <label for="join-a-collection-modal-code" class="form-label bold">
          Enter a code to join a collection
        </label>
        <input class="input" id="join-a-collection-modal-code" type="text" v-model="code" />
        <p v-if="errorText" class="error-text">{{ errorText }}</p>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="joinCollection" :disabled="!allowedToJoin">Join</button>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";
import CollectionService from "services/collections";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    "ls-modal": Modal,
  },
  data() {
    return {
      retrieving: false,
      code: "",
      errorText: null,
    };
  },
  computed: {
    ...mapState("template", { allTemplates: "allTemplates" }),
    allowedToJoin() {
      let retriving = this.retrieving;
      let code = this.code;
      return !retriving && code != "";
    },
  },
  watch: {
    code() {
      this.errorText = null;
    },
  },
  methods: {
    ...mapActions("template", ['getTemplates', 'getAllTemplateChecklistItems']),
    ...mapActions("collection", ['getCollections',]),
    async joinCollection() {
      this.retrieving = true;
      try {
        let response = await CollectionService.join(this.code);
        if (response.status == 200) {
          await Promise.all([this.getTemplates(), this.getCollections(), this.getAllTemplateChecklistItems()]);
        }
        this.$emit("joined-collection", response.data);
      } catch (response) {
        if (response.status == 404) {
          this.errorText = "Collection not found. Verify that your code is correct.";
        } else if (response.status == 429) {
          this.errorText = "Too many attempts to join. Please try again later.";
        }
      } finally {
        this.retrieving = false;
      }
    },
  },
};
</script>
