<template>
  <div class="container column flex vc hc text-small">
    <p v-if="errorMessage !== null">{{ message }}</p>
    <p v-else>{{ errorMessage }}</p>
  </div>
</template>

<script>
import AuthService from "services/auth";

export default {
  props: {
    message: {
      type: String,
      default: "Please wait…",
    },
  },
  data() {
    return {
      errorMessage: null,
    };
  },
  mounted() {
    let code = this.$route.params.code;
    AuthService.confirmRedirect(code)
      .then((response) => {
        if (response.data.destination == "Template") {
          this.$router.push({ name: response.data.destination, params: { templateId: response.data.template_id } });      
        } else {
          this.$router.push({ name: response.data.destination });
        }
      })
      .catch((error) => {
        this.errorMessage = error.toString();
      });
  },
};
</script>
