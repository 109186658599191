<template>
  <main id="main" class="draft-view-container" v-if="email">
    <finalize-modal-compose-email
      :visible="true"
      :sender-email="senderEmail"
      :email="email"
      @send-email="sendEmail"
      @close="goBackToDrafting"
    ></finalize-modal-compose-email>
  </main>
</template>

<script>
import { computed, ref } from "@vue/composition-api";

import EmailService from "services/email";
import { useRouter } from "shared/composables/useRouter";
import { useStore } from "shared/composables/useStore";

import FinalizeModalComposeEmail from "./FinalizeModalComposeEmail.vue";

export default {
  name: "FinalizeSendEmailView",

  components: {
    FinalizeModalComposeEmail,
  },

  setup() {
    const recipients = ref([]);
    const emailAddressOptions = ref([]);

    const router = useRouter();
    const store = useStore();

    const email = computed(() => store.state.drafting.currentEmail);
    const senderEmail = computed(() => store.state.user.email);

    const addRecipient = (newAddress) => {
      recipients.value.push(newAddress);
    };

    const sendEmail = ({ recipients, cc, bcc }) => {
      const sentRecipients = [...recipients, ...cc, ...bcc];
      EmailService.send(email.value.id, recipients, cc, bcc)
        .then(() => {
          goBackToDrafting();
          store.dispatch(
            "showSnackbar",
            `Email ${email.value.subject} sent to ${sentRecipients.length} recipient${
              sentRecipients.length !== 1 ? "s" : ""
            }`
          );
        })
        .catch(() => {
          store.dispatch("showSnackbar", "❌ Failed to send email.");
        });
    };

    const goBackToDrafting = () => {
      router.push({
        name: "Draft",
        params: { templateId: email.value.template_id, slug: email.value.slug },
      });
    };

    return {
      recipients,
      emailAddressOptions,
      email,
      senderEmail,
      addRecipient,
      sendEmail,
      goBackToDrafting,
    };
  },
};
</script>

<style lang="scss" scoped></style>
