import DOMPurify from "dompurify";

export function truncateHTML(content: string, lines = 2): string {
  const clean = DOMPurify.sanitize(content, {
    ALLOWED_TAGS: ["h1", "h2", "p", "b", "em", "u", "ul", "ol", "li"],
    RETURN_DOM: true,
  });

  const host = document.createElement("p");
  for (let index = 0; index < lines; index++) {
    if (clean.childElementCount > index) {
      host.appendChild(clean.childNodes[index]);
    }
  }
  return host.innerHTML;
}

export function convertToPlainText(html: string): string {
  const tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;
  return tempDivElement.textContent || tempDivElement.innerText || "";
}

function truncateStringAtIndex(content: string, index: number, ellipsis = '…'): string {
  return `${content.slice(0, index)}${ellipsis}`;
}

function truncateStringAtWordBoundary(content: string, maxLength: number, ellipsis = '…'): string {
  const longestContent = content.substring(0, content.lastIndexOf(" ", maxLength));
  if (longestContent.length < (maxLength * 0.8)) {
    return truncateStringAtIndex(content, maxLength, ellipsis);
  }
  return `${longestContent}${ellipsis}`;
}

interface TruncateStringOptions {
  ellipsis: string;
  breakAtWordBoundary: boolean;
}

const DEFAULT_TRUNCATE_OPTIONS = { ellipsis: "…", breakAtWordBoundary: true };

export function truncateString(content: string, maxLength: number, { ellipsis, breakAtWordBoundary }: TruncateStringOptions = DEFAULT_TRUNCATE_OPTIONS): string {
  if (content.length <= maxLength) {
    return content
  } else if (breakAtWordBoundary) {
    return truncateStringAtWordBoundary(content, maxLength - 1, ellipsis);
  } else {
    return truncateStringAtIndex(content, maxLength - 1, ellipsis);
  }
}
