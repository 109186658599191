<template>
  <div :id="animationContainerId" />
</template>

<script>
import lottie from 'lottie-web'

export default {
  computed: {
    animationContainerId() {
      return `lottie-animation-${this._uid}`
    },
    assetsPath() {
      const fileParts = this.jsonPath.split('/')
      fileParts.pop()
      fileParts.push(this.relAssetsPath)
      return fileParts.join('/')
    },
  },
  props: {
    jsonPath: {
      type: String,
      required: true,
    },
    relAssetsPath: {
      type: String,
      default: 'images/',
    },
    lottieOptions: {
      type: Object,
      default: () => {},
    },
    speed: {
      type: Number,
      default: 1.0,
    },
  },
  methods: {
    prependPublicPath(path) {
      const publicPath = '/static' 
      const basePath = publicPath.endsWith('/')
        ? publicPath.slice(0, -1)
        : publicPath
      const extPath = path.startsWith('/') ? path.slice(1) : path
      return `${basePath}/${extPath}`
    },
  },
  mounted() {
    const animation = lottie.loadAnimation({
      container: document.getElementById(this.animationContainerId),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: this.prependPublicPath(this.jsonPath),
      assetsPath: this.prependPublicPath(this.assetsPath),
      ...this.lottieOptions,
    })
    animation.setSpeed(this.speed)
  },
}
</script>

<style></style>
