import Home from "./views/Home.vue";
import AddLtiRegistration from "./views/AddLtiRegistration.vue";
import EditLtiDeployment from "./views/EditLtiDeployment.vue";
import EditLtiRegistration from "./views/EditLtiRegistration.vue";
import LtiDeployments from "./views/LtiDeployments.vue";
import AddLtiDeployment from "./views/AddLtiDeployment.vue";
import AdminModule from "./AdminModule.vue";

export const routes = [
  {
    path: "/admin",
    component: AdminModule,
    children: [
      {
        path: "",
        name: "AdminHome",
        component: Home,
      },
      {
        path: "add",
        name: "AddLtiRegistration",
        component: AddLtiRegistration,
      },
      {
        path: "registration/:registrationId/edit",
        name: "EditLtiRegistration",
        component: EditLtiRegistration,
      },
      {
        path: "registration/:registrationId/deployments",
        name: "LtiDeployments",
        component: LtiDeployments,
      },
      {
        path: "registration/:registrationId/deployments/add/",
        name: "AddLtiDeployment",
        component: AddLtiDeployment,
      },
      {
        path: "registration/:registrationId/deployments/:id/edit/",
        name: "EditLtiDeployment",
        component: EditLtiDeployment,
      },
    ]
  }
];
