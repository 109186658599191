<template>
  <div ref="landing" class="landing external-page">
    <div class="background" aria-hidden="true">
      <img src="/static/images/landing-page/hexagon2.svg" alt="" class="hexagon1" />
      <img src="/static/images/landing-page/hexagon3.svg" alt="" class="hexagon2" />
    </div>
    <header class="header external" ref="header">
      <external-header></external-header>
    </header>
    <main id="main" class="container">
      <section class="hero external-section">
        <div class="hero-inner">
          <h1 class="title serif">Write with Purpose</h1>
          <div class="text-med">
            Lettersmith supports both <span class="bold color-text">students</span> and
            <span class="bold color-text">instructors</span> with writing assignments.
          </div>
          <br />
          <div class="text-med">
            <span class="bold color-text">Features include</span> checklists, writing goals, writing
            tips, real examples to annotate, expert and peer feedback channels.
          </div>
          <br />
          <div class="explore">
            <router-link custom :to="{ name: 'Signup' }" v-slot="{ navigate }">
              <button class="button" @click="navigate">Get Started</button>
            </router-link>
            <a class="button-link" href="/about">Learn More</a>
          </div>
        </div>
        <div class="landing-animation">
          <Lottie json-path="/lottie/landing/data.json" />
        </div>
      </section>
      <section class="feature external-section">
        <h2 class="sub-title serif">Features</h2>
        <div class="feature-subtitle text-med">
          Instructors can customize Lettersmith based on the types of writing they teach.
        </div>
        <div class="external-subsection feature-section" data-aos="fade-up">
          <div class="feature-description">
            <img
              src="/static/images/landing-page/tags-icon.svg"
              alt="tags icon"
              class="feature-icon"
            />
            <div class="feature-header">
              <h3 class="feature-title text-large">Tags</h3>
              <div class="feature-subheader text-med">
                Set clear goals for writing assignments and create tags to annotate the works.
              </div>
            </div>
          </div>
          <img
            src="/static/images/landing-page/tags-image.png"
            alt="tags image"
            class="feature-image"
          />
        </div>
        <div class="external-subsection feature-section right" data-aos="fade-up">
          <img
            src="/static/images/landing-page/examples-image.png"
            alt="tags image"
            class="feature-image"
          />
          <div class="feature-description right">
            <img
              src="/static/images/landing-page/examples-icon.svg"
              alt="tags icon"
              class="feature-icon"
            />
            <div class="feature-header">
              <h3 class="feature-title text-large">Real Examples</h3>
              <div class="feature-subheader text-med">
                Share real world, exemplary writing for students to read and annotate.
              </div>
            </div>
          </div>
        </div>
        <div class="external-subsection feature-section" data-aos="fade-up">
          <div class="feature-description">
            <img
              src="/static/images/landing-page/feedback-icon.svg"
              alt="tags icon"
              class="feature-icon"
            />
            <div class="feature-header">
              <h3 class="feature-title text-large">Feedback</h3>
              <div class="feature-subheader text-med">
                Encourage writers to seek private, scaffolded feedback from peers and instructors.
              </div>
            </div>
          </div>
          <img
            src="/static/images/landing-page/feedback-image.png"
            alt="tags image"
            class="feature-image"
          />
        </div>
      </section>
      <section class="workspace external-section" data-aos="fade-up">
        <h2 class="sub-title serif">Details</h2>
        <div class="external-subsection">
          <h3 class="text-large">Writing Templates</h3>
          <div class="content-subheader text-med">
            Lettersmith supports professional and academic writing by allowing individuals to create
            and share custom drafting templates. These templates can be used as guides for others.
            It informs them what should be included in their writing, examples to reflect upon, and
            ability to share their draft for feedback with others.
          </div>
        </div>
        <div class="info-row">
          <div class="info-item">
            <div class="info-header">
              <img
                src="/static/images/landing-page/hex-blue.svg"
                alt="email icon"
                class="info-icon"
              />
              <h4 class="info-name">Cold Email</h4>
            </div>
            <div class="info-content text-med">
              Reaching out to someone new? Write an email that builds trust an motivates them to
              respond.
            </div>
          </div>
          <div class="info-item">
            <div class="info-header">
              <img
                src="/static/images/landing-page/hex-orange.svg"
                alt="cover letter icon"
                class="info-icon"
              />
              <h4 class="info-name">Cover Letter</h4>
            </div>
            <div class="info-content text-med">
              Applying for a new job? Describe yourself in a way that resonates with the job
              posting.
            </div>
          </div>
          <div class="info-item">
            <div class="info-header">
              <img
                src="/static/images/landing-page/hex-teal.svg"
                alt="essay icon"
                class="info-icon"
              />
              <h4 class="info-name">Customize Your Own</h4>
            </div>
            <div class="info-content text-med">
              Do you have an idea for a custom writing template that could help your students or
              others write to a specific audience or objective? Design a custom template, add tags,
              include writing examples, and share your template with others.
            </div>
          </div>
        </div>
        <div class="external-subsection">
          <h3 class="text-large">Ways to organize</h3>
          <div class="content-subheader text-med">
            Lettersmith allows you to create a single writing template or multiple, and a collection
            allows you to organize those templates.
          </div>
        </div>
        <div class="info-row instructor">
          <div class="info-item">
            <div class="info-header">
              <img
                src="/static/images/landing-page/hex-pink.svg"
                alt="template icon"
                class="info-icon"
              />
              <h4 class="info-name">Template</h4>
            </div>
            <div class="info-content text-med">
              Creating an assignment or professional writing support? Create a template and
              customize the content to your liking.
            </div>
          </div>
          <div class="info-item">
            <div class="info-header">
              <img
                src="/static/images/landing-page/hex-darkblue.svg"
                alt="collection icon"
                class="info-icon"
              />
              <h4 class="info-name">Collection</h4>
            </div>
            <div class="info-content text-med">
              Have you created multiple templates for the same audience? Collections allow you to
              organize your writing templates and share with others.
            </div>
          </div>
        </div>
      </section>
    </main>
    <section class="sub-footer" aria-labelledby="subfooter-heading">
      <h2 class="sub-title serif" id="subfooter-heading">Create a free account</h2>
      <h3 class="subtitle text-med">Easily create your own writing templates or join others.</h3>
      <router-link custom :to="{ name: 'Signup' }" v-slot="{ navigate }">
        <button class="landing__btn button" @click="navigate">Sign Up</button>
      </router-link>
    </section>
    <et-footer></et-footer>
  </div>
</template>

<script>
import Lottie from "./Lottie.vue";

export default {
  components: { Lottie },
};
</script>
