<template>
  <li>{{ email }} - {{ status || "pending" }}</li>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
};
</script>
