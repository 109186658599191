<template>
  <examples-admin :template="template" />
</template>

<script>
import ExamplesAdmin from "../components/ExamplesAdmin.vue";

export default {
  components: {
    ExamplesAdmin,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
};
</script>
