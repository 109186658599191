import axios, { AxiosResponse } from "axios";
import AuthService from "./auth";

const apiRoute = "/api/template-checklist-groups/";

export interface TemplateChecklistGroupPayload {
  title: string;
  template_id: number;
}

export interface TemplateChecklistGroup {
  id: number;
  title: string;
  template_id: number;
}

export default {
  list(): Promise<AxiosResponse<TemplateChecklistGroup[]>> {
    return axios.get(apiRoute);
  },

  listByTemplate(
    templateId: string | number,
    isGuest = false
  ): Promise<AxiosResponse<TemplateChecklistGroup[]>> {
    const axios = AuthService.createCustomHeaderAxios(isGuest);
    return axios.get(`${apiRoute}?template_id=${templateId}`);
  },

  store(data: TemplateChecklistGroupPayload): Promise<AxiosResponse<TemplateChecklistGroup>> {
    return axios.post(apiRoute, data);
  },

  show(id: number | string): Promise<AxiosResponse<TemplateChecklistGroup>> {
    return axios.get(`${apiRoute}${id}/`);
  },

  update(
    id: number | string,
    data: TemplateChecklistGroupPayload
  ): Promise<AxiosResponse<TemplateChecklistGroup>> {
    return axios.put(`${apiRoute}${id}/`, data);
  },

  reorder(data: TemplateChecklistGroupPayload): Promise<AxiosResponse<TemplateChecklistGroup[]>> {
    return axios.post(`${apiRoute}reorder/`, data);
  },

  destroy(id: number | string): Promise<AxiosResponse<void>> {
    return axios.delete(`${apiRoute}${id}/`);
  },
};
