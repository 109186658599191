<template>
  <li v-if="exists" class="loose-tag tagged">
    <div class="checklist-header">
      <button class="checklist-button">
        <i :class="['checklist-icon', `highlight-${tag.color}`, 'tagged']"></i>
        <span class="button-link">
          {{ tag.title }}
        </span>
        <span v-if="tag.required" class="required" aria-label="(required)">*</span>
      </button>
    </div>
    <div class="checklist-details border-red">
      <p class="checklist-description">{{ tag.description }}</p>
    </div>
  </li>
  <li class="disabled-tag" v-else>{{ tag.title }}</li>
</template>

<script>
export default {
  name: "ExampleTagChecklistItem",
  props: {
    tag: {
      type: Object,
      required: true,
    },
    exampleTag: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    exists() {
      return this.exampleTag != null;
    },
  },
  methods: {
    highlight() {
      this.$emit("highlight", this.exampleTag);
    },
  },
};
</script>
