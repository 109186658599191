<template>
  <div class="all-submissions card-container" v-if="submissions.length > 0">
    <div class="table-header">
      <span class="row-item smaller">
        Name
        <!-- <button class="sort-submissions button-icon">
          <img alt="Toggle examples" src="/static/images/icons/arrow-dark-blue.png" />
        </button> -->
      </span>
      <span class="row-item">Submission</span>
      <span class="row-item smaller">
        Time
        <!-- <button class="sort-submissions button-icon">
          <img alt="Toggle examples" src="/static/images/icons/arrow-dark-blue.png" />
        </button> -->
      </span>
      <span class="row-item smaller">Actions</span>
    </div>
    <div class="submissions">
      <submission-list-item
        v-for="submission in submissions"
        :key="submission.id"
        :submission="submission"
        @view-full-submission="openSubmissionModal($event)"
        @delete-submission="openDeleteSubmissionModal($event)"
      />
    </div>

    <submission-detail-modal
      v-if="viewFullSubmission && currentSubmission"
      :label-for-title="labelForTitle"
      :label-for-content="labelForContent"
      @close="closeSubmissionModal()"
    >
      <template #message>
        <h2 id="dialog_label" class="text-med">Submission Details</h2>
      </template>
      <template #date>
        {{ currentSubmission.created_at }}
      </template>
      <template #username>
        {{ currentSubmission.username }}
      </template>
      <template #subject>
        <div class="input">
          {{ currentSubmission.title }}
        </div>
      </template>
      <template #submission>
        <div class="input submission-full-content" v-html="currentSubmission.content"></div>
      </template>
    </submission-detail-modal>
    <confirmation-dialog
      v-if="showConfirmDelete"
      accept-label="Delete"
      :danger-mode="true"
      @cancel="showConfirmDelete = false"
      @accept="completeSubmisionDelete()"
    >
      <template #message>
        <h2 class="text-med">
          Are you sure you want to delete submission "{{ currentSubmission.title }}" from
          {{ currentSubmission.username }} ?
        </h2>
      </template>
    </confirmation-dialog>
  </div>
  <div class="empty-state" v-else>
    <img alt="" class="empty-image" src="/static/images/empty-states/empty-submissions.png" />
    <p class="text-med serif">No submissions found!</p>
    <p>Once submissions are submitted, they will appear here.</p>
  </div>
</template>

<script>
import dayjs from "dayjs";

import ConfirmationDialog from "shared/components/dashboard/ConfirmationDialog.vue";

import SubmissionListItem from "./SubmissionListItem.vue";
import SubmissionDetailModal from "shared/components/submission/SubmissionDetailModal.vue";

export default {
  name: "SubmissionList",
  data() {
    return {
      viewFullSubmission: false,
      currentSubmission: null,
      showConfirmDelete: false,
      submissionToDeleteId: null,
    };
  },
  components: {
    SubmissionListItem,
    SubmissionDetailModal,
    ConfirmationDialog,
  },
  props: {
    submissions: {
      type: Array,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
  },
  computed: {
    labelForTitle() {
      const docType = this.template.document_type;
      if (docType === "email") {
        return "Subject";
      }
      return "Title";
    },
    labelForContent() {
      const docType = this.template.document_type;
      if (docType === "email") {
        return "Body";
      }
      return "Content";
    },
  },
  methods: {
    openSubmissionModal(submission) {
      this.currentSubmission = {
        ...submission,
        created_at: dayjs(submission.created_at).format("MMM, D YYYY [at] h:mma"),
      };
      this.viewFullSubmission = true;
    },

    closeSubmissionModal() {
      this.currentSubmission = null;
      this.viewFullSubmission = false;
    },

    openDeleteSubmissionModal(submission) {
      this.currentSubmission = submission;
      this.submissionToDeleteId = submission.id;
      this.showConfirmDelete = true;
    },

    completeSubmisionDelete() {
      this.$emit("delete-submission", this.submissionToDeleteId);
      this.showConfirmDelete = false;
    },
  },
};
</script>
