<template>
  <ls-modal @close="$emit('close')" :visible="true" :large="true" class="onboarding-modal">
    <template #content>
      <h2 id="dialog_label" class="text-med">Template Overview: {{ template.name }} </h2>
      <div v-if="$can('change', template)">
        <div class="onboarding-section">
          <h3 class="onboarding-title bold">Get Started</h3>
          <ul class="no-list-styling">
            <li>Begin by creating a <b>Checklist</b> of tags that will guide your students as they write.</li>
            <li>Add <b>Examples</b> to inspire your students' writing and help them see how others use tags.</li>
            <li>Decide on your submission <b>Settings.</b></li>
            <li>Share your template by adding <b>Members.</b></li>
            <li>Look for tooltips to help guide you along your way. <img alt="Tooltip Icon" class="icon" src="/static/images/icons/tooltip.png" /></li>
          </ul>
        </div>
      </div>
      <div v-else>
        <div v-if="template.description" class="onboarding-section">
          <h3 class="onboarding-title bold">Description</h3>
          <p v-html="template.description"></p>
        </div>
        <div class="onboarding-section">
          <h3 class="onboarding-title bold">Get Started</h3>
          <ul class="no-list-styling">
            <li>Start drafting by clicking the "Compose New Draft" button in the <b>Drafts</b> tab. Use the checklist of tags to help guide your writing.</li>
            <li>Get inspiration from the <b>Examples</b> tab.</li>
            <li>Look for tooltips to help guide you along your way. <img alt="Tooltip Icon" class="icon" src="/static/images/icons/tooltip.png" /></li>
          </ul>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="$emit('close')">Got It</button>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
};
</script>
