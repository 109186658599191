<template>
  <ls-modal @close="$emit('close')" :visible="true" :large="true">
    <template #content>
      <h2 class="text-med">Share Template Link</h2>
      <div class="modal-form-field">
        <div class="create-link-section">
          <label for="create-link" class="form-label bold">Create Shareable Link</label>
            <label class="setting-item">
              <input
                type="checkbox"
                name="can_join_link"
                @click="saveSettings"
                v-model="features.can_join_link"
              >
              Create a shareable link that will enable users to automatically access and join your template at the participant level
            </label>
        </div>
      </div>
      <div class="modal-form-field shareable-link-section" v-if="features.can_join_link">
        <label for="shareable-link" class="form-label bold">Shareable Link</label>
        <p class="description-text">
          If your template is ready to share, copy the link below and share it with participants
          to send them directly to their template. Once they enter the link, they will automatically join
          this template at the "Participant" level.
        </p>
        <div class="input-beside-button">
          <div id="shareable-link" class="input">{{ draftLinkUrl }}</div>
          <button class="button-secondary" @click="copyDraftUrl">Copy Link</button>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="close">Got It</button>
    </template>
  </ls-modal>
</template>

<script>
import { ref, toRefs } from "@vue/composition-api";
import { useStore } from "shared/composables/useStore";
import { mapActions } from "vuex";
import { copyTextToClipboard } from "shared/utils/copy";
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    template: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    const { template } = toRefs(props);
    const store = useStore();
    const initialFeatures = ref(template.value.features ?? {});
    const features = ref({
      ...initialFeatures.value
    });
    const saveSettings = () => {
      features.value.can_join_link = !features.value.can_join_link
      store.dispatch("template/updateTemplateFeatures", {
        template: { ...template.value },
        features: features.value
      });
      initialFeatures.value = { ...features.value };
    };
    return {
      features,
      saveSettings
    }
  },
  computed: {
    draftLinkUrl() {
      return `${location.protocol}//${location.hostname}${location.port ? ":" + location.port :""}` +
             `/join-template-link/${this.template.code}`
    }
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    close() {
      this.$emit("close");
    },
    copyDraftUrl() {
      copyTextToClipboard(this.draftLinkUrl);
      this.showSnackbar("Successfully copied your link!");
    },
  }
}
</script>
