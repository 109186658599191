// ExampleViewSet
import axios from "axios";

const examplesRoute = "/api/examples";

export default {
  create(data) {
    return axios.post(`${examplesRoute}/`, data);
  },

  list(templateId) {
    let params = {};
    if (templateId !== undefined) {
      params["template_id"] = templateId;
    }
    return axios.get(`${examplesRoute}/`, { params });
  },

  get(id) {
    return axios.get(`${examplesRoute}/${id}/`);
  },

  update(id, data) {
    return axios.put(`${examplesRoute}/${id}/`, data);
  },

  reorder(data) {
    return axios.put(`${examplesRoute}/reorder/`, data);
  },

  destroy(id) {
    return axios.delete(`${examplesRoute}/${id}/`);
  },
};
