<template>
  <section aria-labelledby="feedback-heading" class="comment-view" id="comments-panel">
    <div class="draft-view__header">
      <h2 id="feedback-heading" class="text-med serif">Feedback Comments</h2>
    </div>
    <div class="comment-view__body" v-if="comments.length > 0">
      <comment-view-item
        v-for="userComment in comments"
        :key="userComment.id"
        :comment="userComment"
      >
      </comment-view-item>
    </div>
    <div class="comment-view__body empty-state" v-else>
      <img alt="" class="empty-image" src="/static/images/empty-states/empty-comments.png" />
      <p class="text-med serif">No Comments Yet!</p>
      <p class="text-small">
        Share your draft with peers by using the feedback link located directly above your draft.
      </p>
    </div>

    <div v-if="updatedDraftAlert" class="previous-draft-version-alert">
      <div aria-label="Applies to a previous draft version" class="previous-draft-version hover-text-icon"></div>
      Comment applies to a previous draft version.
      <button @click="updatedDraftAlert = false" aria-label="Dismiss alert" class="close-alert hover-text-icon"></button>
    </div>

    <div class="comment-view__footer">
      <textarea
        class="comment-view__input"
        v-model="comment"
        placeholder="Type something..."
        @keyup.enter="submitForm"
        @focus="checkUser"
        ref="textarea"
      >
      </textarea>
      <button class="button" :disabled="!userSet || comment === ''" @click="saveComment">
        Leave Comment
      </button>
    </div>

    <comment-modal-get-username
      :visible="userNameModalVisible"
      @close="userNameModalVisible = false"
      @save="saveUsername"
    >
    </comment-modal-get-username>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

import CommentViewItem from "./CommentItem.vue";
import CommentModalGetUsername from "./CommentModalGetUsername.vue";

export default {
  name: "CommentView",

  components: {
    CommentViewItem,
    CommentModalGetUsername,
  },

  data() {
    return {
      updatedDraftAlert: false,
      comment: "",
      userName: null,
      userNameModalVisible: false,
    };
  },

  computed: {
    ...mapState(["loggedIn", "user"]),
    ...mapState("drafting", {
      email: "currentEmail",
      comments: "feedback",
    }),

    userSet() {
      return this.loggedIn || this.userName;
    },

    useGuestHeader() {
      return this.user === null || (this.user !== null && this.user.is_guest);
    },
  },

  created() {
    if (this.comments.length === 0) {
      this.getDraftWithFeedback(this.email.slug);
    }
    this.updater = setInterval(async () => {
      await this.getDraftWithFeedback(this.email.slug);
    }, 5000);
  },

  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    if (this.updater) {
      clearInterval(this.updater);
    }
  },

  methods: {
    ...mapActions("drafting", {
      saveEmailFeedback: "saveEmailFeedback",
      getDraftWithFeedback: "getDraftWithFeedback",
    }),

    checkUser() {
      if (!this.loggedIn && !this.userName) {
        this.userNameModalVisible = true;
        this.$refs.textarea.blur();
      }
    },

    async saveComment() {
      let payload = {
        email_id: this.email.id,
        message: this.comment,
      };

      payload["user_id"] = this.user.id;
      if (this.useGuestHeader) {
        payload["user_name"] = this.userName;
      }

      await this.saveEmailFeedback({ payload, useGuestHeader: this.useGuestHeader });
      this.comment = "";

      setTimeout(() => {
        this.scrollToEnd();
      }, 300);
    },

    saveUsername(userName) {
      this.userName = userName;
    },

    submitForm(evt) {
      if (evt.keyCode == 13 && !evt.shiftKey) {
        evt.preventDefault();
        this.saveComment();
      }
    },

    scrollToEnd() {
      var container = this.$el.querySelector(".comment-view__body");
      container.scrollTop = container.scrollHeight + 69;
    },
  },
};
</script>
