<template>
  <div class="example-detail">
    <div class="draft-checklist left">
      <h3 class="example-detail-subheader">Tags</h3>
      <example-tag-checklist
        :tags="tags"
        :example-tags="example.tags"
        :highlight="highlight"
        @highlight="updateHighlight"
      />
    </div>
    <div class="right">
      <h3 class="example-detail-subheader">Examples</h3>
      <example-display
        :example="example"
        :highlight="highlight"
        @focus="clearHighlight"
        @blur="clearHighlight"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ExampleDisplay from "./ExampleDisplay.vue";
import ExampleTagChecklist from "./ExampleTagChecklist.vue";

export default {
  components: { ExampleDisplay, ExampleTagChecklist },
  props: {
    example: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      highlight: null,
    };
  },
  computed: {
    ...mapState("template", {
      allTemplates: "allTemplates",
      allChecklistItems: "allChecklistItems",
    }),
    template() {
      let allTemplates = this.allTemplates;
      let example = this.example;
      return allTemplates.find((t) => t.id == example.template_id);
    },
    tags() {
      let template = this.template;
      let allChecklistItems = this.allChecklistItems;

      return allChecklistItems.filter((ci) => ci.template_id == template.id);
    },
  },
  methods: {
    updateHighlight(newHighlight) {
      if (this.highlight && this.highlight == newHighlight) {
        this.highlight = null;
      } else {
        this.highlight = newHighlight;
      }
    },

    clearHighlight() {
      this.highlight = null;
    },
  },
};
</script>
