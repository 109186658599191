<template>
  <modal :visible="visible" @close="close">
    <template #content>
      <div>
        <h2 class="text-med" id="dialog_label">
          {{ title }}
        </h2>
        <p v-if="errorMessage" class="error-text-lighter">
          {{ errorMessage }}
        </p>
        <div class="modal-form-field">
          <label for="confirm-name" class="form-label bold">New Name</label>
          <input
            id="confirm-name"
            class="input"
            :class="{ error: $v.newName.$error }"
            :placeholder="user.name"
            v-model="newName"
            @input="$v.newName.$touch"
          />
        </div>
        <template v-if="$v.newName.$dirty">
          <p v-if="!$v.newName.required">This field is required.</p>
        </template>
      </div>
    </template>
    <template #footer>
      <div>
        <button class="button" :disabled="$v.newName.$invalid" @click="updateName">
          {{ buttonTitle }}
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

import UserService from "services/user";

import Modal from "shared/components/Modal.vue";

export default {
  name: "UpdateNameModal",
  components: { Modal },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: "Update Name",
      errorMessage: null,
      buttonTitle: "Update",
      newName: "",
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions(["updateUserName", "showSnackbar"]),
    close() {
      this.$emit("close");
      this.errorMessage = "";
      this.$v.$reset.call();
    },
    updateName() {
      this.errorMessage = "";
      UserService.updateName({
        name: this.newName,
      })
        .then((response) => {
          if (response.data.success) {
            this.updateUserName(response.data.data.name);
            this.close();
            this.showSnackbar("Successfully updated your name!");
          } else {
            this.errorMessage = response.data.error;
          }
        })
        .catch((err) => {
          this.errorMessage = err.data.error;
        });
    },
  },
  watch: {
    visible(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.newName = "";
      }
    },
  },
  validations: {
    newName: {
      required,
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  flex: 1;
}
input {
  flex: 4;
  &.error {
    border-color: #b93c5f;
  }
}
.warp {
  flex-wrap: wrap;
}
</style>
