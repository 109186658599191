<template>
  <modal :ex-large="true" :visible="visible" @close="close" class="anonymize-email-modal">
    <template #content>
      <div class="submit-example-modal-container">
        <div v-if="email && !showSuccessMessage">
          <h1 class="text-med">Submit your Draft</h1>
          <p class="subject">
            Once submitted, template owner and editors will be able
            to review your submission. It will not be made public.
          </p>

          <div class="modal-form-field">
            <label for="subject" class="form-label bold">Subject</label>
            <input
              id="subject"
              ref="subject"
              class="input black-text"
              :value="email.subject"
              readonly
            />
          </div>

          <div class="modal-form-field">
            <label for="draft-content" class="form-label bold">Draft</label>
            <email-content
              id="draft-content"
              ref="content"
              :email="email"
              :hideSubject="true"
              class="input draft-content"
            ></email-content>
          </div>
        </div>
        <div v-if="showSuccessMessage">
          <h2 class="text-med">Submission Successful</h2>
          <div class="text-small">
            Your draft has been submitted for review.
            Thank you!
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <div class="buttons" v-if="!showSuccessMessage">
          <button class="button" @click="prepareToSubmit">Submit Draft</button>
        </div>
        <div class="flex vc sb" v-if="showSuccessMessage">
          <button class="button full primary fill" @click="submitEmailSubmission">Close</button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapState } from "vuex";

import EmailContent from "shared/components/email/EmailContent.vue";
import Modal from "shared/components/Modal.vue";

import SubmissionsService from "services/submissions";

export default {
  name: "AnonymizeEmailModal",
  components: {
    Modal,
    EmailContent,
  },

  props: {
    originalEmail: {
      type: Object,
      default: null,
    },

    visible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      email: null,
      showSuccessMessage: false,
      payload: null,
    };
  },

  computed: {
    ...mapState({
      template: (state) => state.template.currentTemplate,
      currentDraftTags: (state) => state.drafting.currentDraftTags,
    }),
  },

  created() {
    this.email = Object.assign({}, this.originalEmail);
  },

  methods: {
    close() {
      if (this.showSuccessMessage) {
        this.submitEmailSubmission();
      }
      this.$emit("close");
    },

    prepareToSubmit() {
      this.showSuccessMessage = true;
      this.payload = {
        draft_id: this.email.draft_id,
        template_id: this.email.template_id,
        title: this.email.subject,
        content: this.email.content,
        tags: [...this.currentDraftTags],
      };
      console.log(this.email);
      console.log(this.payload);
    },

    submitEmailSubmission() {
      SubmissionsService.create(this.payload).then(() => {
        this.$emit("completed");
      });
    },
  },
};
</script>
