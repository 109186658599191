import { computed, Ref } from "@vue/composition-api";
import { TemplateChecklistItem } from "services/template-checklist-item";
import { TemplateChecklistGroup } from "services/template-checklist-group";
import { useStore } from "shared/composables";

type DraftingStoreData = {
  loadData: (templateId: number, slug: string) => Promise<void>;
  loadDraft: (slug: string) => Promise<void>;
};

export function useStoreData(): DraftingStoreData {
  const store = useStore();

  // template data
  const allChecklistItems: Ref<Array<TemplateChecklistItem>> = computed(
    () => store.state.template.allChecklistItems
  );

  const allChecklistGroups: Ref<Array<TemplateChecklistGroup>> = computed(
    () => store.state.template.allChecklistGroups
  );

  const currentTemplate = computed(() => store.state.template.currentTemplate);
  const checklistItems = computed(() => store.state.template.checklistItems);
  const checklistGroups = computed(() => store.state.template.checklistGroups);
  const examples = computed(() => store.state.template.examples);

  // drafting data
  const currentDraft = computed(() => store.state.drafting.currentDraft);
  const currentEmail = computed(() => store.state.drafting.currentEmail);

  // data fetching
  const loadAllChecklistItems = async () => {
    if (allChecklistItems.value.length === 0) {
      store.dispatch("showLoader", "Loading checklist items");
      await store.dispatch("template/getAllTemplateChecklistItems");
    }
  };

  const loadAllChecklistGroups = async () => {
    if (allChecklistGroups.value.length === 0) {
      store.dispatch("showLoader", "Loading checklist groups");
      await store.dispatch("template/getAllTemplateChecklistGroups");
    }
  };

  const loadTemplate = async (templateId: number) => {
    if (checklistItems.value.length === 0 || !currentTemplate.value) {
      store.dispatch("showLoader", "Loading template");
      await store.dispatch("template/getTemplate", templateId);
    }
  };

  const loadExamples = async (templateId: number) => {
    if (examples.value.length === 0 || examples.value[0].template_id !== templateId) {
      store.dispatch("showLoader", "Loading examples");
      await store.dispatch("template/getExamples", templateId);
    }
  };

  const loadDraft = async (slug: string) => {
    if (!currentDraft.value?.id) {
      store.dispatch("showLoader", "Loading draft");
      await store.dispatch("drafting/getDraft", slug);
    }
    if (!currentEmail.value?.id) {
      store.dispatch("showLoader", "Loading details");
      await store.dispatch("drafting/initEmail");
    }
  };

  const loadData = async (templateId: number, slug: string) => {
    await loadAllChecklistGroups();
    await loadAllChecklistItems();
    await loadTemplate(templateId);
    await loadExamples(templateId);
    await loadDraft(slug);
    store.dispatch("hideLoader");
  };

  return {
    loadData,
    loadDraft,
  };
}

// export default useStoreData;
