<template>
  <div class="details-container">
    <header>
      <h2 id="members-heading" class="text-med serif">Members</h2>
      <div class="button-container">
        <button
          v-if="canvasAssociations.length > 0"
          class="button-secondary"
          @click="importStudentsFromCanvas"
        >
          Import Students from Canvas
        </button>
        <button
          ref="importButton"
          class="button-secondary"
          @click="showBulkUserUploadModal = true"
          aria-haspopup="dialog"
        >
          Import from CSV
        </button>
        <button
          ref="addMemberButton"
          class="button"
          @click="showAddModal = true"
          aria-haspopup="dialog"
        >
          Add Member
        </button>
      </div>
    </header>
    <div class="table-header">
      <span class="row-item">Name</span>
      <span class="row-item">Email</span>
      <span class="row-item">Permission Level</span>
      <span class="row-item">Actions</span>
    </div>
    <ul class="members no-list-styling" aria-labelledby="members-heading">
      <member-item
        class="table-row"
        v-for="(member, i) in members"
        :key="i"
        :member="member"
        :can-edit="$can(removePermissionName(member), collection)"
        :can-delete="$can(removePermissionName(member), collection)"
        @edit="editMember(member)"
        @delete="deleteMember(member)"
      />
    </ul>
    <member-modal-add
      :visible="showAddModal"
      :member-service="memberService"
      :service-item-id="serviceItemId"
      :allowed-roles="allowedMemberTypesForAddition"
      @close="
        showAddModal = false;
        $refs.addMemberButton.focus();
      "
      @added-member="handleAddedMember"
    ></member-modal-add>
    <member-modal-edit
      v-if="memberToEdit !== null"
      :allowed-roles="allowedMemberTypesForAddition"
      :member="memberToEdit"
      :error-message="editErrorMessage"
      @close="memberToEdit = null"
      @save="handleEditMember(memberToEdit, $event)"
    ></member-modal-edit>
    <member-modal-bulk-upload
      :visible="showBulkUserUploadModal"
      :member-service="memberService"
      :service-item-id="collection.id"
      @close="
        showBulkUserUploadModal = false;
        $refs.importButton.focus();
      "
      @uploaded="getMembers"
    ></member-modal-bulk-upload>
    <confirmation-dialog
      v-if="memberToDelete !== null"
      accept-label="Remove"
      :message="`Are you sure you want to remove member “${memberToDelete.email}”`"
      :error-message="deleteErrorMessage"
      @cancel="memberToDelete = null"
      @accept="handleDeleteMember(memberToDelete)"
    />
  </div>
</template>

<script>
import CollectionService from "services/collections";

import MemberModalAdd from "shared/components/members/MemberModalAdd.vue";
import MemberModalEdit from "shared/components/members/MemberModalEdit.vue";
import MemberModalBulkUpload from "shared/components/members/MemberModalBulkUpload.vue";
import ConfirmationDialog from "shared/components/dashboard/ConfirmationDialog.vue";
import MemberItem from "shared/components/members/MemberItem.vue";

export default {
  components: {
    MemberModalAdd,
    MemberModalBulkUpload,
    MemberModalEdit,
    MemberItem,
    ConfirmationDialog,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAddModal: false,
      collectionMembers: [],
      memberToDelete: null,
      deleteErrorMessage: null,
      memberToEdit: null,
      editErrorMessage: null,
      showBulkUserUploadModal: false,
      canvasAssociations: [],
    };
  },
  created() {
    this.getCanvasInfo();
    this.getMembers();
  },
  computed: {
    members() {
      return this.collectionMembers;
    },
    memberService() {
      return CollectionService;
    },
    serviceItemId() {
      return this.collection.id;
    },
    allowedMemberTypesForAddition() {
      let membershipPermRE = /^groups\.add_templatecollection_(\w+)$/;
      let collection = this.collection;
      let perms = collection.perms;
      let permMatches = perms.map((p) => p.match(membershipPermRE)).filter((m) => m !== null);
      return permMatches.map((m) => m[1]);
    },
  },
  watch: {
    collection() {
      this.getMembers();
    },
  },
  methods: {
    getMembers() {
      CollectionService.listMembers(this.collection.id).then((response) => {
        this.collectionMembers = response.data;
      });
    },

    deleteMember(member) {
      this.memberToDelete = member;
    },
    editMember(member) {
      this.memberToEdit = member;
    },

    handleDeleteMember(member) {
      this.deleteErrorMessage = null;
      CollectionService.destroyMember(this.collection.id, member.id)
        .then(() => {
          this.deleteErrorMessage = null;
          this.memberToDelete = null;
          this.collectionMembers = this.collectionMembers.filter((m) => m.id !== member.id);
        })
        .catch((error) => {
          this.deleteErrorMessage = error.data[0];
        });
    },
    handleEditMember(member, details) {
      this.editErrorMessage = null;

      return CollectionService.updateMember(this.collection.id, member.id, details)
        .then(() => {
          this.editErrorMessage = null;
          return this.getMembers();
        })
        .then(() => {
          this.memberToEdit = null;
        })
        .catch((error) => {
          this.editErrorMessage = error.data[0];
        });
    },
    handleAddedMember(member) {
      const memberExistsAlready = this.collectionMembers.find((m) => m.id === member.id);
      if (!memberExistsAlready) {
        this.collectionMembers.push(member);
      }
    },

    getCanvasInfo() {
      return CollectionService.listCanvasAssociations(this.collection.id).then((response) => {
        if (response.status === 200) {
          this.canvasAssociations = response.data;
        }
      });
    },
    importStudentsFromCanvas() {
      const promises = this.canvasAssociations.map((association) => {
        return CollectionService.importStudentsFromCanvasAssociation(
          this.collection.id,
          association.id
        );
      });

      Promise.all(promises).then(() => {
        this.getMembers();
      });
    },

    removePermissionName(member) {
      let roleNameComponents = member.role.split(" ");
      let roleName = roleNameComponents[roleNameComponents.length - 1].toLowerCase();
      return `remove_${roleName}_of`;
    },
  },
};
</script>
