<template>
  <ul class="no-list-styling">
    <example-tag-checklist-item
      v-for="pair in tagPairs"
      :key="pair.template.id"
      :class="[pair.example == highlight && 'selected']"
      :tag="pair.template"
      :example-tag="pair.example"
      @highlight="$emit('highlight', pair.example)"
    />
  </ul>
</template>

<script>
import ExampleTagChecklistItem from "./ExampleTagChecklistItem.vue";

export default {
  components: {
    ExampleTagChecklistItem,
  },
  props: {
    tags: {
      type: Array,
      required: true,
    },
    exampleTags: {
      type: Array,
      required: true,
    },
    highlight: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    tagPairs() {
      let templateTags = this.tags;
      let exampleTags = this.exampleTags;

      return templateTags.map((tt) => ({
        template: tt,
        example: exampleTags.find((et) => et.template_checklist_item_id == tt.id),
      }));
    },
  },
};
</script>
