<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 id="dialog_label" class="text-med">Edit Your Template</h2>
      <div class="modal-body">
        <div class="modal-form-field">
          <label for="edit-template-modal-template-name" class="form-label bold">
            Template Name
          </label>
          <input
            class="input"
            id="edit-template-modal-template-name"
            type="text"
            v-model="templateName"
          />
        </div>
        <div class="modal-form-field">
          <div class="form-label bold">
            Template Type
          </div>
          <label class="label-with-decription" v-for="type in documentTypes" :key="type.id">
            <div class="input-with-decription">
              <input
                type="radio"
                name="template-type"
                v-model="documentType"
                :value="type.id"
              />
            {{ titleCase(type.id) }}
            </div>
            <div class="description-text" v-if="type.id === 'email'">Users will have the ability to send their drafts as emails directly from Lettersmith</div>
            <div class="description-text" v-else-if="type.id === 'document'">Users will have the ability to export their draft as a Microsoft Word document (.docx)</div>
          </label>
        </div>
        <div class="modal-form-field">
          <div class="form-label bold">Collections</div>
          <div class="collections-list" v-if="collections.length > 0">
            <label
              v-for="collection in collections"
              :key="collection.id"
              :class="{ disabled: !$can('change', collection) }"
            >
              <input
                type="checkbox"
                :value="collection.id"
                v-model="selectedCollectionIds"
                :disabled="!$can('change', collection)"
              />
              {{ collection.name }}
            </label>
          </div>
          <div class="no-collections" v-else>No Collections Found</div>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="handleSave" :disabled="!allowedToSave">Save</button>
    </template>
  </ls-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      templateName: this.template.name,
      documentType: this.template.document_type,
      selectedCollectionIds: this.template.collections,
    };
  },
  computed: {
    ...mapState("collection", { collections: "collections" }),
    ...mapState("template", ["documentTypes"]),
    allowedToSave() {
      let templateName = this.templateName;
      let saving = this.saving;

      return templateName.trim() != "" && !saving;
    },
  },
  methods: {
    ...mapActions("template", { updateTemplate: "updateTemplate" }),
    ...mapActions("collection", { getCollections: "getCollections" }),

    handleSave() {
      this.saving = true;
      let newTemplate = Object.assign({}, this.template);
      newTemplate.name = this.templateName;
      newTemplate.collections = this.selectedCollectionIds;
      newTemplate.document_type = this.documentType;
      this.updateTemplate({ template: newTemplate }).then((template) => {
        this.getCollections().then(() => {
          this.$emit("updated-template", template);
        });
      });
    },
    titleCase(value){
      return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
    },
  },
};
</script>
