import CollectionsOverview from "./components/CollectionsOverview.vue";
import CollectionDetail from "./views/CollectionDetail.vue";
import CollectionDetailMembers from "./views/CollectionDetailMembers.vue";
import CollectionDetailTemplates from "./views/CollectionDetailTemplates.vue";
import CollectionDetailSettings from "./views/CollectionDetailSettings.vue";

const CollectionModule = () => import("./CollectionModule.vue");

export const routes = [
  {
    path: "collections",
    component: CollectionModule,
    name: "Collections",
    redirect: { name: "CollectionOverview" },
    children: [
      {
        path: "",
        component: CollectionsOverview,
        name: "CollectionOverview",
      },
      {
        path: ":collectionId",
        component: CollectionDetail,
        props: (route) => {
          const collectionId = Number.parseInt(route.params.collectionId);
          if (Number.isNaN(collectionId)) {
            return 0;
          }
          return { collectionId };
        },
        children: [
          {
            path: "",
            name: "Collection",
            redirect: "templates",
          },
          {
            path: "templates",
            component: CollectionDetailTemplates,
            name: "CollectionTemplates",
            props: (route) => {
              const collectionId = Number.parseInt(route.params.collectionId);
              if (Number.isNaN(collectionId)) {
                return 0;
              }
              return { collectionId };
            },
          },
          {
            path: "members",
            component: CollectionDetailMembers,
            name: "CollectionMembers",
          },
          {
            path: "settings",
            component: CollectionDetailSettings,
            name: "CollectionSettings",
          },
        ],
      },
    ],
  },
];
