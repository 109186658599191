<template>
  <div class="flex column">
    <div  class="quill-draft-editor">
      <div ref="quillEditor"></div>
      <div id="quill-toolbar">
        <span class="ql-button-group">
          <button aria-label="Bold" class="ql-bold">Bold</button>
          <button aria-label="Italic" class="ql-italic">Italic</button>
          <button aria-label="Underline" class="ql-underline">Underline</button>
        </span>
        <span class="button-group-separator"></span>
        <span class="ql-button-group">
          <button aria-label="Header 1" class="ql-header" value="1">Header 1</button>
          <button aria-label="Header 2" class="ql-header" value="2">Header 2</button>
        </span>
        <span class="button-group-separator"></span>
        <span class="ql-button-group">
          <button aria-label="Link" class="ql-link" type="button"></button>
        </span>
        <span class="ql-button-group">
          <button aria-label="Image" class="ql-image" type="button"></button>
        </span>
        <span class="button-group-separator"></span>
        <span class="ql-button-group">
          <button aria-label="Ordered List" class="ql-list" value="ordered"></button>
          <button aria-label="Bullet List" class="ql-list" value="bullet"></button>
        </span>
        <span class="button-group-separator"></span>
        <span class="ql-button-group">
          <button aria-label="Clear Formatting" class="ql-clean" type="button"></button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from "libs/editors";

export default {
  name: "RichTextEditor",

  components: {},

  props: {
    html: {
      type: String,
      required: false,
      default: "",
    },

    placeholder: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#quill-toolbar",
          imageEdit: {
            modules: ["Resize", "DisplaySize", "Toolbar", "Delete"],
          },
        },
        placeholder: this.placeholder,
      },
      editor: null,
    };
  },

  mounted() {
    this.initEditor();
  },

  methods: {
    // editor init and setup
    initEditor() {
      const initConfig = {
        container: this.$refs.quillEditor,
        enabled: true,
        options: this.editorOption,
        html: this.html,
        tags: [],
      };
      this.editor = new QuillEditor(initConfig);

      this.editor.on("input", (e) => {
        this.$emit("input", e);
      });

      this.editor.on("selected", (e) => {
        const { range } = e;
        const selection = {
          start: range.index,
          end: range.index + range.length,
          content: this.editor.getTextByRange(range),
        };
        this.$emit("selected", selection);
        this.$emit("select", range);
      });

      this.editor.on("blur", () => {
        this.$emit("blur");
      });

      this.editor.on("focus", () => {
        this.$emit("focus");
      });

      this.editor.on("text-change", (e) => {
        // Only updates with source==="user" will trigger this event
        this.$emit("text-change", e);
      });
    },

    select(start, end) {
      this.editor.select(start, end);
    },

    getTextByRange(range) {
      return this.editor.getTextByRange(range);
    },

    replaceContent(range, replacedText) {
      this.editor.replaceText(range, replacedText);
    },

    blur() {
      this.editor.blur();
    },
  },
};
</script>
