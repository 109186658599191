<template>
  <ls-modal @close="$emit('close')" :visible="true" :large="true">
    <template #content>
      <h2 class="text-med">{{ registration.name }} Details</h2>
      <div class="modal-form-field">
        <label for="" class="form-label bold">Target Link URI:</label>
        <div class="input-beside-button">
          <div id="" class="input">{{ registration.target_link_uri }}</div>
          <button class="button-secondary" @click="copyTargetLinkUri">Copy</button>
        </div>
      </div>
      <div class="modal-form-field">
        <label for="" class="form-label bold">OpenID Connect Initiation URL:</label>
        <div class="input-beside-button">
          <div id="" class="input">{{ registration.login_init_url }}</div>
          <button class="button-secondary" @click="copyLoginInitUrl">Copy</button>
        </div>
      </div>
      <div class="modal-form-field">
        <label for="" class="form-label bold">Public JWK URL:</label>
        <div class="input-beside-button">
          <div id="" class="input">{{ registration.jwks_url }}</div>
          <button class="button-secondary" @click="copyJwksUrl">Copy</button>
        </div>
      </div>
      <div class="modal-form-field">
        <label for="" class="form-label bold">Redirect URIs:</label>
        <div class="input-beside-button">
          <div id="" class="input">{{ registration.redirect_uri }}</div>
          <button class="button-secondary" @click="copyRedirectUri">Copy</button>
        </div>
      </div>
    </template>
  </ls-modal>
</template>

<script>
import { mapActions } from "vuex";
import { copyTextToClipboard } from "shared/utils/copy";
import Modal from "shared/components/Modal.vue";

export default {
  name: "RegistratinDetailsModal",
  components: {
    "ls-modal": Modal,
  },
  props: {
    registration: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    copyTargetLinkUri() {
      copyTextToClipboard(this.registration.target_link_uri);
      this.showSnackbar("Successfully copied target link URI!");
    },
    copyLoginInitUrl() {
      copyTextToClipboard(this.registration.login_init_url);
      this.showSnackbar("Successfully copied OpenID Connect initiation URL!");
    },
    copyJwksUrl() {
      copyTextToClipboard(this.registration.jwks_url);
      this.showSnackbar("Successfully copied Public JWK URL!");
    },
    copyRedirectUri() {
      copyTextToClipboard(this.registration.redirect_uri);
      this.showSnackbar("Successfully copied redirect URI!");
    }
  }
};
</script>
