<template>
  <ls-modal @close="$emit('close')" :visible="true" :large="true" class="onboarding-modal">
    <template #content>
      <h2 class="text-med">Examples</h2>
      <div class="modal-body">
        <div class="onboarding-section">
          <h3 class="onboarding-title bold">Description</h3>
          <ul class="no-list-styling">
            <li>Add Examples to inspire your students' writing and help them see how others tag drafts.</li>
          </ul>
        </div>
        <div class="onboarding-section">
          <h3 class="onboarding-title bold">Get Started</h3>
          <ul class="no-list-styling">
            <li>Click the “Compose New Example” button to begin adding an example.</li>
            <li>Tag your example with the Checklist items you have created.</li>
          </ul>
        </div>
        <div class="onboarding-section" v-if="template.features.can_submit_example">
          <h3 class="onboarding-title bold">Participant Submitted Examples</h3>
          <ul class="no-list-styling">
            <li>You’ve enabled your participants to submit their draft as examples. To turn this feature off, visit your <b>Settings</b> tab.</li>
            <li>Participant submitted examples will show up within this tab so you can review, edit, and approve them.</li>
          </ul>
        </div>
        <div class="onboarding-section" v-if="template.features.can_submit_example">
          <h3 class="onboarding-title bold">Approval</h3>
          <ul class="no-list-styling">
            <li>Examples you approve will be visible to participants as they draft.</li>
          </ul>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="$emit('close')">Got It</button>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
};
</script>
