<template>
  <div class="screen-loader__wrapper" v-show="loader.enabled">
    <div class="screen-loader__container">
      <img alt="Loader" class="logo-loading" src="/static/images/logos/logo-loading.gif" />
      <p class="screen-loader__message text-med bold">
        {{ loader.message }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState(["loader"]),
};
</script>
