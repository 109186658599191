<template>
  <li>
    <button class="item" @click="onClick">
      <slot></slot>
    </button>
  </li>
</template>

<script>
export default {
  methods: {
    onClick(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style scoped>
.item {
  display: block;
  padding: 8px 24px 8px 12px;
  color: var(--primary-blue-dark);
  cursor: pointer;
  font-weight: 600;
  font-size: 100%;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
}

.item:hover {
  background-color: var(--primary-blue-dark);
  color: var(--white);
}
</style>
