<template>
  <div class="details-container">
    <header>
      <div class="header-container">
        <h2 class="text-med serif">Submissions</h2>
        <onboarding-button
          @click.native="showOnboardingModal = true"
          ref="onboardingButton"
        ></onboarding-button>
      </div>
      <!-- <button class="button" @click="exportSubmissions">Export All</button> -->
    </header>
    <submission-list
      :submissions="submissions"
      :template="currentTemplate"
      @delete-submission="deleteSubmission($event)"
    ></submission-list>
    <template-submissions-modal
      v-if="showOnboardingModal"
      :template="template"
      @close="closeOnboarding"
    ></template-submissions-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import SubmissionsService from "services/submissions";

import SubmissionList from "../components/SubmissionList.vue";
import OnboardingButton from "../components/OnboardingButton.vue";
import TemplateSubmissionsModal from "shared/components/template/TemplateSubmissionsModal.vue";

export default {
  name: "TemplateDetailSubmissions",
  components: {
    SubmissionList,
    OnboardingButton,
    TemplateSubmissionsModal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submissions: [],
      loading: false,
      showOnboardingModal: false,
    };
  },
  computed: {
    ...mapState(["token"]),
    ...mapState("template", ["currentTemplate"]),
  },
  mounted() {
    this.getSubmissions();
  },
  watch: {
    template() {
      this.getSubmissions();
    },
  },
  methods: {
    ...mapActions(["showSnackbar"]),

    closeOnboarding() {
      this.showOnboardingModal = false;
      this.$refs.onboardingButton.$el.focus();
    },

    getSubmissions() {
      this.loading = true;
      return SubmissionsService.list(this.template.id)
        .then((response) => {
          this.submissions = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteSubmission(submissionId) {
      SubmissionsService.destroy(submissionId)
        .then(() => {
          let deletedSubmission = undefined;
          this.submissions = this.submissions.filter((s) => {
            if (s.id === submissionId) {
              deletedSubmission = s;
            }
            return s.id !== submissionId;
          });
          this.showSnackbar(`Deleted submission of ${deletedSubmission?.username}!`);
        })
        .catch(() => {
          this.showSnackbar("❌ Failed to delete submission.");
        });
    },

    // NOTE: This is not exporting the submissions but drafts and examples.
    // We might need to update and use it at some point.
    // exportSubmissions() {
    //   TemplateService.export(this.template.id, this.token).then((response) => {
    //     const blob = new Blob([response.data], { type: "text/csv" });
    //     downloadBlob(blob, response.headers["file-name"]);
    //   });
    // },
  },
};
</script>
