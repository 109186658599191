<template>
  <div class="example-display">
    <div class="subject">
      {{ example.subject }}
    </div>
    <email-content
      ref="editor"
      class="draft-content body"
      :email="example"
      :displayed-tags="tagsToBeDisplayed"
      :hide-subject="true"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmailContent from "shared/components/email/EmailContent.vue";

export default {
  components: {
    EmailContent,
  },
  props: {
    example: {
      type: Object,
      required: true,
    },
    highlight: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters("drafting", { getReviewTagsToBeDisplayed: "getReviewTagsToBeDisplayed" }),
    tagsToBeDisplayed() {
      return this.getReviewTagsToBeDisplayed(this.example);
    },
  },
  watch: {
    highlight(newValue) {
      let editor = this.$refs.editor;
      if (newValue == null) {
        editor.select(0, 0);
      } else {
        editor.select(newValue.start, newValue.end);
      }
    },
  },
};
</script>
