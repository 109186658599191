<template>
  <div>
    <div class="card-content dashboard-template-card">
      <header class="template-name">
        <div class="name">
          {{ template.name }}
        </div>
        <div class="role-type-wrapper">
          <div v-if="template.document_type" class="template-type email-type">
            {{ template.document_type }}
          </div>
        </div>
      </header>
      <div class="summary" v-if="template.description">
        <p v-html="template.description" class="summary-description text-small">
        </p>
      </div>
      <div class="public-template-details">
        <span><b>{{ template.tag_count }}</b> Checklist Items</span>
        <span><b>{{ template.example_count }}</b> Examples</span>
        <button 
          class="button-link"
          ref="detailsTemplateTrigger"
          @click.prevent="showDetailsModal = true"
        >
          Review Details
        </button>
      </div>
      <div v-if="hasJoined" class="buttons">
        <router-link
          :to="{ name: 'Template', params: { templateId: template.id } }"
          :aria-label="template.name"
        >
          <button class="button-quaternary">
            View Template
          </button>
        </router-link>
      </div>
      <div v-else class="buttons">
        <button
          class="button-secondary"
          ref="joinTemplateTrigger"
          @click.prevent="showJoinModal = true"
        >
        Join as Participant
        </button>
      </div>
    </div>
    <public-template-modal-details
      v-if="showDetailsModal"
      :template="template"
      :examples="templateExamples"
      @close="hideDetailsModalAndRefocus"
    ></public-template-modal-details>
    <public-template-modal-description
      v-if="showDescriptionModal"
      :template="template"
      @close="hideDescriptionModalAndRefocus"
    ></public-template-modal-description>
    <public-template-modal-join
      v-if="showJoinModal"
      :template="template"
      @close="hideJoinModalAndRefocus"
      @joined-template="handleJoinedTemplate"
    ></public-template-modal-join>
  </div>
</template>

<script>
import PublicTemplateModalDescription from './PublicTemplateModalDescription.vue';
import PublicTemplateModalDetails from './PublicTemplateModalDetails.vue';
import PublicTemplateModalJoin from './PublicTemplateModalJoin.vue';
import ExamplesService from "services/examples";
import { mapActions } from "vuex";

export default {
  components: {
    PublicTemplateModalDescription,
    PublicTemplateModalDetails,
    PublicTemplateModalJoin,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDescriptionModal: false,
      showDetailsModal: false,
      showJoinModal: false,
      templateExamples: null,
    };
  },
  async mounted() {
    await this.getExamples();
  },
  watch: {
    template() {
      this.getExamples();
    },
  },
  computed: {
    hasJoined() {
      for (const role in this.template.roles) {
        if (this.template.roles[role] == "Template Participant" || this.template.roles[role] == "Template Owner")
          return true;
      };
      return false;
    },
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    handleNewTemplateCompletion(template) {
      this.showCreateTemplateModal = false;
      this.showSnackbar(`Successfully created ${template.name}`);
      this.$router.push({ name: "Template", params: { templateId: template.id } });
    },
    handleJoinedTemplate(template) {
      this.showJoinTemplateModal = false;
      this.showSnackbar(`Successfully joined ${template.name}`);
      this.$router.push({ name: "Template", params: { templateId: template.id } });
    },
    hideDescriptionModalAndRefocus() {
      this.showDescriptionModal = false;
      this.$refs.descriptionTemplateTrigger.focus();
    },
    hideDetailsModalAndRefocus() {
      this.showDetailsModal = false;
      this.$refs.detailsTemplateTrigger.focus();
    },
    hideJoinModalAndRefocus() {
      this.showJoinModal = false;
      this.$refs.joinTemplateTrigger.focus();
    },
    getExamples() {
      return ExamplesService.list(this.template.id)
        .then((response) => {
          this.templateExamples = response.data.filter((e) => e.is_approved);
        });
    },
  },
}
</script>
