<template>
  <div v-if="isDataReady" class="container interior flex column">
    <et-header></et-header>
    <div class="draft-container flex-fill">
      <div class="left-panel">
        <checklist-view></checklist-view>
      </div>
      <editor-view></editor-view>
      <div class="right-panel">
        <comment-view> </comment-view>
      </div>
    </div>
  </div>
</template>

<script>
import CommentView from "../components/CommentView.vue";
import EditorView from "../components/EditorView.vue";
import ChecklistView from "../components/ChecklistView.vue";
import { useGuestStoreData } from "../composables";

export default {
  name: "GuestFeedback",

  components: {
    CommentView,
    EditorView,
    ChecklistView,
  },

  props: {
    slug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { isDataReady } = useGuestStoreData(props.slug);

    return {
      isDataReady,
    };
  },
};
</script>

<style lang="scss"></style>
