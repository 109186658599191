import axios, { AxiosResponse } from "axios";

const submissionsRoute = "/api/submissions";

export interface SubmissionTag {
  id: number;
  start: number;
  end: number;
  content: string;
  submission_id: number;
  template_checklist_item_id: number;
}

export interface DraftSubmissionResponse {
  id: string;
  slug: string;
  created_at: Date;
  version: number;
  username: string;
  title: string;
  content: string;
  is_archived: boolean;
  user_id: number;
  template_id: number;
  draft_id: number;
  email_slug: string;
  tags: SubmissionTag[];
}

export interface DraftSubmissionPayload {
  draft_id: number;
  template_id: number;
  title: string;
  content: string;
  tags: Array<unknown>;
}

export default {
  create(data: DraftSubmissionPayload): Promise<DraftSubmissionResponse> {
    return axios.post(`${submissionsRoute}/`, data);
  },

  list(templateId: number): Promise<DraftSubmissionResponse[]> {
    if (templateId !== undefined) {
      return axios.get(`${submissionsRoute}/`, { params: { template_id: templateId } });
    }
    return axios.get(`${submissionsRoute}/`);
  },

  get(id: number | string): Promise<AxiosResponse<DraftSubmissionResponse>> {
    return axios.get(`${submissionsRoute}/${id}/`);
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  destroy(id: number) {  // TODO: Add return type.
    return axios.delete(`${submissionsRoute}/${id}/`);
  },
};
