<template>
  <li>
    <router-link :to="to">
      <slot></slot>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
a {
  display: block;
  padding: 8px 24px 8px 12px;
  color: var(--primary-blue-dark);
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none;
  white-space: nowrap;
}

a:hover {
  background-color: var(--primary-blue-dark);
  color: var(--white);
}
</style>
