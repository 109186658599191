import AboutUs from "./pages/AboutUs.vue";
import Landing from "./pages/Landing.vue";
import TermsOfService from "./pages/TermsOfService.vue";
import LearningAnalytics from "./pages/LearningAnalytics.vue";

export default [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/about",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/pages/Terms-of-Service",
    name: "Terms of Service",
    component: TermsOfService,
  },
  {
    path: "/pages/Learning-Analytics",
    name: "Learning Analytics",
    component: LearningAnalytics,
  },
];
