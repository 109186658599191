import axios from "axios";

const baseURL = "/api/";

export default {
  courses() {
    return axios.get(`${baseURL}canvas-courses.json`);
  },

  listServices() {
    return axios.get("/canvas/services/");
  },

  listTokens() {
    return axios.get("/canvas/tokens/");
  },
  destroyToken(tokenId) {
    return axios.delete(`/canvas/tokens/${tokenId}/`);
  },
};
