<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 id="dialog_label" class="text-med">{{ template.name }} Desciption</h2>
      <div v-html="template.description" class="modal-body public-templates-modal">
      </div>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
};
</script>
