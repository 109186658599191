<template>
  <div class="terms-of-service-wrapper external-page">
    <header class="header external" ref="header">
      <external-header></external-header>
    </header>
    <main id="main" class="container">
      <section class="external-section">
        <h1 class="title serif">Center for Academic Innovation Software: Terms of Service</h1>
        <p><b>Last Update: </b>October 27, 2022</p>
        <br>
        <div class="text-small">
          <p>
            These terms of service govern the use of software tools and applications and associated materials
            such as documentation and content (each and collectively, “Software”) developed by or in
            partnership with the Regents of the University of Michigan (“U-M,” “we,” “us,” “our”),
            a constitutional corporation of the state of Michigan, through U-M’s Center for
            Academic Innovation.
          </p>
          <br>
          <p>
            By your use of Software, you agree to abide by all terms, conditions, and referenced policies contained
            in these Terms of Service (“Terms”) and all relevant state and federal laws. If you are using
            Software in your capacity as a U-M employee or student, or have otherwise agreed to be
            bound by U-M policies, you further agree to abide by U-M’s
            <a href="https://spg.umich.edu/policy/601.07" target="_blank">Proper Use of Information Resources, Information Technology, and Networks Policy (SPG 601.07)</a>,
            <a href="https://spg.umich.edu/policy/601.12" target="_blank">Institutional Data Resource Management Policy (601.12)</a>,
            and all other applicable U-M policies, as updated.
          </p>
          <br>
          <p>
            Please read these Terms carefully before using Software as these Terms CREATE A BINDING AGREEMENT BETWEEN
            YOU AND U-M. If you do not understand how these Terms apply to your access to or use of Software, or if
            you think that your access to or use of Software would be in violation of these Terms, please stop here
            and do not access or use Software.
          </p>
          <br>
          <p>
            BEFORE YOU ACCESS OR USE SOFTWARE, YOU MUST BE AUTHORIZED TO ACCEPT THESE TERMS AND CONDITIONS AS AN INDIVIDUAL.
            THIS SOFTWARE IS NOT INTENDED FOR USE BY ANYONE UNDER THE AGE OF 13. IF YOU ARE UNDER 13, YOU ARE NOT PERMITTED
            TO USE THIS SOFTWARE.
          </p>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">I. Covered Software</h2>
        <div class="text-small">
          <p>
            These Terms govern the use of all learning tools and other software listed in the
            <a href="https://ai.umich.edu/our-work/software-applications/" target="_blank">CAI Software Portfolio</a>
            and all software and applications created under the University of Michigan’s
            <a href="https://ai.umich.edu/xr-initiative/" target="_blank">XR Initiative</a>
            within CAI. Specifically, covered Software includes the following:
          </p>
          <ul class="list-section">
            <li>
              <a href="https://ai.umich.edu/software-applications/atlas/" target="_blank">Atlas</a>™
            </li>
            <li>
              <a href="https://ai.umich.edu/software-applications/ecoach/" target="_blank">ECoach</a>™ (UM Innovation Partnerships File# 6076)
            </li>
            <li>
              <a href="https://ai.umich.edu/software-applications/gradecraft/" target="_blank">GradeCraft</a>™ (UM Innovation Partnerships File# 6664)
            </li>
            <li>
              <a href="https://ai.umich.edu/software-applications/lettersmith/" target="_blank">Lettersmith</a>™ (UM Innovation Partnerships File# 2021-218)
            </li>
            <li>
              <a href="https://ai.umich.edu/software-applications/problem-roulette/" target="_blank">Problem Roulette</a>™  (UM Innovation Partnerships File# 7736)
            </li>
            <li>
              <a href="https://spire.ai.umich.edu/" target="_blank">Spire</a>™
            </li>
            <li>
              <a href="https://ai.umich.edu/software-applications/tandem/" target="_blank">Tandem</a>™  (UM Innovation Partnerships File# 2022-418)
            </li>
            <li>
              <a href="https://ai.umich.edu/software-applications/viewpoint/" target="_blank">ViewPoint</a>™  (UM Innovation Partnerships File# 7228)
            </li>
            <li>
              All
              <a href="https://ai.umich.edu/software-applications/gamut/" target="_blank">Gamut</a>® Suite tools (e.g., Gallery, Workbook, Recommender, Goal Setting, and Write and Revise) (UM Innovation Partnerships File# 2021-016, 2021-018)
            </li>
            <li>
              <a href="https://www.oculus.com/experiences/quest/4263226607121330/" target="_blank">Under the Skin</a>,™ available on Oculus Quest
            </li>
          </ul>
          <p>
            Additional Software may be covered by these same Terms without being listed above. Rather, users may be
            directed to these same Terms from separate web pages that host tools that remain under development or
            have otherwise not been featured on the
            <a href="https://ai.umich.edu/our-work/software-applications/" target="_blank">CAI Software Portfolio</a>
            or
            <a href="https://ai.umich.edu/xr-initiative/" target="_blank">XR Initiative</a>
            webpages. Users may also be directed to these Terms through unique notices that ask users to agree to these
            Terms when creating new accounts.
          </p>
          <br>
          <p>
            Finally, these Terms also apply through incorporation when referenced as part of other terms of service
            and agreements associated with the use of learning experiences supported by the Center for Academic
            Innovation or its partners when such experiences incorporate Software.
          </p>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">II. Our License to You</h2>
        <div class="text-small">
          <p>
            Subject to these Terms, we grant you a non-exclusive, non-transferable right to use the Software solely
            for noncommercial research and education purposes.
          </p>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">III. Limitations, Restrictions, and Code of Conduct</h2>
        <div class="text-small">
          <ul>
            <li class="no-list-styling">
              <b>A.</b> You agree to use Software only for your sole and exclusive, noncommercial research and educational use
              and shall not disclose, sell, license, or otherwise distribute Software, in whole or in part, either
              directly or indirectly, to any third party without the prior written consent of U-M. You may not use
              Software as part of any revenue-generating activities.
            </li>
            <li class="no-list-styling">
              <b>B.</b> You shall not use, print, copy, translate, reverse engineer, decompile, disassemble, modify, create
              derivative works of or publicly display Software, in whole or in part, unless expressly authorized by these
              Terms. Furthermore, you shall not use the Software to harm, threaten, or harass another person,
              organization, or U-M and/or to build similar services, software, or websites.
            </li>
            <li class="no-list-styling">
              <b>C.</b> All user data collected through Software is subject to
              <a href="https://ai.umich.edu/academic-innovation-website-privacy-policy/" target="_blank">U-M’s Center for Academic Innovation’s Privacy Policy</a>. You also acknowledge that we may send you communications or data regarding Software, including but not
              limited to: (a) notices about your use of Software, including any notices concerning violations of use; (b)
              updates; and (c) promotional information and materials regarding Software, via electronic mail. Any data
              or content downloaded from the Software by you may be used for personal and educational purposes only.
            </li>
            <li class="no-list-styling">
              <b>D.</b> No ownership rights of U-M in Software are conferred upon you by these Terms. U-M retains all rights
              and interests in Software and reserves all rights not expressly granted under these Terms. You agree to secure
              and protect Software in a manner consistent with the maintenance of U-M’s rights in Software.
            </li>
            <li class="no-list-styling">
              <b>E.</b> Any use or access by anyone under the age of 13 is prohibited.
            </li>
            <li class="no-list-styling">
              <b>F.</b> Certain countries and regions may have additional technological or legal restrictions or requirements
              that may limit an impacted user’s access to and use of Software.
            </li>
            <li class="no-list-styling">
              <b>G.</b> You are responsible for all data and content that you access from or create using the Software.
            </li>
            <li class="no-list-styling">
              <b>H.</b> You will not share passwords or other access information or devices or otherwise authorize any third
              party to access or use Software on your behalf.
            </li>
            <li class="no-list-styling">
              <b>I.</b> You will not engage in unlawful, illegal, or fraudulent activity, including, without limitation, by
              engaging in unauthorized access or unauthorized use of Software or any accounts, computers, or networks
              related to Software.
            </li>
            <li class="no-list-styling">
              <b>J.</b> You will not use Software to make available any unsolicited or unauthorized advertising or promotional
              materials.
            </li>
            <li class="no-list-styling">
              <b>K.</b> Unless indicated to you separately in writing by U-M, you will not use any robot, spider, site
              search/retrieval application, or other manual or automated process to access, retrieve, scrape, or index
              any portion of Software.
            </li>
            <li class="no-list-styling">
              <b>L.</b> You will not record, process, or mine information about other users or creators of Software.
            </li>
            <li class="no-list-styling">
              <b>M.</b> You will not damage, disrupt, interfere with, diminish, or render inaccessible or unusable the
              Software or others’ equipment or software or others’ data, or attempt to do so, or encourage or assist
              others to do so.
            </li>
            <li class="no-list-styling">
              <b>N.</b> You will not initiate a denial of service attack against Software or release a virus, trojan
              horse, worms or other malware or spyware.
            </li>
            <li class="no-list-styling">
              <b>O.</b> You will not use Software to perpetrate a hoax or engage in phishing schemes or forgery or other
              similar falsification or manipulation of data.
            </li>
            <li class="no-list-styling">
              <b>P.</b> You will not use Software to abuse, harass, stalk, threaten, or otherwise violate the legal rights
              of others.
            </li>
            <li class="no-list-styling">
              <b>Q.</b> You will not use Software to libel or defame others.
            </li>
            <li class="no-list-styling">
              <b>R.</b> You will not use Software to make available any content that is vulgar or obscene.
            </li>
            <li class="no-list-styling">
              <b>S.</b> You will abide by reasonable administrative directives issued from time to time concerning the
              access or use of Software.
            </li>
          </ul>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">IV. User Content</h2>
        <div class="text-small">
          <ul>
            <li class="no-list-styling">
              <b>A.</b> You are solely responsible for all video, audio, images, text, and other content uploaded, transmitted, or
              created by you through or with Software (“User Content”). You represent and warrant that you own or have a license
              to use your User Content, and that this User Content does not violate the law or infringe, misappropriate or
              violate the rights, including intellectual property rights, of any other person or entity. You must remove
              any User Content if you no longer have the rights to such User Content. U-M has no obligation to monitor
              User Content and is not liable to you or to any other person or entity for any content, information or
              misinformation contained in any User Content. Notwithstanding the foregoing, U-M reserves the right
              to remove User Content without notice if it deems, in its sole discretion, that User Content is in
              violation of the terms of these Terms. You are solely responsible for saving, storing and
              otherwise maintaining copies of User Content to prevent loss.
            </li>
            <li class="no-list-styling">
              <b>B.</b> You grant U-M a fully paid-up non-exclusive, royalty-free, transferable, sublicensable, worldwide license
              to use, display, reproduce, modify, re-format, rearrange, distribute, and create derivative works of any User
              Content provided to or through Software. This license includes U-M being able to perform research related to
              the effectiveness of Software, evaluating the performance of users, and making Software available to users.
            </li>
            <li class="no-list-styling">
              <b>C.</b> You grant U-M as well as other users a non-exclusive right to modify and adapt User Content that you have
              submitted for educational purposes consistent with any additional terms set forth in a course syllabus, as
              applicable.
            </li>
            <li class="no-list-styling">
              <b>D.</b> If you provide U-M with any suggestions, ideas, feedback, reports, error identifications or other similar
              information related to Software (“Feedback”), you hereby assign to U-M all right, title and interest in and to all
              Feedback, including all intellectual property rights therein, and shall assist U-M in perfecting such rights and
              obtaining assignments of such rights from all individuals involved in generating the Feedback. In instances
              where U-M uses Feedback to enhance Software in any way, you will not be considered an “Inventor” pursuant
              to the <a href="https://spg.umich.edu/policy/303.04" target="_blank">University of Michigan Technology Transfer Policy (SPG 303.04)</a>.
            </li>
            <li class="no-list-styling">
              <b>E.</b> U-M enforces copyright law and protects its own as well as the rights of others. If a copyright holder
              has a concern regarding the unauthorized use of copyrighted material on Software or any site on the umich.edu
              network, please contact U-M’s designated
              <a href="https://www.copyright.gov/legislation/dmca.pdf" target="_blank">Digital Millennium Copyright Act (DMCA)</a> agent,
              Sol Bermann, using the contact information provided below. U-M will only process complaints that are sent to the email
              address below, which has been registered with the U.S. Copyright Office.
            </li>
          </ul>
          <p>Sol Bermann</p>
          <p>Executive Director of Information Assurance and Chief Information Security Officer</p>
          <p>University of Michigan</p>
          <p>950 Victors Way, Suite 10</p>
          <p>Ann Arbor, MI 48108-5218</p>
          <p>
            <a href="mailto:dmca.notices@umich.edu" target="_blank">dmca.notices@umich.edu</a>
          </p>
          <br>
          <p>
            The DMCA specifies that all infringement claims must be in writing (either email or paper letter) and must include the
            following:
          </p>
          <ul class="list-section">
            <li>
              A physical or electronic signature of the copyright holder or a person authorized to act on his or her behalf;
            </li>
            <li>
              Description of the copyrighted work claimed to have been infringed, including URL of any web site;
            </li>
            <li>
              Description of the material that is claimed to be infringed with information sufficient to permit the university to locate the material;
            </li>
            <li>
              Contact information for the owner or agent, including name, address, telephone number, and email address;
            </li>
            <li>
              A statement that the copyright holder or authorized agent has a good faith belief that use of the copyrighted material complained of is not authorized by the copyright owner, agent or the law; and
            </li>
            <li>
              A statement made under penalty of perjury that the information in the notification is accurate.
            </li>
          </ul>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">V. Disclaimer of Warranty and Limitation of Liability</h2>
        <div class="text-small">
          <ul>
            <li class="no-list-styling">
              <b>A.</b> SOFTWARE IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT
              LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. U-M DOES NOT WARRANT
              THAT THE FUNCTIONS CONTAINED IN THE SOFTWARE WILL MEET YOUR REQUIREMENTS OR THAT OPERATION WILL BE
              UNINTERRUPTED OR ERROR FREE. U-M shall not be liable for special, indirect, incidental, or
              consequential damages with respect to any claim on account of or arising from these
              Terms or use of the Software, even if U-M has been or is hereafter advised of the
              possibility of such damages. Because some states do not allow certain exclusions
              or limitations on implied warranties or of liability for consequential or
              incidental damages, the above exclusions may not apply to you. In no
              event, however, will U-M be liable to you, under any theory of
              recovery.
            </li>
            <li class="no-list-styling">
              <b>B.</b> You agree that U-M has no obligation to provide to you any maintenance, support, or update services. Should
              U-M provide any revised versions of Software to you, you agree that these Terms shall apply to such revised versions.
            </li>
          </ul>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">VI. Indemnification</h2>
        <div class="text-small">
          <p>
            You agree to hold U-M and its affiliates, agents, employees, and licensors harmless from and against any and all claims
            and expenses, including reasonable attorney fees, arising out of or related in any way to Software, your use of
            Software, your violation any law or regulation, your violation of any proprietary or privacy right, or your
            violation of these Terms.
          </p>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">VII. Suspension and Termination</h2>
        <div class="text-small">
          <p>
            If you at any time fail to abide by these Terms, U-M shall have the right to immediately and without prior notice suspend
            or terminate your access to applicable Software granted herein, require the return or destruction of all copies of such
            Software from you and certification in writing as to such return or destruction, and pursue any other legal or
            equitable remedies available. You acknowledge that if your access to and/or use of Applicants is suspended or
            terminated, it is possible that you may no longer have access to the content that you created.
          </p>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">VIII. Changes to Terms</h2>
        <div class="text-small">
          <p>
            U-M reserves the right to update and revise these Terms at any time. U-M will change the “Effective Date” at the top of this
            page so you can determine if the terms of this Agreement have changed since your last visit. You should routinely check the
            web page to find out if any changes have been made that affect your use of Software. Unless otherwise specified, changes
            made to these Terms will be effective when they are posted to the web page. IF YOU DO NOT AGREE WITH ANY CHANGES THAT
            HAVE BEEN MADE, YOU MUST TERMINATE YOUR USE OF SOFTWARE.
          </p>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">IX. Monitoring</h2>
        <div class="text-small">
          <p>
            U-M may monitor your use of Software to assess compliance with the terms of these Terms.
          </p>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">X. Miscellaneous</h2>
        <div class="text-small">
          <ul>
            <li class="no-list-styling">
              <b>A.</b> These Terms shall be construed in accordance with the laws of the state of Michigan. Should you for any reason
              bring a claim, demand, or other action against U-M, its agents or employees, arising out of these Terms or Software
              licensed herein, you agree to bring said claim only in the Michigan Court of Claims.
            </li>
            <li class="no-list-styling">
              <b>B.</b> You acknowledge that Software is of United States origin. You agree to comply with all applicable international and
              national laws that apply to Software, including the United States Export Administration Regulations, as well as end-user,
              end-use, and destination restrictions issued by the United States. You warrant that you are not individually or by
              association a named person or entity on any government sanctions list, including but not limited to the
              <a href="https://sanctionssearch.ofac.treas.gov/" target="_blank">U.S. Office of Foreign Assets Control’s Specially Designated Nationals and Blocked Persons list</a> and
              that you will immediately discontinue your use of Software if you are placed on such a list. You further
              warrant that you will not export, re-export, or transfer Software to a person or entity on a government
              sanctions list and will not use Software in a manner prohibited by laws in your local jurisdiction or
              those otherwise applicable.
            </li>
            <li class="no-list-styling">
              <b>C.</b> Unless otherwise exempt therefrom, you agree that you will be responsible for any sales, use or excise taxes
              imposed by any governmental unit in this transaction, when applicable, except income taxes.
            </li>
            <li class="no-list-styling">
              <b>D.</b> THIS AGREEMENT REPRESENTS THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN YOU AND U-M AND SUPERSEDES
              ALL PRIOR AGREEMENTS, PROPOSALS, REPRESENTATIONS AND OTHER COMMUNICATIONS, VERBAL OR WRITTEN, BETWEEN THEM WITH RESPECT TO
              USE OF SOFTWARE. THIS AGREEMENT MAY BE MODIFIED ONLY WITH THE MUTUAL WRITTEN APPROVAL OF AUTHORIZED REPRESENTATIVES OF THE
              PARTIES.
            </li>
          </ul>
        </div>
      </section>
    </main>
    <et-footer></et-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      loggedIn: (state) => state.loggedIn,
      user: (state) => state.user,
    }),
  },
};
</script>
