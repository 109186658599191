import { HighlightColor } from "./editors/extensions/highlightable.extension";

export interface EmailTag {
  id: number;
  email_id: number;
  start: number;
  end: number;
  content: string;
  template_checklist_item_id: number;
  color: HighlightColor;
}

/**
 * This data structure holds a collection of tags and is able to filter/update tags
 * based on position indexes
 */
export class TagList {
  private _tags: EmailTag[];

  constructor(tags: EmailTag[]) {
    this._tags = [...tags];
  }

  get tags(): EmailTag[] {
    return this._tags;
  }

  atPosition(position: number): TagList {
    return new TagList(this._tags.filter((t) => t.start < position && t.end >= position));
  }

  notAtPosition(position: number): TagList {
    return new TagList(this._tags.filter((t) => t.start >= position || t.end < position));
  }

  inRange(start: number, end: number): TagList {
    return new TagList(this._tags.filter((t) => t.start >= start && t.end <= end));
  }

  afterPosition(position: number): TagList {
    return new TagList(this._tags.filter((t) => t.start >= position));
  }

  updateById(updatedTag: EmailTag): void {
    const index = this.tags.findIndex((t) => t.id === updatedTag.id);
    this._tags[index] = updatedTag;
  }

  removeById(tagId: number): void {
    this._tags = this._tags.filter((tag) => tag.id !== tagId);
  }
}
