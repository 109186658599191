<template>
  <div class="permission-level" :class="roleClass">{{ name }}</div>
</template>

<script>
export default {
  props: {
    roleName: {
      type: String,
      required: true,
    },
  },
  computed: {
    name() {
      let elements = this.roleName.split(" ");
      return elements[elements.length - 1];
    },
    roleClass() {
      return this.name.toLowerCase();
    },
  },
};
</script>
