import axios from "axios";

const apiRoute = "/api/email-tag/";

export default {
  store(data) {
    return axios.post(apiRoute, data);
  },

  show(id) {
    return axios.get(apiRoute + id);
  },

  update(id, data) {
    return axios.put(apiRoute + id + "/", data);
  },

  destroy(id) {
    return axios.delete(apiRoute + id + "/");
  },
};
