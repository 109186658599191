<template>
  <div role="status">
    <div
      v-show="snackbarEnabled"
      class="snackbar"
      :class="{ 'fade-in-bottom': isEntering, 'fade-out-bottom': !isEntering }"
    >
      <span>{{ snackbar.message }}</span>
      <button
        class="button"
        @click="closeSnackbar"
        aria-hidden="true"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Snackbar",

  data() {
    return {
      isEntering: true,
      currentTimeoutId: null,
    };
  },

  computed: {
    ...mapState(["snackbar"]),

    snackbarEnabled() {
      return this.snackbar.enabled;
    },

    snackbarIsEntering() {
      return this.snackbar.isEntering;
    },
  },

  methods: {
    ...mapActions(["hideSnackbar"]),

    clearTimeout() {
      if (this.currentTimeoutId) {
        window.clearTimeout(this.currentTimeoutId);
      }
    },

    closeSnackbar() {
      this.clearTimeout();
      this.isEntering = false; // starts animation, duration 0.75s defined in _animation.scss
      this.currentTimeoutId = setTimeout(() => {
        this.hideSnackbar(); // hide snackbar after aniation has finished
        this.currentTimeoutId = null;
      }, 750);
    },

    autoCloseAfterFiveSeconds() {
      this.clearTimeout();
      this.currentTimeoutId = setTimeout(() => {
        this.closeSnackbar();
      }, 4250);
    },
  },

  watch: {
    snackbarEnabled(newValue) {
      if (newValue) {
        this.autoCloseAfterFiveSeconds(); // show snackbar
      } else {
        this.isEntering = true; // hide snackbar; next appearance should be a fade-in
      }
    },
  },
};
</script>
