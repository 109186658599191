<template>
  <div ref="emailContent">
    <div v-if="!hideSubject" class="draft-view-subject">
      {{ draft.title }}
    </div>
    <div>
      <div ref="quillEditor"></div>
      <div id="empty-quill-toolbar" class="visually-hidden"></div>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from "libs/editors";

export default {
  name: "DraftContent",
  components: {},
  props: {
    hideSubject: {
      type: Boolean,
      default: false,
    },
    draft: {
      type: Object,
      required: true,
    },
    displayedTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: null,
      readOnlyOptions: {
        modules: {
          toolbar: "#empty-quill-toolbar",
        },
      },
      editor: null,
    };
  },

  mounted() {
    this.init();
  },

  watch: {
    draft(newValue) {
      this.editor.setHTML(newValue.content);
      this.editor.setTagList(newValue.tags || []);
      this.editor.toBeDeletedTagIds = [];
    },
    displayedTags(newDisplayedTags, oldDisplayedTags) {
      if (newDisplayedTags.length > 0) {
        if (newDisplayedTags.length !== oldDisplayedTags.length) {
          this.editor.blur();
        }
        this.editor.highlightTags(newDisplayedTags, oldDisplayedTags);
      } else {
        this.editor.removeAllHighlighting();
      }
    },
  },

  methods: {
    init() {
      const initConfig = {
        container: this.$refs.quillEditor,
        enabled: false,
        options: this.readOnlyOptions,
        html: this.draft.content,
        tags: this.draft.tags || [],
      };
      this.editor = new QuillEditor(initConfig);

      this.editor.on("selected", ({ range, position }) => {
        this.$emit("select", range);
        this.handleSelected(position);
      });

      this.editor.on("focus", () => {
        this.$emit("focus");
      });

      this.editor.on("text-change", (e) => {
        this.$emit("text-change", e);
      });

      this.editor.on("blur", () => {
        this.$emit("blur");
      });
    },

    select(start, end) {
      this.editor.select(start, end);
    },

    getTextByRange(range) {
      this.editor.getTextByRange(range);
    },

    replaceContent(range, replacedText) {
      this.editor.replaceText(range, replacedText);
    },

    blur() {
      this.editor.blur();
    },

    handleSelected(position) {
      const startPosition = position.start;
      const endPosition = position.end;
      const content = this.$refs.emailContent;

      if (content.scrollTop > startPosition.top) {
        this.$refs.emailContent.scrollTop = startPosition.top;
      }
      if (content.scrollTop + content.getBoundingClientRect().height < endPosition.bottom) {
        this.$refs.emailContent.scrollTop =
          endPosition.bottom - content.getBoundingClientRect().height + endPosition.height;
      }
    },
  },
};
</script>
