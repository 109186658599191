<template>
  <div class="learning-analytics-wrapper external-page">
    <header class="header external" ref="header">
      <external-header></external-header>
    </header>
    <main id="main" class="container">
      <section class="external-section">
        <h1 class="title serif">Learning Analytics Guiding Principles</h1>
        <h2 class="sub-title serif">1. Introduction</h2>
        <div class="analytics-section-container text-small">
          <p>
            In 2017, the University Registrar, University Privacy Officer, and the Office of
            Academic Innovation began working on a set of U-M Learning Analytics Guiding Principles.
            As we collect and use data for learning analytics, our guiding principles ensure that
            the creation, collection, analysis, and reporting of learning analytics data remains
            transparent and secure. These guiding principles also make certain we take privacy and
            ethical considerations into account. For example, ensuring that data is not exploited
            for unexpected or unwanted uses or outcomes, and where appropriate, consent or approval
            is sought from students we are collecting and/or using data for.
          </p>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">II. What are learning analytics data?</h2>
        <div class="analytics-section-container text-small">
          <p>
            We define learning analytics as “the measurement, collection, analysis and reporting of
            data about learners and their contexts, for purposes of understanding and optimizing
            learning and the environments in which it occurs.”
          </p>
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">
          III. What are the learning analytics guiding principles?
        </h2>
        <div class="analytics-section-container text-small">
          <p>
            <span class="bold">RESPECT:</span> We respect the dignity of students and their rights.
            We strive to balance the good of the students, the University of Michigan’s needs, and
            improving higher education through learning analytics data. We acknowledge that data
            students contribute to learning analytics research may benefit themselves and fellow
            students.
          </p>
          <br />
          <p>
            <span class="bold">TRANSPARENCY:</span> We act with transparency, purpose, and
            specification when collecting and using learning analytics data. We disclose to students
            the educational interest in and benefits of collecting and analyzing learning analytics
            data. We inform students about the types of information we collect, how it is used, and
            how it is shared. We help students understand they are part of a community that seeks to
            improve approaches to teaching and learning by using learning analytics data.
          </p>
          <br />
          <p>
            <span class="bold">ACCOUNTABILITY:</span> We create processes for the security,
            integrity, and accountability of learning analytics data. We enforce policies and
            procedures for appropriate data-sharing, privacy, and data stewardship. We provide ways
            for students to inspect and review their learning analytics data.
          </p>
          <br />
          <p>
            <span class="bold">EMPOWERMENT:</span> We achieve individual participation in and
            respect the rights and dignity of students. We have a defined process to scrutinize data
            accuracy. We aim to use anonymized, de-identified, or aggregated data unless there is a
            legitimate need to use personally identifiable information with a clear and measurable
            impact on improving teaching and learning. We give students the ability to view their
            learning analytics data and to make choices about whether certain identifiable data may
            be shared.
          </p>
          <br />
          <p>
            <span class="bold">CONTINUOUS CONSIDERATION:</span> We continually review and reexamine
            our learning analytics policies and models to maintain a balance between the benefits of
            learning analytics for the University of Michigan with the privacy and ethical
            considerations of students. We welcome students to participate in our learning
            community, and to inquire about their role in supplying learning analytics data.
          </p>
          <br />
        </div>
      </section>
      <section class="external-section smaller">
        <h2 class="sub-title serif">
          IV. What if I have more questions about learning analytics or opting-out of research
          studies?
        </h2>
        <div class="analytics-section-container text-small">
          <p class="contact">
            Contact us at
            <a href="mailto:lettersmith-support@umich.edu">lettersmith-support@umich.edu</a>
          </p>
        </div>
      </section>
    </main>
    <et-footer></et-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      loggedIn: (state) => state.loggedIn,
      user: (state) => state.user,
    }),
  },
};
</script>
