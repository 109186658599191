<template>
  <examples-browser :examples="examples" />
</template>

<script>
import ExamplesService from "services/examples";

import ExamplesBrowser from "../components/ExamplesBrowser.vue";

export default {
  components: {
    ExamplesBrowser,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      examples: [],
      loading: false,
    };
  },
  mounted() {
    this.getExamples();
  },
  watch: {
    template() {
      this.getExamples();
    },
  },
  methods: {
    getExamples() {
      this.loading = true;
      return ExamplesService.list(this.template.id)
        .then((response) => {
          this.examples = response.data.filter((e) => e.is_approved);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
