import store from "~/store";
import ability from "core/abilities";

import TemplatesOverview from "./views/TemplatesOverview.vue";
import TemplateDetail from "./views/TemplateDetail.vue";
import TemplateDetailDrafts from "./views/TemplateDetailDrafts.vue";
import TemplateDetailExamples from "./views/TemplateDetailExamples.vue";
import TemplateDetailExamplesAdmin from "./views/TemplateDetailExamplesAdmin.vue";
import TemplateDetailMembers from "./views/TemplateDetailMembers.vue";
import TemplateDetailChecklist from "./views/TemplateDetailChecklist.vue";
import TemplateDetailSubmissions from "./views/TemplateDetailSubmissions.vue";
import TemplateDetailSettings from "./views/TemplateDetailSettings.vue";

const TemplateModule = () => import("./TemplateModule.vue");

export const routes = [
  {
    path: "templates",
    component: TemplateModule,
    name: "Templates",
    redirect: { name: "TemplatesOverview" },
    children: [
      {
        path: "",
        component: TemplatesOverview,
        name: "TemplatesOverview",
      },
      {
        path: ":templateId",
        component: TemplateDetail,
        props: (route) => {
          const templateId = Number.parseInt(route.params.templateId);
          if (Number.isNaN(templateId)) {
            return 0;
          }
          return { templateId };
        },
        children: [
          {
            path: "",
            name: "Template",
            redirect: (to) => {
              const { params } = to;
              const getters = store.getters;
              const templateId = Number.parseInt(params.templateId);

              if (getters && !Number.isNaN(templateId)) {
                const template = getters["template/getTemplateById"](templateId);
                if (template && ability.can("change", template)) {
                  return "tags";
                }
              }
              return "drafts";
            },
          },
          {
            path: "drafts",
            component: TemplateDetailDrafts,
            name: "TemplateDrafts",
          },
          {
            path: "tags",
            component: TemplateDetailChecklist,
            name: "TemplateChecklist",
          },
          {
            path: "example-documents",
            component: TemplateDetailExamples,
            name: "TemplateExamples",
          },
          {
            path: "example-documents/admin",
            component: TemplateDetailExamplesAdmin,
            name: "TemplateExamplesAdmin",
          },
          {
            path: "submissions",
            component: TemplateDetailSubmissions,
            name: "TemplateSubmissions",
          },
          {
            path: "members",
            component: TemplateDetailMembers,
            name: "TemplateMembers",
          },
          {
            path: "settings",
            component: TemplateDetailSettings,
            name: "TemplateSettings",
          },
        ],
      },
    ],
  },
];
