<template>
  <main id="main" class="container-min flex column admin-wrapper">
    <div class="breadcrumb-navigation">
      <router-link class="button-link" :to="{ name: 'Dashboard' }">Dashboard</router-link>
      <img alt="" src="/static/images/icons/arrow-dark-blue.png" />
      <p class="current-location">Site Administration</p>
    </div>
    <div class="admin-header">
      <h1 class="title serif">Site Administration</h1>
    </div>
    <div class="admin-content">
      <div class="admin-item details-container">
        <header>
          <div class="header-container">
            <img alt="" src="/static/images/icons/template-report.svg" />
            <h2 class="bold text-med">Template Report</h2>
          </div>
          <button v-if="user.is_admin" @click="downloadReport" class="button">
            Download Report
          </button>
        </header>
        <div class="admin-input-item card-container not-table">
          <div v-if="user.is_admin">Download a report of all existing templates with their members, codes, and shareable links.</div>
        </div>
      </div>
      <div class="admin-item details-container">
        <header>
          <div class="header-container">
            <img alt="" src="/static/images/icons/lti-registration.svg" />
            <h2 class="text-med">LTI Registration Management</h2>
          </div>
          <router-link class="button" :to="{ name: 'AddLtiRegistration' }">
            Add LTI Registration
          </router-link>
        </header>
        <table class="card-container" v-if="registrations.length !== 0">
          <thead>
            <tr class="table-header">
              <th class="row-item">Name</th>
              <th class="row-item">Issuer</th>
              <th class="row-item">Client</th>
              <th class="row-item">Status</th>
              <th class="row-item">Actions</th>
            </tr>
          </thead>
          <tbody class="table-content">
            <tr class="table-row" v-for="registration in registrations" :key="registration.id">
              <td class="row-item">{{ registration.name }}</td>
              <td class="row-item">{{ registration.issuer }}</td>
              <td class="row-item">{{ registration.client_id }}</td>
              <td class="row-item">
                <div class="status-tag" :class="{ active: registration.is_active }">{{ registration.is_active ? "Active" : "Inactive" }}</div>
              </td>
              <td class="row-item table-actions stacked">
                <button class="button-link" @click="showRegistrationDetails(registration)">Details</button>
                <router-link
                  class="button-link"
                  :to="{ name: 'EditLtiRegistration', params: { registrationId: registration.id }}"
                >
                  Edit
                </router-link>
                <router-link
                  class="button-link"
                  :to="{ name: 'LtiDeployments', params: { registrationId: registration.id } }"
                >
                  Deployments
                </router-link>
              </td>
              <registration-details-modal
                v-if="isActiveRegistration(registration)"
                :registration="registration"
                @close="activeRegistration = null;">
              </registration-details-modal>
            </tr>
          </tbody>
        </table>
        <div class="card-container not-table empty-text" v-else>
          No LTI Registrations Added
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex";

import RegistrationDetailsModal from "../components/RegistrationDetailsModal.vue";
import TemplateService from "services/template";
import { downloadBlob } from "shared/utils";

export default {
  name: "Admin",

  components: {
    RegistrationDetailsModal,
  },

  data() {
    return {
      activeRegistration: null,
    };
  },

  methods: {
    downloadReport() {
      TemplateService.downloadReport().then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        downloadBlob(blob, response.headers["file-name"]);
      });
    },
    showRegistrationDetails(registration) {
      this.activeRegistration = registration;
    },
    isActiveRegistration (registration) {
      return this.activeRegistration ? this.activeRegistration.id === registration.id : false;
    },
  },

  computed: {
    ...mapState(["user",]),
    ...mapState("admin", ["registrations"])
  },
};
</script>

<style scoped>
.admin-item.details-container:not(:last-child) {
  margin-bottom: 1.5rem;
}
</style>
