<template>
  <template-checklist :template="template" />
</template>

<script>
import TemplateChecklist from "../components/Checklist.vue";

export default {
  components: {
    TemplateChecklist,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
};
</script>
