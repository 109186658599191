<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 id="dialog_label" class="text-med">Edit Your Collection</h2>
      <div class="modal-body">
        <div class="modal-form-field">
          <label for="edit-collection-modal-collection-name" class="form-label bold">
            Collection Name
          </label>
          <input
            class="input"
            id="edit-collection-modal-collection-name"
            type="text"
            v-model="collectionName"
          />
        </div>
        <div class="modal-form-field">
          <div class="form-label bold">Which templates would you like inside this collection?</div>
          <p class="description-text">Existing templates:</p>
          <div class="template-list">
            <label
              v-for="template in allTemplates"
              :key="template.id"
              :class="{ disabled: !$can('change', template) }"
            >
              <input
                type="checkbox"
                :value="template.id"
                v-model="includeTemplates"
                :disabled="!$can('change', template)"
              />
              {{ template.name }}
            </label>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="handleSave" :disabled="!allowedToSave">Save</button>
    </template>
  </ls-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      collectionName: this.collection.name,
      includeTemplates: this.collection.templates,
    };
  },
  computed: {
    ...mapState("template", { allTemplates: "allTemplates" }),
    allowedToSave() {
      let collectionName = this.collectionName;
      let saving = this.saving;

      return collectionName.trim() != "" && !saving;
    },
  },
  methods: {
    ...mapActions("collection", {
      createCollection: "createCollection",
      updateCollection: "updateCollection",
    }),
    ...mapActions("template", { getTemplates: "getTemplates" }),
    handleSave() {
      this.saving = true;
      let newCollection = Object.assign({}, this.collection);
      newCollection.name = this.collectionName;
      newCollection.templates = this.includeTemplates;
      this.updateCollection({ collection: newCollection }).then((collection) => {
        this.getTemplates().then(() => {
          this.$emit("updated-collection", collection);
        });
      });
    },
  },
};
</script>
