import axios from "axios";

const apiRoute = "/api/email/";
const multiAPIRoute = "/api/emails/";

export default {
  index() {
    return axios.get(multiAPIRoute);
  },

  store(data) {
    return axios.post(multiAPIRoute, data);
  },

  show(id) {
    return axios.get(apiRoute + id + "/");
  },

  update(id, data) {
    return axios.put(apiRoute + id + "/", data);
  },

  destroy(id) {
    return axios.delete(apiRoute + id + "/");
  },

  send(id, recipients, cc, bcc) {
    return axios.post(apiRoute + id + "/send/", { recipients, cc, bcc });
  },
};
