import axios, { AxiosResponse } from "axios";

const apiRoute = "/api/lti-registrations/";
const deploymentApiRoute = "/api/lti-deployments";

export interface LtiRegistration {
  id: number;
  uuid: string;
  name: string;
  issuer: string;
  client_id: string;
  auth_url: string;
  token_url: string;
  keyset_url: string;
  is_active: boolean;
}

export interface LtiRegistrationPayload {
  name: string;
  issuer: string;
  client_id: string;
  auth_url: string;
  token_url: string;
  keyset_url: string;
  is_active: boolean;
}

export interface LtiDeployment {
  id: number;
  registration: number;
  deployment_id: string;
  is_active: boolean;
}

export interface LtiDeploymentPayload {
  registration: number;
  deployment_id: string;
  is_active: boolean;
}

export default {
  list(): Promise<AxiosResponse<LtiRegistration[]>> {
    return axios.get(apiRoute);
  },

  create(ltiRegistration: LtiRegistrationPayload): Promise<AxiosResponse<LtiRegistration>> {
    return axios.post(apiRoute, ltiRegistration);
  },

  update(id: number, data: LtiRegistrationPayload): Promise<AxiosResponse<LtiRegistration>> {
    return axios.put(`${apiRoute}${id}/`, data);
  },

  createDeployment(data: LtiDeploymentPayload): Promise<AxiosResponse<LtiDeployment>> {
    return axios.post(`${deploymentApiRoute}/`, data);
  },

  updateDeployment(id: number, data: LtiDeploymentPayload): Promise<AxiosResponse<LtiDeployment>> {
    return axios.put(`${deploymentApiRoute}/${id}/`, data);
  }
};
