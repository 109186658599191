<template>
  <div class="draft-container create-example-container flex-fill">
    <div class="left-panel">
      <nav aria-label="Primary navigation">
        <drafting-side-bar class="email-sidebar draft-navigation">
          <li
            :class="{
              active: checkComponentActive(activeLeftComponent, 'ChecklistView'),
            }"
            class="nav-item no-list-styling"
          >
            <button
              class="button-icon checklist-icon"
              aria-label="Toggle Checklist"
              @click="loadLeftComponent('ChecklistView')"
              :aria-expanded="checkComponentActive(activeLeftComponent, 'ChecklistView') ? 'true' : 'false'"
              aria-controls="checklist-panel"
            >
            </button>
            <span class="nav-title">Checklist</span>
          </li>
        </drafting-side-bar>
      </nav>
      <transition name="slide-fade">
        <keep-alive>
          <component v-if="activeLeftComponent" :is="activeLeftComponent"></component>
        </keep-alive>
      </transition>
    </div>

    <editor-view :show-feedback-url="false">
      <template #title>Update Example</template>
      <template #buttons-content>
        <router-link :to="{ name: 'TemplateExamplesAdmin', params: { templateId: templateId } }">
          <button class="button-secondary">Return to Examples</button>
        </router-link>
        <button class="button" :disabled="saveDisabled" @click="updateExample">
          Update Example
        </button>
      </template>
    </editor-view>

    <div class="right-panel"></div>
  </div>
</template>

<script>
import { ref, computed, onUnmounted } from "@vue/composition-api";
import ChecklistView from "../components/ChecklistView.vue";
import DraftingSideBar from "../components/DraftingSideBar.vue";
import EditorView from "../components/EditorView.vue";
import EmailService from "services/email";
import { useRouter, useStore } from "shared/composables";

export default {
  name: "EditExample",

  components: {
    EditorView,
    DraftingSideBar
  },

  props: {
    templateId: {
      type: String,
      required: true,
    },
    exampleSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const store = useStore();

    const email = computed(() => store.state.drafting.currentEmail);
    const emailTags = computed(() => store.state.drafting.currentDraftTags);
    const example = store.state.template.examples.find(
      item => item.slug === props.exampleSlug
    );

    // Left Panel
    let activeLeftComponent = ref(ChecklistView);
    const loadLeftComponent = (componentName) => {
      if (componentName === activeLeftComponent.value?.name) {
        activeLeftComponent.value = null;
      } else {
        switch (componentName) {
          case "ChecklistView":
            activeLeftComponent.value = ChecklistView;
            break;
          default:
            break;
        }
      }
    };

    // UI Logic
    const checkComponentActive = (componentObj, componentName) => {
      return componentObj?.name === componentName;
    };

    const saveDisabled = computed(() => {
      const hasSubject = (email.value.subject || "").trim() !== "";
      const hasBody = (email.value.content || "").trim() !== "";
      return !(hasSubject && hasBody);
    });

    const destroyEmail = async () => {
      if (email.value) {
        await EmailService.destroy(email.value.id);
        store.commit("drafting/SET_EMAIL", null);
      }
    };

    onUnmounted(async () => {
      await destroyEmail();
    });

    const updateExample = async () => {
      const emailValue = email.value;

      await store.dispatch('template/updateExample', {
        ...example,
        email_id: emailValue.id,
        content: emailValue.content,
        subject: emailValue.subject,
        tags: emailTags.value,
      });
      await destroyEmail();

      router.push({
        name: "TemplateExamplesAdmin",
        params: {
          templateId: props.templateId,
        },
      });
    };

    return {
      activeLeftComponent,
      loadLeftComponent,
      checkComponentActive,
      saveDisabled,
      updateExample,
    };
  },
}
</script>
