import store from "~/store";

import Drafting from "./views/Drafting.vue";
import GuestFeedback from "./views/GuestFeedback.vue";
import Finalize from "./views/Finalize.vue";
import CreateExample from "./views/CreateExample.vue";
import EditExample from "./views/EditExample.vue";

const DraftModule = () => import("./DraftingModule.vue");

const requireAuth = (to, from, next) => {
  if (!store.state.loggedIn) {
    next({
      name: "Login",
      query: { next: to.fullPath },
    });
  } else if (
    store.state.loggedIn &&
    store.state.user.templates &&
    store.state.user.templates.length === 0
  ) {
    next({ name: "Dashboard" });
  } else {
    next();
  }
};

export const routes = [
  {
    path: "/drafting/:templateId/:slug?",
    component: DraftModule,
    beforeEnter: requireAuth,
    props: true,
    children: [
      {
        path: "",
        name: "Draft",
        component: Drafting,
      },
      {
        path: "finalize/:action",
        name: "Finalize",
        component: Finalize,
        props: true,
      },
    ],
  },
  {
    path: "/create-example/:templateId/:slug?",
    component: DraftModule,
    beforeEnter: requireAuth,
    props: true,
    children: [
      {
        path: "",
        name: "CreateExample",
        component: CreateExample,
        props: true,
      },
    ],
  },
  {
    path: "/edit-example/:templateId/:exampleSlug",
    component: DraftModule,
    beforeEnter: requireAuth,
    props: true,
    children: [
      {
        path: "",
        name: "EditExample",
        component: EditExample,
        props: true,
      },
    ],
  },
  {
    path: "/f/:slug",
    name: "GuestFeedback",
    component: GuestFeedback,
    props: true,
  },
];
