<template>
  <div :class="{ 'tag-folder' : group.is_default === false, 'open' : !hideTags }">
    <div class="table-row" :class="{ folder : group.is_default === false }">
    <span class="row-item tag-title">
      <button
        class="button-icon draggable-icon"
        aria-label="Draggable Icon"
        aria-hidden="true"
        tabindex="-1"
      >
      </button>
      <div
        v-if="group.is_default === false"
        @click="toggleTagFolder"
        class="tag-title-text"
      >
        {{ group.title }}
        <button
          class="toggle-icon button-icon"
          :class="{ 'closed-icon': hideTags }"
          aria-label="Toggle Folder"
        >
        </button>
      </div>
      <span v-else class="tag-title-text">{{ group.title }}</span>
      <span v-if="group.is_default && looseTag.required" class="required"> * </span>
    </span>
    <span class="row-item">{{ group.description || "" }}</span>
    <span class="row-item table-actions">
        <button
          class="button-icon up-icon"
          @click="moveUp"
          aria-label="Move Up"
          :disabled="!canMoveUp"
        >
        </button>
        <button
          class="button-icon down-icon"
          @click="moveDown"
          aria-label="Move Down"
          :disabled="!canMoveDown"
        >
        </button>
        <button class="button-link edit" @click.prevent="showEditModal = true">Edit</button>
        <button class="button-link delete" @click="openDeleteItemModal">Delete</button>
    </span>
    </div>
    <div class="tag-folder-list" :class="{ closed: hideTags }" v-if="group.is_default === false">
      <div v-if="items.length > 0">
        <checklist-item
          class="table-row tags-in-folder"
          v-for="(item, i) in items"
          :key="item.title"
          :item="item"
          :can-move-up="i !== 0"
          :can-move-down="i < items.length - 1"
          @move-up="moveItemUp"
          @move-down="moveItemDown"
          @delete="deleteItem"
        >
        </checklist-item>
      </div>
      <div class="empty-tag" v-else>No tags in this folder</div>
    </div>
    <checklist-group-modal-edit
      v-if="showEditModal && !group.is_default"
      :template="template"
      :group="group"
      @close="showEditModal = false"
      @updated-tag="showEditModal = false"
    ></checklist-group-modal-edit>
    <checklist-item-modal-edit
      v-if="showEditModal && group.is_default"
      :tag="looseTag"
      @close="showEditModal = false"
      @updated-tag="showEditModal = false"
    ></checklist-item-modal-edit>
    <confirmation-dialog
      v-if="showConfirmDelete"
      accept-label="Delete"
      :danger-mode="true"
      @cancel="showConfirmDelete = false"
      @accept="deleteGroup"
    >
      <template #message v-if="!group.is_default">
        <h2 class="text-med">
          Are you sure you want to delete tag folder "{{ group.title }}" ?
          If there are tags in this folder, the tags will also be deleted.
        </h2>
      </template>
      <template #message v-else>
        <h2 class="text-med">
          Are you sure you want to delete tag "{{ group.title }}" ?
        </h2>
      </template>
    </confirmation-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { clone, isEqual } from "lodash";
import ChecklistGroupModalEdit from "./ChecklistGroupModalEdit.vue";
import ChecklistItemModalEdit from "./ChecklistItemModalEdit.vue";
import ConfirmationDialog from "shared/components/dashboard/ConfirmationDialog.vue";
import ChecklistItem from "./ChecklistItem.vue";

export default {
  components: {
    ChecklistGroupModalEdit,
    ChecklistItemModalEdit,
    ConfirmationDialog,
    ChecklistItem,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    canMoveUp: {
      type: Boolean,
      default: false,
    },
    canMoveDown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEditModal: false,
      showConfirmDelete: false,
      hideTags: false,
    };
  },
  computed : {
    ...mapState("template", {
      allChecklistGroups: "allChecklistGroups",
      checklistGroups: "checklistGroups",
      allChecklistItems: "allChecklistItems",
      checklistItems: "checklistItems",
    }),
    groupItems: {
      get() {
        var titles = []
        for (var item in this.group.items) {
          titles.push(this.group.items[item]["title"])
        }
        return titles;
      },
    },
    items: {
      get() {
        return this.checklistItems.filter((ci) => ci.checklist_group_id == this.group.id);
      },
      async set(value) {
        if (!isEqual(value, this.items)) {
          this.setChecklistItems(value); // optimistic update
          const oldAllChecklistItems = clone(this.allChecklistItems); // backup for restoration
          try {
            await this.reorderTemplateChecklistItems({ items: value });
          } catch (error) {
            this.showSnackbar(error.message);
            this.setAllChecklistItems(oldAllChecklistItems);
          }
        }
      },
    },
    looseTag: {
      get() {
        if (this.group.is_default) {
          return this.group.items[0]
        }
        return null;
      }
    }
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    ...mapActions("template", {
      // checklist groups
      setChecklistGroups: "setChecklistGroups",
      setAllChecklistGroups: "setAllChecklistGroups",
      removeTemplateChecklistGroup: "removeTemplateChecklistGroup",
      reorderTemplateChecklistGroups: "reorderTemplateChecklistGroups",
      getAllTemplateChecklistGroups: "getAllTemplateChecklistGroups",

      // checklist items
      setChecklistItems: "setChecklistItems",
      setAllChecklistItems: "setAllChecklistItems",
      removeTemplateChecklistItem: "removeTemplateChecklistItem",
      reorderTemplateChecklistItems: "reorderTemplateChecklistItems",
      getAllTemplateChecklistItems: "getAllTemplateChecklistItems",
    }),
    toggleTagFolder () {
      this.hideTags = !this.hideTags;
    },
    moveUp() {
      this.$emit("move-up", this.group);
    },
    moveDown() {
      this.$emit("move-down", this.group);
    },
    deleteGroup() {
      this.$emit("delete", this.group);
      this.showConfirmDelete = false;
    },
    openDeleteItemModal() {
      this.itemToDeleteId = this.group.id;
      this.showConfirmDelete = true;
    },
    moveItemUp(item) {
      this.moveItemDir({ item, up: true });
    },

    moveItemDown(item) {
      this.moveItemDir({ item, down: true });
    },
    moveItemDir({ item, up = false, down = false }) {
      let checklistItems = clone(this.items);
      let itemIndex = checklistItems.indexOf(item);
      let otherIndex = itemIndex + 1;
      if (up || !down) {
        otherIndex = itemIndex - 1;
      }
      checklistItems[itemIndex] = checklistItems[otherIndex];
      checklistItems[otherIndex] = item;

      this.items = checklistItems;
    },
    async deleteItem(item) {
      const currentChecklistItems = this.items;
      if (currentChecklistItems.length <= 1) {
        this.showSnackbar("❌ Cannot delete the last checklist item.");
      } else {
        await this.removeTemplateChecklistItem({ item });
      }
      this.getAllTemplateChecklistItems();
    },
  },
};
</script>
