import { subject } from "@casl/ability";

import EmailFavoriteService from "../../../services/email-favorite";
import ExamplesService from "../../../services/examples";
import TemplateService from "../../../services/template";
import TemplateChecklistGroupService from "../../../services/template-checklist-group";
import TemplateChecklistItemService from "../../../services/template-checklist-item";
import TemplateUserService from "../../../services/template-user";
import DocumentTypeService from '../../../services/document-type';

import {
  SET_CHECKLIST_GROUPS,
  SET_CHECKLIST_ITEMS,
  SET_EXAMPLES,
  SET_TEMPLATE,
  SET_ALL_TEMPLATES,
  SET_TEMPLATE_EMAILS,
  SET_ALL_CHECKLIST_GROUPS,
  SET_ALL_CHECKLIST_ITEMS,
  SET_DOCUMENT_TYPES,
} from "./mutations";

// initial state
const state = () => ({
  allTemplates: [],
  currentTemplate: null,
  allChecklistGroups: [],
  allChecklistItems: [],
  checklistGroups: [],
  checklistItems: [],
  examples: [],
  documentTypes: [],
});

// getters
const getters = {
  getTemplateById: (state) => (id) => {
    return state.allTemplates.find((t) => t.id === id);
  },
  getTemplateByCode: (state) => (code) => {
    return state.allTemplates.find((t) => t.code === code);
  },
  getTemplatesByCollectionId: (state) => (collectionId) => {
    return state.allTemplates.filter((t) => t.collections.includes(collectionId));
  },
  getPublicTemplates: (state) => {
    return state.allTemplates.filter((t) => t.is_public == true);
  },
  getMyTemplates: (state) => {
    return state.allTemplates.filter((t) => t.roles.length);
  },
  templateId: (state) => {
    return state.currentTemplate.id;
  },
  documentTypeWithId: (state) => {
    return (typeId) => state.documentTypes.find(t => t.id == typeId);
  },
  approvedExamples: (state) => {
    return state.examples.filter((e) => e.approved);
  },
  templateFeatures: (state) => {
    return state.currentTemplate.features ?? {};
  },
};

// mutations
const mutations = {
  [SET_TEMPLATE_EMAILS](state, { templateId, emails }) {
    const templateIndex = state.allTemplates.findIndex((g) => g.id === templateId);
    const template = state.allTemplates[templateIndex];
    template.emails = emails;
    // state.allTemplates = state.allTemplates.splice(templateIndex, 0);
  },

  [SET_ALL_TEMPLATES](state, templates) {
    state.allTemplates = templates.map((t) => {
      if (t.__caslSubjectType__ !== undefined) {
        return t;
      }

      return subject("DocumentTemplate", t);
    });
  },

  [SET_TEMPLATE](state, template) {
    state.currentTemplate = template;
  },

  [SET_CHECKLIST_ITEMS](state, checklistItems) {
    state.checklistItems = checklistItems.map((item) => {
      if (item.__caslSubjectType__ !== undefined) {
        return item;
      }
      return subject("TemplateChecklistItem", item);
    });
  },

  [SET_ALL_CHECKLIST_ITEMS](state, items) {
    state.allChecklistItems = items.map((item) => {
      if (item.__caslSubjectType__ !== undefined) {
        return item;
      }
      return subject("TemplateChecklistItem", item);
    });
  },

  [SET_CHECKLIST_GROUPS](state, checklistGroups) {
    state.checklistGroups = checklistGroups.map((group) => {
      if (group.__caslSubjectType__ !== undefined) {
        return group;
      }
      return subject("TemplateChecklistGroup", group);
    });
  },

  [SET_ALL_CHECKLIST_GROUPS](state, groups) {
    state.allChecklistGroups = groups.map((group) => {
      if (group.__caslSubjectType__ !== undefined) {
        return group;
      }
      return subject("TemplateChecklistGroup", group);
    });
  },

  [SET_EXAMPLES](state, examples) {
    const newlyOrderedExamples = examples.map((example, index) => {
      example.index = index + 1; // example.index should be 1-based
      return example;
    });
    state.examples = newlyOrderedExamples;
  },
  
  [SET_DOCUMENT_TYPES](state, documentTypes) {
    state.documentTypes = documentTypes;
  },
};

// actions
const actions = {
  // template and templates
  setCurrentTemplate({ commit, state }, template) {
    commit(SET_TEMPLATE, template);
    if (template) {
      const checklistGroups = state.allChecklistGroups.filter(
        (group) => group.template_id == template.id
      );
      commit(SET_CHECKLIST_GROUPS, checklistGroups);

      const checklistItems = state.allChecklistItems.filter(
        (item) => item.template_id == template.id
      );
      commit(SET_CHECKLIST_ITEMS, checklistItems);
    }
  },

  async createTemplate({ state, commit }, { template }) {
    const response = await TemplateService.create(template);
    let newTemplates = [...state.allTemplates, response.data];
    commit(SET_ALL_TEMPLATES, newTemplates);
    return state.allTemplates.find((t) => t.id === response.data.id);
  },

  async getTemplates({ state, commit }) {
    const { data: result } = await TemplateService.all();
    commit(SET_ALL_TEMPLATES, result);
    return state.allTemplates;
  },

  async getTemplate({ dispatch, rootState }, templateId) {
    const { data: result } = await TemplateService.show(templateId, rootState.isGuest);
    dispatch("setCurrentTemplate", result);
  },

  async updateTemplate({ state, commit, dispatch }, { template }) {
    const response = await TemplateService.update(template.id, template);
    const newTemplates = [...state.allTemplates];
    const templateIndex = newTemplates.findIndex((t) => t.id === template.id);

    newTemplates[templateIndex] = response.data;
    commit(SET_ALL_TEMPLATES, newTemplates);
    dispatch("setCurrentTemplate", response.data);
    return state.allTemplates.find((t_1) => t_1.id === response.data.id);
  },

  async updateTemplateFeatures({ dispatch }, { template, features }) {
    const newTemplate = { ...template };
    newTemplate.features = features;
    dispatch("updateTemplate", { template: newTemplate });
  },

  async removeTemplate({ state, commit }, { template }) {
    await TemplateService.destroy(template.id);
    const newTemplates = state.allTemplates.filter((t) => t.id !== template.id);
    commit(SET_ALL_TEMPLATES, newTemplates);
    commit(SET_TEMPLATE, null);
    commit(SET_CHECKLIST_GROUPS, []);
    commit(SET_CHECKLIST_ITEMS, []);
  },

  async leaveTemplate({ state, commit }, { template }) {
    const membersResponse = await TemplateService.listMembers(template.id, true);
    const memberIds = membersResponse.data.map((membership) => membership.id);
    if (memberIds.length > 0) {
      await Promise.all(memberIds.map((id) => 
        TemplateService.destroyMember(template.id, id)
      ));
      const newTemplates = state.allTemplates.filter((t) => t.id !== template.id);
      commit(SET_ALL_TEMPLATES, newTemplates);
      commit(SET_TEMPLATE, null);
      commit(SET_CHECKLIST_GROUPS, []);
      commit(SET_CHECKLIST_ITEMS, []);
      return true;
    }
    return false;
  },

  // template emails
  removeEmailFromTemplate({ state, commit, dispatch }, email) {
    const template = state.allTemplates.find((t) => t.id === email.template_id);

    if (template) {
      const emails = template.emails.filter((e) => e.id !== email.id);
      commit(SET_TEMPLATE_EMAILS, { templateId: template.id, emails });

      if (emails.length > 0) {
        dispatch("drafting/setCurrentEmail", emails[0], { root: true });
      } else {
        dispatch("drafting/setCurrentEmail", null, { root: true });
      }
    }
  },

    // checklist groups
    setChecklistGroups({ commit }, checklistGroups) {
      const groups = checklistGroups || [];
      commit(SET_CHECKLIST_GROUPS, groups);
    },
  
    setAllChecklistGroups({ state, commit }, checklistGroups) {
      const groups = checklistGroups || [];
      commit(SET_ALL_CHECKLIST_GROUPS, groups);
      if (state.currentTemplate) {
        const checklistGroups = state.allChecklistGroups.filter(
          (group) => group.template_id === state.currentTemplate.id
        );
        checklistGroups.sort((a, b) => a.sort - b.sort);
        commit(SET_CHECKLIST_GROUPS, checklistGroups);
      }
    },
  
    async getAllTemplateChecklistGroups({ state, dispatch }) {
      const response = await TemplateChecklistGroupService.list();
      dispatch("setAllChecklistGroups", response.data);
      return state.allChecklistGroups;
    },
  
    async getChecklistGroupsByTemplate({ state, dispatch }, { templateId, isGuest = false }) {
      const response = await TemplateChecklistGroupService.listByTemplate(templateId, isGuest);
      dispatch("setAllChecklistGroups", response.data);
      return state.allChecklistGroups;
    },
  
    async createTemplateChecklistGroup({ state, dispatch }, { group }) {
      const response = await TemplateChecklistGroupService.store(group);
      let newGroup = response.data;
      let newChecklistGroups = [...state.allChecklistGroups, newGroup];
      dispatch("setAllChecklistGroups", newChecklistGroups);
      dispatch("getAllTemplateChecklistGroups");
      dispatch("getAllTemplateChecklistItems");
      return state.allChecklistGroups.find((i) => i.id === newGroup.id);
    },
  
    async removeTemplateChecklistGroup({ state, dispatch }, { group }) {
      await TemplateChecklistGroupService.destroy(group.id);
      let newChecklistGroups = state.allChecklistGroups.filter((i) => i.id != group.id);
      dispatch("setAllChecklistGroups", newChecklistGroups);
      return true;
    },
  
    async updateTemplateChecklistGroup({ state, dispatch }, { group }) {
      const response = await TemplateChecklistGroupService.update(group.id, group);
      let newChecklistGroups = state.allChecklistGroups.slice();
      let oldIndex = newChecklistGroups.findIndex((i) => i.id === group.id);
      newChecklistGroups[oldIndex] = response.data;
      dispatch("setAllChecklistGroups", newChecklistGroups);
      dispatch("getAllTemplateChecklistGroups");
      return state.allChecklistGroups.find((i_1) => i_1.id === group.id);
    },
  
    async reorderTemplateChecklistGroups({ state, dispatch }, { groups }) {
      try {
        const groupIds = groups.map((group) => group.id);
        const otherGroups = state.allChecklistGroups.filter((group) => !groupIds.includes(group.id));
        const { data: reorderedGroups } = await TemplateChecklistGroupService.reorder(groupIds);
        const newChecklistGroups = [...otherGroups, ...reorderedGroups];
        dispatch("setAllChecklistGroups", newChecklistGroups);
      } catch (error) {
        if (error.status === 409) {
          throw new Error("❌ Tags out of date. Please reload to get the latest tag groups.");
        } else if (error.status === 403) {
          throw new Error("❌ Permission denied. You are not allowed to reorder tag groups.");
        } else if (error.status === 500) {
          throw new Error("❌ Server error. Please try again later.");
        }
        throw Error(error);
      }
    },  

  // checklist items
  setChecklistItems({ commit }, checklistItems) {
    const items = checklistItems || [];
    commit(SET_CHECKLIST_ITEMS, items);
  },

  setAllChecklistItems({ state, commit }, checklistItems) {
    const items = checklistItems || [];
    commit(SET_ALL_CHECKLIST_ITEMS, items);
    if (state.currentTemplate) {
      const checklistItems = state.allChecklistItems.filter(
        (item) => item.template_id === state.currentTemplate.id
      );
      checklistItems.sort((a, b) => a.sort - b.sort);
      commit(SET_CHECKLIST_ITEMS, checklistItems);
    }
  },

  async getAllTemplateChecklistItems({ state, dispatch }) {
    const response = await TemplateChecklistItemService.list();
    dispatch("setAllChecklistItems", response.data);
    return state.allChecklistItems;
  },

  async getChecklistItemsByTemplate({ state, dispatch }, { templateId, isGuest = false }) {
    const response = await TemplateChecklistItemService.listByTemplate(templateId, isGuest);
    dispatch("setAllChecklistItems", response.data);
    return state.allChecklistItems;
  },

  async createTemplateChecklistItem({ state, dispatch }, { item }) {
    const response = await TemplateChecklistItemService.store(item);
    let newItem = response.data;
    let newChecklistItems = [...state.allChecklistItems, newItem];
    dispatch("setAllChecklistItems", newChecklistItems);
    dispatch("getAllTemplateChecklistGroups");

    return state.allChecklistItems.find((i) => i.id === newItem.id);
  },

  async removeTemplateChecklistItem({ state, dispatch }, { item }) {
    await TemplateChecklistItemService.destroy(item.id);
    let newChecklistItems = state.allChecklistItems.filter((i) => i.id != item.id);
    dispatch("setAllChecklistItems", newChecklistItems);
    return true;
  },

  async updateTemplateChecklistItem({ state, dispatch }, { item }) {
    const response = await TemplateChecklistItemService.update(item.id, item);
    let newChecklistItems = state.allChecklistItems.slice();
    let oldIndex = newChecklistItems.findIndex((i) => i.id === item.id);
    newChecklistItems[oldIndex] = response.data;
    dispatch("setAllChecklistItems", newChecklistItems);
    dispatch("getAllTemplateChecklistGroups");
    return state.allChecklistItems.find((i_1) => i_1.id === item.id);
  },

  async reorderTemplateChecklistItems({ state, dispatch }, { items }) {
    try {
      const itemIds = items.map((item) => item.id);
      const otherItems = state.allChecklistItems.filter((item) => !itemIds.includes(item.id));
      const { data: reorderedItems } = await TemplateChecklistItemService.reorder(itemIds);
      const newChecklistItems = [...otherItems, ...reorderedItems];
      dispatch("setAllChecklistItems", newChecklistItems);
    } catch (error) {
      if (error.status === 409) {
        throw new Error("❌ Tags out of date. Please reload to get the latest tags.");
      } else if (error.status === 403) {
        throw new Error("❌ Permission denied. You are not allowed to reorder tags.");
      } else if (error.status === 500) {
        throw new Error("❌ Server error. Please try again later.");
      }
      throw Error(error);
    }
  },

  // examples
  setExamples({ commit }, examples) {
    commit(SET_EXAMPLES, examples);
  },

  async getExamples({ commit }, templateId) {
    const { data: result } = await ExamplesService.list(templateId);
    commit(SET_EXAMPLES, result);
  },

  async updateExample({ state, commit }, updatedExample) {
    const response = await ExamplesService.update(updatedExample.id, updatedExample);
    if (response.status === 200) {
      const examples = [...state.examples];
      const exampleIndex = state.examples.findIndex((e) => e.id === updatedExample.id);
      if (exampleIndex > -1) {
        examples[exampleIndex] = response.data;
      }
      commit(SET_EXAMPLES, examples);
    }
  },

  async favoriteExample({ commit }, example) {
    const { data: result } = await EmailFavoriteService.store({
      source_id: example.id,
    });
    if (result.success) {
      const examples = result.data;
      const exampleIndex = examples.findIndex((e) => e.id === example.id);
      if (exampleIndex > -1) {
        examples[exampleIndex].saved = true;
      }
      commit(SET_EXAMPLES, examples);
    }
  },

  async deleteExample({ state, commit }, example) {
    const response = await ExamplesService.destroy(example.id);
    if (response.status === 204) {
      const examples = state.examples.filter((e) => e.id !== example.id);
      commit(SET_EXAMPLES, examples);
    }
  },

  async reorderExamples({ commit }, examples) {
    commit(SET_EXAMPLES, examples);

    try {
      const example_ids = examples.map((e) => e.id);
      const { data: reorderedExamples } = await ExamplesService.reorder(example_ids);
      commit(SET_EXAMPLES, reorderedExamples);
    } catch (error) {
      if (error.status === 409) {
        throw new Error("❌ Examples out of date. Please reload to get the latest examples.");
      } else if (error.status === 403) {
        throw new Error("❌ Permission denied. You are not allowed to reorder examples.");
      } else if (error.status === 500) {
        throw new Error("❌ Server error. Please try again later.");
      }
      throw Error(error);
    }
  },

  // users
  async removeUserFromTemplate({ state, dispatch }, userId) {
    const { data: result } = await TemplateUserService.destroy(userId);
    if (result.success) {
      dispatch("getTemplate", state.currentTemplate.id);
    }
  },

  async fetchDocumentTypes({ commit }) {
    let response = await DocumentTypeService.list();
    let docTypes = response.data.map((docType) => ({
        id: docType.id,
        includesSubject: docType.includes_subject,
        isPaginated: docType.is_paginated,
        canEmail: docType.can_email,
        canExport: docType.can_export,
    }));
    commit(SET_DOCUMENT_TYPES, docTypes);
  },
};

export const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default store;
