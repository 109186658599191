<template>
  <modal :visible="visible" @close="close" class="cohort-upload">
    <template #content>
      <div>
        <h2 class="text-med">
          {{ title }}
        </h2>
        <form action="" method="POST" @submit.prevent="upload">
          <div class="modal-form-field">
            <label class="form-label bold" for="cohort-file">File</label>
            <p class="description-text">
              Upload several members at once by using a CSV. Please download this
              <a href="/static/images/lettersmith-cohort-upload-template.csv">
                example CSV template</a
              >
              then upload once your CSV file is ready.
            </p>
            <input
              id="cohort-file"
              class="input"
              :class="{ error: $v.file.$error }"
              type="file"
              @input="updateCohortCSV"
              ref="fileupload"
            />
            <div v-if="errorMessages.length">
              <p v-for="(error, index) in errorMessages" :key="index" class="error-text">
                {{ error }}
              </p>
            </div>
          </div>
          <div class="modal-form-field">
            <label>
              <input type="checkbox" v-model="sendEmail" />
              Send Members Invite Email After Upload
            </label>
          </div>
        </form>
      </div>
    </template>
    <template #footer>
      <div class="flex hc vc">
        <button class="button full primary fill" :disabled="$v.file.$invalid" @click="upload">
          {{ buttonTitle }}
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

import Modal from "shared/components/Modal.vue";

export default {
  name: "BulkUserUploadModal",
  components: { Modal },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    memberService: {
      type: Object,
      required: true,
    },
    serviceItemId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      title: "Upload Members",
      errorMessages: [],
      buttonTitle: "Upload",
      file: null,
      sendEmail: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions(["updateUserName", "showSnackbar"]),
    close() {
      this.$emit("close");
      this.errorMessages = [];
      this.file = null;
      this.$refs.fileupload.value = null;
      this.$v.$reset.call();
    },
    upload() {
      const bulkUploadForm = new FormData();
      bulkUploadForm.append("file", this.file);
      bulkUploadForm.append("send_email", this.sendEmail);

      this.memberService
        .bulkUserUpload(this.serviceItemId, bulkUploadForm)
        .then((response) => {
          if (response.data.success) {
            this.$emit("uploaded");
            this.close();
            this.showSnackbar("Successfully imported your CSV!");
          } else {
            this.errorMessages = response.data.errors;
          }
        })
        .catch((response) => {
          if (response.data.errors) {
            this.errorMessages = response.data.errors;
          } else {
            this.errorMessages = Object.values(response.data).flat();
          }
        });
    },
    updateCohortCSV(e) {
      this.file = e.target.files[0];
    },
  },
  validations: {
    file: {
      required,
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  flex: 1;
}
input {
  flex: 4;
  &.error {
    border-color: #b93c5f;
  }
}
.warp {
  flex-wrap: wrap;
}
</style>
