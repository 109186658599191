<template>
  <div class="associated-collection" v-if="!isEditing">
    <div>
      <img
        v-if="selectedCollections.length > 0"
        class="folder-icon"
        src="/static/images/icons/folder-icon.svg"
        alt=""
      />
      <em class="no-collections" v-if="selectedCollections.length === 0">No Collections</em>
      {{ selectedCollections.map((c) => c.name).join(", ") }}
      <Can I="change" :this="template">
        <button v-if="false" @click="isEditing = true">edit</button>
      </Can>
    </div>
  </div>
  <div v-else>
    <ul>
      <li v-for="collection in collections" :key="collection.id">
        <label>
          <input
            type="checkbox"
            :checked="collectionIsChecked(collection)"
            @change="toggleCollection(collection)"
            :disabled="!$can('change', collection)"
          />
          {{ collection.name }}
        </label>
      </li>
    </ul>
    <button @click="isEditing = false">done</button>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    template: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isEditing: false,
    };
  },

  computed: {
    ...mapState("collection", { collections: "collections" }),

    selectedCollections() {
      let templateId = this.template.id;
      return this.collections.filter((c) => c.templates.includes(templateId));
    },
    collectionIsChecked() {
      let selectedCollections = this.selectedCollections;
      let selectedIds = selectedCollections.map((c) => c.id);

      return (c) => selectedIds.includes(c.id);
    },
  },
  methods: {
    ...mapActions("collection", {
      addTemplateToCollection: "addTemplateToCollection",
      removeTemplateFromCollection: "removeTemplateFromCollection",
    }),

    toggleCollection(collection) {
      if (this.collectionIsChecked(collection)) {
        this.removeTemplateFromCollection({ collection, template: this.template });
      } else {
        this.addTemplateToCollection({ collection, template: this.template });
      }
    },
  },
};
</script>
