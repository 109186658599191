import Vue from 'vue'
import { getCurrentInstance } from '@vue/composition-api'
import { NavigationGuard } from 'vue-router'
import { ComponentOptions } from 'vue/types/umd'

export function onHook(
  name: keyof ComponentOptions<Vue>,
  callback: (...args: any) => void  // eslint-disable-line @typescript-eslint/no-explicit-any
): void {
  const vm = getCurrentInstance()
  const merge = Vue.config.optionMergeStrategies[name]
  if (vm && merge) {
    const prototype = Object.getPrototypeOf(vm.proxy.$options)
    if (prototype[name]) {
      delete prototype[name];
    }
    prototype[name] = merge(vm.proxy.$options[name], callback)
  }
}

export function onBeforeRouteUpdate(callback: NavigationGuard<Vue>): void {
  return onHook('beforeRouteUpdate', callback)
}

export function onBeforeRouteLeave(callback: NavigationGuard<Vue>): void {
  return onHook('beforeRouteLeave', callback)
}
