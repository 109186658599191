import { AbilityBuilder, Ability } from "@casl/ability";

const { can, build } = new AbilityBuilder(Ability);

can("view", "DocumentTemplate", { perms: "groups.view_documenttemplate" });
can("add", "DocumentTemplate", { perms: "groups.add_documenttemplate" });
can("change", "DocumentTemplate", { perms: "groups.change_documenttemplate" });
can("delete", "DocumentTemplate", { perms: "groups.delete_documenttemplate" });
can("create_a_tag_for", "DocumentTemplate", {
  perms: "checklists.add_templatechecklistitem",
});
can("delete_submission", "DocumentTemplate", { perms: "drafts.delete_submission" });

can("view", "TemplateCollection", { perms: "groups.view_templatecollection" });
can("add", "TemplateCollection", { perms: "groups.add_templatecollection" });
can("change", "TemplateCollection", {
  perms: "groups.change_templatecollection",
});
can("delete", "TemplateCollection", {
  perms: "groups.delete_templatecollection",
});

can("view", "TemplateChecklistGroup", {
  perms: "checklists.view_templatechecklistgroup",
});
can("add", "TemplateChecklistGroup", {
  perms: "checklists.add_templatechecklistgroup",
});
can("change", "TemplateChecklistGroup", {
  perms: "checklists.change_templatechecklistgroup",
});
can("delete", "TemplateChecklistGroup", {
  perms: "checklists.delete_templatechecklistgroup",
});

can("view", "TemplateChecklistItem", {
  perms: "checklists.view_templatechecklistitem",
});
can("add", "TemplateChecklistItem", {
  perms: "checklists.add_templatechecklistitem",
});
can("change", "TemplateChecklistItem", {
  perms: "checklists.change_templatechecklistitem",
});
can("delete", "TemplateChecklistItem", {
  perms: "checklists.delete_templatechecklistitem",
});

can("add_participant_to", "DocumentTemplate", {
  perms: "groups.add_documenttemplate_participant",
});
can("add_editor_to", "DocumentTemplate", {
  perms: "groups.add_documenttemplate_editor",
});
can("add_owner_to", "DocumentTemplate", {
  perms: "groups.add_documenttemplate_owner",
});
can("add_participant_to", "TemplateCollection", {
  perms: "groups.add_templatecollection_participant",
});
can("add_editor_to", "TemplateCollection", {
  perms: "groups.add_templatecollection_editor",
});
can("add_owner_to", "TemplateCollection", {
  perms: "groups.add_templatecollection_owner",
});
can("remove_participant_of", "DocumentTemplate", {
  perms: "groups.delete_documenttemplate_participant",
});
can("remove_editor_of", "DocumentTemplate", {
  perms: "groups.delete_documenttemplate_editor",
});
can("remove_owner_of", "DocumentTemplate", {
  perms: "groups.delete_documenttemplate_owner",
});
can("remove_participant_of", "TemplateCollection", {
  perms: "groups.delete_templatecollection_participant",
});
can("remove_editor_of", "TemplateCollection", {
  perms: "groups.delete_templatecollection_editor",
});
can("remove_owner_of", "TemplateCollection", {
  perms: "groups.delete_templatecollection_owner",
});

export default build();
