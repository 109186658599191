<template>
  <div class="container interior flex column">
    <et-header>
      <burger></burger>
    </et-header>
    <div class="container flex flex-fill">
      <div class="email-sidebar-desktop">
        <nav class="email-sidebar account">
          <ul class="no-list-styling">
            <li class="no-hover">
              <img class="back" src="/static/images/icons/back.png" alt="" />
              <router-link class="accent-yellow" :to="{ name: 'Dashboard' }">
                Back To Dashboard
              </router-link>
            </li>
            <li class="active">Site Administration</li>
          </ul>
        </nav>
      </div>
      <slide-out-side-nav>
        <div class="email-sidebar account">
          <ul class="no-list-styling">
            <li class="no-hover">
              <img class="back" src="/static/images/icons/back.png" />
              <router-link class="accent-yellow" :to="{ name: 'Dashboard' }">
                Back To Dashboard
              </router-link>
            </li>
            <li class="active">Site Administration</li>
          </ul>
        </div>
      </slide-out-side-nav>
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "Admin",

  created() {
    console.log("AdminModule.create");
    this.getRegistrations();
  },

  methods: {
    ...mapActions("admin", ["getRegistrations"]),
  },
};
</script>

<style scoped></style>
