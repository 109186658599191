export function copyElement(element: HTMLElement): void {
  window.getSelection().removeAllRanges();
  const range = document.createRange();
  range.selectNode(element);
  window.getSelection().addRange(range);
  document.execCommand("copy");
  window.getSelection().removeAllRanges();
}

export function copyElementById(id: string): void {
  window.getSelection().removeAllRanges();
  const range = document.createRange();
  range.selectNode(document.getElementById(id));
  window.getSelection().addRange(range);
  document.execCommand("copy");
  window.getSelection().removeAllRanges();
}

export function copyTextToClipboard(text: string): void {
  navigator.clipboard.writeText(text);
}
