<template>
  <button
    class="button-icon tooltip-icon"
    aria-label="Open Onboarding Modal"
    aria-haspopup="dialog"
  >
  </button>
</template>

<script>
export default {
  name: "OnboardingButton",
};
</script>
