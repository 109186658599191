import { Ref, ref } from "@vue/composition-api";

import { useStore } from "shared/composables";
import SubmissionsService, { DraftSubmissionResponse } from "services/submissions";

interface SubmissionData {
  submission: Ref<DraftSubmissionResponse>;
  isDataReady: Ref<boolean>;
}

const submission = ref<DraftSubmissionResponse>();

export function useSubmission(slug = ""): SubmissionData {
  const store = useStore();

  const isDataReady = ref(false);

  const loadData = async () => {
    store.dispatch("showLoader", "Loading checklist items");
    const result = await SubmissionsService.get(slug);
    submission.value = result.data;

    // for template checklist items & groups in SubmissionDetail.vue
    if (
      store.state.template.checklistItems.length === 0 ||
      store.state.template.id !== submission.value.template_id
    ) {
      await store.dispatch("template/getChecklistItemsByTemplate", {
        templateId: submission.value.template_id,
      });
      await store.dispatch("template/getChecklistGroupsByTemplate", {
        templateId: submission.value.template_id,
      });
      await store.dispatch("template/getTemplate", submission.value.template_id);
    }
  };

  if (slug && slug !== submission.value?.slug) {
    loadData().then(() => {
      store.dispatch("hideLoader");
      isDataReady.value = true;
    });
  } else {
    isDataReady.value = true;
  }

  return { submission, isDataReady };
}
