<template>
  <div class="about external-page">
    <header class="header external">
      <external-header></external-header>
    </header>
    <main id="main" class="container about-page">
      <div class="external-section our-story">
        <div class="our-story-text">
          <h1 class="title serif">Our Story</h1>
          <p class="text-med">
            Have you ever spent hours writing an email to someone important? Or received a poorly
            written email from someone reaching out for help? Lettersmith was developed to address
            the frequent communication snags that we all encounter in our day-to-day lives. There
            are a lot of general “How to write a good email” suggestions out there, but these tips
            can only take novice writers so far. What we write depends highly on the audience and
            context, whether it is asking for a job interview or reaching out to a client.
          </p>
        </div>
        <div class="our-video">
          <iframe
            title="Lettersmith overview video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/9IDVnvOd8cY"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div>
        <div class="text-med">
          <p>
            Lettersmith helps its users build writing skills to communicate more effectively with
            clients, employers, and fellow professionals. Users can:
          </p>
          <br />
          <ul class="list-section">
            <li>
              Follow along with a checklist of best practices specific to different writing contexts
            </li>
            <li>See examples of communication from experts and peers</li>
            <li>Get feedback on their writing from experts and peers</li>
          </ul>
          <br />
          <p>
            This writing tool builds on the collaborative expertise of a writing community to
            produce guidance that is specific to one’s individual communication goals. Better emails
            and letters can mean stronger professional relationships, better project planning and
            reflection, and more confidence in your communication.
          </p>
        </div>
      </div>
      <div class="external-section our-team" data-aos="fade-up" data-aos-duration="500">
        <div class="our-team-text right-section">
          <div>
            <h2 class="sub-title serif">Our Team</h2>
            <p class="text-med text">
              Our team is made up of designers, developers, behavioral scientists, researchers, and
              students who work to ensure a pleasant and meaningful experience for Lettersmith
              users.
            </p>
          </div>
          <div class="text-med text">
            <p>
              <b>Contact us:</b> <br />For questions about Lettersmith, please contact us at
              <a href="mailto:lettersmith-support@umich.edu">lettersmith-support@umich.edu</a>
            </p>
          </div>
        </div>
        <div class="our-team-images-block">
          <div>
            <span class="team-member">
              <img
                src="/static/images/team-images/julie-info.png"
                alt="Julie Hui - Assistant Professor in U-M School of Information, Lettersmith Faculty Innovator"
                class="our-team-images"
              />
              <img
                src="/static/images/team-images/julie.png"
                alt=""
                class="our-team-images image"
              />
            </span>
            <span class="team-member">
              <img
                src="/static/images/team-images/james-info.png"
                alt="James Alexander - Senior Software Ambassador"
                class="our-team-images"
              />
              <img
                src="/static/images/team-images/james.png"
                alt=""
                class="our-team-images image"
              />
            </span>
          </div>
          <div>
            <div>
              <span class="team-member">
                <img
                  src="/static/images/team-images/holly-info.png"
                  alt="Holly Derry - Associate Director, Behavioral Science"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/holly.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
              <span class="team-member">
                <img
                  src="/static/images/team-images/david-info.png"
                  alt="David Corneail - User Experience Designer"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/david.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
            </div>
            <div>
              <span class="team-member">
                <img
                  src="/static/images/team-images/ben-info.png"
                  alt="Ben Hayward - Director of Software Development and User Experience Design"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/ben.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
              <span class="team-member">
                <img
                  src="/static/images/team-images/cait-info.png"
                  alt="Cait Hayward - Associate Director, Research and Development"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/cait.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
            </div>
            <div>
              <span class="team-member">
                <img
                  src="/static/images/team-images/nathan-info.png"
                  alt="Nathan Magyar - Software Development"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/nathan.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
              <span class="team-member">
                <img
                  src="/static/images/team-images/macy-info.png"
                  alt="Macy Morrow - User Experience Designer"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/macy.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
            </div>
            <div>
              <span class="team-member">
                <img
                  src="/static/images/team-images/sundeep-info.png"
                  alt="Sundeep Nagumalli - Systeams Administrator"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/sundeep.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
              <span class="team-member">
                <img
                  src="/static/images/team-images/david-n-info.png"
                  alt="David Nesbitt - Software Portfolio Manager"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/david-n.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
            </div>
            <div>
              <span class="team-member">
                <img
                  src="/static/images/team-images/dennis-info.png"
                  alt="Dennis O'Reilly - Application Architect"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/dennis.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
              <span class="team-member">
                <img
                  src="/static/images/team-images/becky-info.png"
                  alt="Becky Peng - User Experience Design Fellow"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/becky.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
            </div>
            <div>
              <span class="team-member">
                <img
                  src="/static/images/team-images/ollie-info.png"
                  alt="Ollie Saunders - Software Development and Dev Ops Manager"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/ollie.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
              <span class="team-member">
                <img
                  src="/static/images/team-images/michael-info.png"
                  alt="Michael Skib - Quality Assurance Analyst"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/michael.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
            </div>
            <div>
              <span class="team-member">
                <img
                  src="/static/images/team-images/xucong-info.png"
                  alt="Xucong Zhan - Software Development"
                  class="our-team-images"
                />
                <img
                  src="/static/images/team-images/xucong.png"
                  alt=""
                  class="our-team-images image"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="external-section whats-new" data-aos="fade-up" data-aos-duration="500">
        <h2 id="whats-new-header" class="sub-title serif">What's New</h2>
        <div ref="newItemsContainer" class="new-items-container">
          <div class="gradient-border gradient-border-left" :class="{ hidden: !hasLeftOverflow }">
            <img
              src="/static/images/about-page/read-more.svg"
              alt=""
              class="read-more read-more-left"
            />
          </div>
          <dl class="new-items" @scroll="onScroll" aria-labelledby="whats-new-header" tabindex="0">
            <dt ref="firstNewItem" class="new-item-date first-new-item">
              <b>Jan</b> <br />
              2020
            </dt>
            <dd class="new-item">
              <div class="new-item-title">
                <div class="text-med">Compose and Send</div>
                <div class="tag">Improvements</div>
              </div>
              Lettersmith now allows for users to send an email directly to recipients as a final
              step when composing a draft. The styling of these emails can be customized with a
              newly added Rich Text Editor within the drafting step.
            </dd>
            <dt class="new-item-date">
              <b>Aug</b> <br />
              2020
            </dt>
            <dd class="new-item">
              <div class="new-item-title">
                <div class="text-med">Account Page</div>
                <div class="tag pink-tag">New Features</div>
              </div>
              Students and teachers now have the ability to customize and easily update their
              profile image and name within Lettersmith’s new Account page.
            </dd>
            <dt class="new-item-date">
              <b>Aug</b> <br />
              10th 2020
            </dt>
            <dd ref="lastNewItem" class="new-item last-new-item">
              <div class="new-item-title">
                <div class="text-med">Application Migration to Center for Academic Innovation</div>
              </div>
              Lettersmith was brought on as a new application and a full-team was assigned to
              develop the next wave of features and enhancements to an already existing system.
            </dd>
          </dl>
          <div class="gradient-border gradient-border-right" :class="{ hidden: !hasRightOverflow }">
            <img src="/static/images/about-page/read-more.svg" alt="" class="read-more" />
          </div>
        </div>
      </div>
      <div class="external-section testimonials" data-aos="fade-up" data-aos-duration="500">
        <h2 class="sub-title serif">Testimonials</h2>
        <p class="text-med text">
          View some of what advisors, faculty, and students have to say about Lettersmith. Many
          thanks to those who have provided feedback throughout Lettersmith’s history.
        </p>
        <ul class="user-testimonials" aria-label="Testimonials">
          <li class="text-small all-testimonials">
            <h3 class="text-med testimonial-title">From SI Instructor</h3>
            <blockquote>
              It allowed us to remove some of the basic instruction of how to construct these
              communications. We don't have to say, this is what a first sentence looks like. This
              is what a second sentence looks like, because it's laid out in the tool.
            </blockquote>
          </li>
          <li class="text-small all-testimonials">
            <h3 class="text-med testimonial-title">From SI Instructor</h3>
            <blockquote>
              When a class is that size, there's just too many. We have four different sections that
              all meet. So it's really hard to keep track of what's going on. In this case, we don't
              have to do any of that. If you complete your Lettermith assignment, then we know that
              to do that, you have to highlight what you think agreeing is and you have to highlight
              the connection to the project or your interest in working with the client or whatever
              it is. We can just define those pieces and they can move forward. And they do it on
              their own. It allowed us to remove some of the basic instruction of how to construct
              these communications. We don't have to say, this is what a first sentence looks like.
              This is what a second sentence looks like, because it's laid out in the tool.
            </blockquote>
          </li>
          <li class="text-small all-testimonials">
            <h3 class="text-med testimonial-title">From SI Instructor</h3>
            <blockquote>
              I also recognized that [Using Lettersmith] was modeling, that I hadn't done this in
              other classes. [The students] could actually see what it was I was expecting, it was
              very clear, which any of the negative feedback that I get from students working with
              me is that things aren't clear. I kind of gloss over big concepts without helping them
              connect it to, so it made it very clear. You should tell the students to tell the
              client why you're interested in this project. This is where you should look for, how
              can you make a change here.
            </blockquote>
          </li>
          <li class="text-small all-testimonials">
            <h3 class="text-med testimonial-title">From I6 Instructor</h3>
            <blockquote>
              Students feel like they already know what they're doing. So being able to use a tool
              that standardizes it was really helpful. Rather than standing in front of them and
              being like, you will do this and the class being like, `don't tell me how to write an
              email. I know how to do it.’ When I'm like you actually don't because I see the
              evidence of it. There's a certain expectation in industry of what people want or
              expect from a student team, and that student maybe is not as prepared as other
              students. And so like the gap of expectations, and how to teach that appropriately, I
              think is very challenging.
            </blockquote>
          </li>
        </ul>
      </div>
      <div class="external-section FAQ" data-aos="fade-up" data-aos-duration="500">
        <h2 id="faq-header" class="sub-title serif">Frequently Asked Questions</h2>
        <dl class="text-med" aria-labelledby="faq-header">
          <dt class="question"><span class="text-med serif">Q:</span> <b>Is it free?</b></dt>
          <dd class="answer">
            <span class="text-med serif">A:</span> Yes! Anyone can use Lettersmith at no cost. Reach
            out to <a href="mailto:lettersmith-support@umich.edu">our team</a> for a walk-through.
          </dd>
          <dt class="question">
            <span class="text-med serif">Q:</span> <b>How can I start using Lettersmith?</b>
          </dt>
          <dd class="answer">
            <span class="text-med serif">A:</span> You can sign-in to Lettersmith and start using it
            right away. We also provide set up step-by-step instructions. If you’d like a live walk
            through of the tool for your needs, please contact
            <a href="mailto:lettersmith-support@umich.edu">lettersmith-support@umich.edu</a>.
          </dd>
          <dt class="question">
            <span class="text-med serif">Q:</span>
            <b>Are there existing writing templates I can adopt for class?</b>
          </dt>
          <dd class="answer">
            <span class="text-med serif">A:</span> Currently, we have three public templates: Cold
            Email, Cover Letter, and Follow Up. We are in the process of creating more, so if you
            have any suggestions, please let us know!
          </dd>
        </dl>
        <a class="button-secondary" href="mailto:lettersmith-support@umich.edu">
          Give Us Feedback
        </a>
      </div>
    </main>
    <et-footer></et-footer>
  </div>
</template>

<script>
export default {
  data: () => ({
    firstNewItemX: 0,
    lastNewItemX: 600,
    newItemsX: 0,
  }),

  computed: {
    hasLeftOverflow() {
      return this.newItemsX - this.firstNewItemX > 0;
    },

    hasRightOverflow() {
      const newItemAvailableLeftMargin = 600; // pixels
      return this.lastNewItemX - this.newItemsX >= newItemAvailableLeftMargin;
    },
  },

  methods: {
    onScroll() {
      this.updateNewItemsPositions();
    },

    updateNewItemsPositions() {
      this.newItemsX = this.$refs.newItemsContainer.getBoundingClientRect().x;
      this.firstNewItemX = this.$refs.firstNewItem.getBoundingClientRect().x;
      this.lastNewItemX = this.$refs.lastNewItem.getBoundingClientRect().x;
    },
  },
};
</script>
