<template>
  <transition name="modal">
    <div
      class="modal-mask"
      v-if="visible"
      v-focus-trap
      @keyup.esc="$emit('close')"
      @mousedown.self="onBackgroundMouseDown"
    >
      <div class="modal-wrapper">
        <div
          class="modal-container"
          :class="{ 'modal-container__large': large, 'modal-container__extra-large': exLarge }"
          role="dialog"
          aria-modal="true"
          aria-labelledby="dialog_label"
        >
          <div class="modal-content" :class="{ 'modal-content__no-padding': noPadding }">
            <div v-if="showClose" class="modal-close">
              <button
                class="remove-icon button-icon"
                v-if="showClose"
                data-close-button="true"
                aria-label="Close"
                @click="$emit('close')"
              >
              </button>
            </div>
            <slot name="content"> </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false,
    },

    exLarge: {
      type: Boolean,
      default: false,
    },

    visible: {
      type: Boolean,
      required: true,
    },

    noPadding: {
      type: Boolean,
      default: false,
    },

    showClose: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onBackgroundMouseDown(mouseDownEvent) {
      document.addEventListener(
        "mouseup",
        (mouseUpEvent) => {
          if (mouseDownEvent.target == mouseUpEvent.target) {
            this.$emit("close");
          }
        },
        { once: true }
      );
    },
  },
};
</script>
