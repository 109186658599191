<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 id="dialog_label" class="text-med">Join as Participant</h2>
      <div class="modal-body public-templates-modal">
        <p>
          You are about to join the <b>“{{ template.name }}”</b> template as a participant, which will give you the ability to view examples and create drafts. 
          This template will be added to your dashboard. Continue?
        </p>
      </div>
    </template>
    <template #footer>
      <div class="buttons">
        <button 
          class="button-secondary"
          @click="$emit('close')"
        >
          Cancel
        </button>
        <button class="button" @click="joinTemplate">Continue</button>
      </div>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";
import TemplateService from "services/template";
import { mapActions, mapState, mapMutations } from "vuex";
import { SET_ALL_TEMPLATES } from "../../../modules/template/store/mutations";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      retrieving: false,
      code: "",
    };
  },
  computed: {
    ...mapState("template", { allTemplates: "allTemplates" }),
  },
  methods: {
    ...mapActions("template", {
      getAllTemplateChecklistItems: "getAllTemplateChecklistItems",
      getTemplates: "getTemplates",
    }),
    ...mapMutations("template", {
      setTemplates: SET_ALL_TEMPLATES,
    }),
    joinTemplate() {
      this.retrieving = true;
      this.code = this.template.code;
      TemplateService.join(this.template.code)
        .then((response) => {
          if (response.status == 200) {
            const allTemplates = this.allTemplates;
            const newTemplate = response.data;
            if (!this.allTemplates.some((t) => t.id === newTemplate.id)) {
              this.setTemplates([...allTemplates, newTemplate]);
            }
            this.getAllTemplateChecklistItems();
            this.getTemplates();
            this.$emit("joined-template", newTemplate);
          }
        })
        .finally(() => {
          this.retrieving = false;
        });
    },
  }
};
</script>
