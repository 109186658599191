<template>
  <ul>
    <slot></slot>
  </ul>
</template>

<script>
export default {};
</script>
<style scoped>
.side-navigation {
  max-width: 60px;
}
ul {
  display: block;
  margin: 0;
}
</style>
