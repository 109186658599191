<template>
  <div class="tag-folder" v-if="!group.is_default">
    <div class="tag-accordion-header">
      <div class="tag-description-container">
        <button
          @click="toggleTagFolder"
          class="tag-folder-name button-link"
        >
          <slot>{{group.title}}</slot>
        </button>
        <button
          v-if="group.description"
          class="information-icon button-icon"
          @click="toggleDescription"
          aria-label="Toggle Folder Description"
        >
        </button>
        <transition name="fade">
          <div v-focus-trap class="tag-description" v-if="showDescription">
            <div class="tag-description-header">
              <div>Folder Description</div>
              <button
                class="remove-icon button-icon"
                @click="showDescription = false"
                aria-label="Close Folder Description"
              >
              </button>
            </div>
            <p tabindex="0">{{group.description}}</p>
          </div>
        </transition>
      </div>
      <button
        class="toggle-icon button-icon"
        :class="{ 'closed-icon': hideTags }"
        @click="toggleTagFolder"
        aria-label="Toggle Folder"
      >
      </button>
    </div>
    <ul class="no-list-styling" :class="{ closed: hideTags }" v-if="items.length > 0">
      <checklist-item
        class="individual-tag"
        v-for="item in items"
        :key="item.title"
        :item="item"
        :read-only="isInGuestFeedback"
        :active="isActive(item)"
        :selected-item="selectedChecklistItem"
        :logged-in="loggedIn"
        :show-cross="showCross"
        :is-tagging="isTagging"
        @select="select"
        @deselect="deselect"
        @tag="tag"
        @remove="remove"
      >
      </checklist-item>
    </ul>
    <div :class="{ closed: hideTags }" class="empty-tag" v-else>No tags in this folder</div>
  </div>
  <!-- LOOSE TAGS -->
  <ul class="no-list-styling" v-else>
    <checklist-item
      class="loose-tag"
      v-for="item in items"
      :key="item.title"
      :item="item"
      :read-only="isInGuestFeedback"
      :active="isActive(item)"
      :selected-item="selectedChecklistItem"
      :logged-in="loggedIn"
      :show-cross="showCross"
      :is-tagging="isTagging"
      @select="select"
      @deselect="deselect"
      @tag="tag"
      @remove="remove"
    >
    </checklist-item>
  </ul>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ChecklistItem from "shared/components/checklist/ChecklistItem.vue";

export default {
  name: "ChecklistGroup",

  components: {
    ChecklistItem,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    group: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showRemove: false,
      selectedChecklistItem: null,
      showCross: true,
      hideTags: false,
      showDescription: false,
    };
  },

  computed: {
    ...mapState({
      loggedIn: (state) => state.loggedIn,
      tagPosition: (state) => state.tagPosition,
      isInGuestFeedback: (state) => state.drafting.isInGuestFeedback,
      email: (state) => state.drafting.currentEmail,
      draftTags: (state) => state.drafting.currentDraftTags,
      currentSelection: (state) => state.drafting.currentSelection,
      isTagging: (state) => state.drafting.isTagging,
    }),

    ...mapState("template", {
      template: "currentTemplate",
    }),
    ...mapGetters("drafting", {
      taggedChecklistItemIds: "taggedChecklistItemIds",
    }),

    items() {
      return this.group.items;
    }
  },

  methods: {
    ...mapActions("drafting", {
      setSelectedChecklistItemId: "setSelectedChecklistItemId",
      restoreSelectedChecklistItems: "restoreSelectedChecklistItems",
      saveTag: "saveTag",
      updateTag: "updateTag",
      removeTag: "removeTag",
      setIsTagging: "setIsTagging",
      resetCurrentSelection: "resetCurrentSelection",
    }),

    toggleTagFolder () {
      this.hideTags = !this.hideTags;
    },

    toggleDescription () {
      this.showDescription = !this.showDescription;
    },

    select(item) {
      this.setSelectedChecklistItemId(item.id);
      this.selectedChecklistItem = item;
    },

    deselect() {
      this.restoreSelectedChecklistItems();
      this.selectedChecklistItem = null;
    },

    async tag(item) {
      const tagPayload = {
        email_id: this.email.id,
        template_checklist_item_id: item.id,
        start: this.currentSelection.start,
        end: this.currentSelection.end,
        content: this.currentSelection.content,
      };
      this.saveTag(tagPayload);
      this.resetCurrentSelection();
      this.setIsTagging(false);
      this.deselect();
    },

    remove(item) {
      this.draftTags
        .filter((tag) => tag.template_checklist_item_id === item.id)
        .forEach((tag) => this.removeTag(tag.id));
    },

    isActive(item) {
      const result = !!this.taggedChecklistItemIds.includes(item.id);
      return result;
    },
  },
};
</script>
