<template>
  <ls-modal @close="$emit('close')" :visible="true" :large="true" class="onboarding-modal">
    <template #content>
      <h2 class="text-med">Members</h2>
      <div class="modal-body">
        <div class="onboarding-section">
          <h3 class="onboarding-title bold">Description</h3>
          <p>Add owners, editors, and participants to your template.</p>
          <ul class="no-list-styling">
            <li>Click the "Add Member" button to quickly add people's email addresses. Add commas between email addresses to add more than one at a time.</li>
            <li>Use the "Import from CSV" button to upload a file with multiple email addresses.</li>
          </ul>
        </div>
        <div class="onboarding-section">
          <h3 class="onboarding-title bold">Permission Level Descriptions</h3>
          <p>Owners</p>
          <ul class="no-list-styling">
            <li>Owners have all editing and deleting capabilities. You can have more than one owner on a template. Add owners if you have someone you would like to collaborate on this template with or transfer ownership to.</li>
          </ul>
          <br>
          <p>Editors</p>
          <ul class="no-list-styling">
            <li>Editors are similar to owners, except they can't delete the template or the template owners. Add editors if you have someone you would like to collaborate on this template with.</li>
          </ul>
          <br>
          <p>Participants</p>
          <ul class="no-list-styling">
            <li>Participants have student level permissions. They can create drafts and view examples.</li>
          </ul>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="$emit('close')">Got It</button>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
};
</script>
