import store from "~/store";

import { routes as CollectionRoutes } from "modules/collection";
import { routes as TemplateRoutes } from "modules/template";

import DashboardHome from "./views/DashboardHome.vue";

const DashboardModule = () => import("./DashboardModule.vue");

const simpleAuth = (to, from, next) => {
  if (!store.state.loggedIn) {
    next({
      name: "Login",
      query: { next: to.fullPath },
    });
  } else {
    next();
  }
};

export const routes = [
  {
    path: "/dashboard",
    component: DashboardModule,
    beforeEnter: simpleAuth,
    children: [
      {
        path: "",
        component: DashboardHome,
        name: "Dashboard",
      },
      ...CollectionRoutes,
      ...TemplateRoutes,
    ],
  },
];
