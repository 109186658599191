<template>
  <div class="account-item" v-if="services.length !== 0">
    <div class="bold text-med">Canvas</div>
    <div class="account-input-item">
      <template v-for="serviceToken in serviceTokens">
        <button
          v-if="serviceToken.token"
          :key="serviceToken.service.slug"
          @click="disconnectFromCanvas(serviceToken.token)"
        >
          Disconnect from {{ serviceToken.service.name }}
        </button>
        <button
          v-else
          :key="serviceToken.service.slug"
          @click="connectToCanvas(serviceToken.service.slug)"
        >
          Connect to {{ serviceToken.service.name }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "@vue/composition-api";
import CanvasAPI from "~/services/canvas";
import AuthAPI from "~/services/auth";

export default defineComponent({
  setup() {
    const services = ref([]);
    const tokens = ref([]);

    const serviceTokens = computed(() => {
      const servicesValue = services.value;
      const tokensValue = tokens.value;

      return servicesValue.map((service) => {
        return {
          service,
          token: tokensValue.find((token) => token.service === service.id),
        };
      });
    });

    CanvasAPI.listServices().then((response) => {
      if (response.status === 200) {
        services.value = response.data;
      }
    });
    CanvasAPI.listTokens().then((response) => {
      if (response.status === 200) {
        tokens.value = response.data;
      }
    });

    const connectToCanvas = (serviceSlug) => {
      AuthAPI.ensureActiveSession().then(() => {
        window.location.href = `/canvas/authenticate/${serviceSlug}/?next=/account/`;
      });
    };
    const disconnectFromCanvas = async (token) => {
      const response = await CanvasAPI.destroyToken(token.id);
      if (response.status === 204) {
        tokens.value = tokens.value.filter((t) => t.id !== token.id);
      }
    };

    return {
      services,
      tokens,
      serviceTokens,

      connectToCanvas,
      disconnectFromCanvas,
    };
  },
});
</script>
