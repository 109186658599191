<template>
  <li>
    <form :action="`/download/docx/${emailId}/`" method="POST" target="_blank">
      <input type="hidden" name="JWT" :value="token" />
      <button class="item" type="submit">Export as Microsoft Word</button>
    </form>
  </li>
</template>

<script>
export default {
  props: {
    token: {
      type: String,
      required: true,
    },
    emailId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.item {
  display: block;
  padding: 8px 24px 8px 12px;
  color: var(--primary-blue-dark);
  color: #142b66; /* TODO: Remove */
  cursor: pointer;
  font-weight: 600;
  font-size: 100%;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
}

.item:hover {
  background-color: var(--primary-blue-dark);
  background-color: #142b66; /* TODO: Remove */
  color: var(--white);
  color: white; /* TODO: Remove */
}
</style>
