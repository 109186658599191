import axios from "axios";

const apiRoute = "/api/template-user/";

export default {
  store(data) {
    return axios.post(apiRoute, data);
  },

  destroy(id) {
    return axios.delete(apiRoute + id + "/");
  },
};
