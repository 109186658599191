<template>
  <ls-modal @close="$emit('close')" :large="true" :visible="true">
    <template #content>
      <h2 class="text-med">Edit Member</h2>
      <div class="modal-body">
        <div class="modal-description-text">
          <span class="bold">Currently Editing:</span> {{ member.name }} |
          <span class="italic">{{ member.email }}</span>
        </div>
        <div class="modal-form-field">
          <div class="form-label bold">What role would you like this user to have?</div>
          <label class="member-role label-with-decription" v-for="roleName in roles" :key="roleName">
            <div class="role-name input-with-decription">
              <input type="radio" :value="roleName" v-model="role" />
              {{ roleName }}
            </div>
            <div class="description-text" v-if="roleName === 'owner'">Owners have all editing and deleting capabilities. You can have more than one owner on a template. Add owners if you have someone you would like to collaborate on this template with or transfer ownership to.</div>
            <div class="description-text" v-else-if="roleName === 'editor'">Editors are similar to owners, except they can't delete the template or the template owners. Add editors if you have someone you would like to collaborate on this template with.</div>
            <div class="description-text" v-else-if="roleName === 'participant'">Participants have student level permissions. They can create drafts and view examples.</div>
          </label>
          <div v-if="errorMessage" class="error-text">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="handleSave">Save</button>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    allowedRoles: {
      type: Array,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      role: null,
    };
  },
  computed: {
    roleSuffixes() {
      return ["owner", "editor", "participant"];
    },
    roles() {
      let allowedRoles = this.allowedRoles;
      return this.roleSuffixes.filter((r) => allowedRoles.includes(r));
    },
  },
  created() {
    let currentRole = this.member.role;
    let rollComponents = currentRole.split(" ");
    let roleName = rollComponents[rollComponents.length - 1].toLowerCase();
    this.role = roleName;
  },
  methods: {
    handleSave() {
      this.$emit("save", {
        role: this.role,
      });
    },
  },
};
</script>
