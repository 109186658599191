import axios from "axios";

const apiRoute = "/users";

export default {
  getMe() {
    return axios.get(`${apiRoute}/me/`);
  },

  listUsers(type) {
    return axios.get(`${apiRoute}/${type}s/`);
  },

  updatePassword(data) {
    return axios.post(`${apiRoute}/update-user-info/password/`, data);
  },

  updatePicture(data) {
    return axios.post(`${apiRoute}/update-user-info/picture/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  updateName(data) {
    return axios.post(`${apiRoute}/update-user-info/name/`, data);
  },

  updateUserType(data) {
    return axios.post(`${apiRoute}/update-user-info/user-type/`, data);
  },

  validEmail(email) {
    return axios.get(`${apiRoute}/email-check/`, { params: { email } });
  },
};
