<template>
  <main id="main" class="draft-view-container" v-if="email">
    <finalize-modal-anonymize-email
      :visible="true"
      :original-email="email"
      @completed="completeSubmission"
      @close="goBackToDrafting"
    >
    </finalize-modal-anonymize-email>
  </main>
</template>

<script>
import { computed } from "@vue/composition-api";

import { useRouter } from "shared/composables/useRouter";
import { useStore } from "shared/composables/useStore";

import FinalizeModalAnonymizeEmail from "./FinalizeModalAnonymizeEmail.vue";

export default {
  name: "FinalizeSubmitExampleView",

  components: {
    FinalizeModalAnonymizeEmail,
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    const email = computed(() => store.state.drafting.currentEmail);

    const goBackToDrafting = () => {
      router.push({
        name: "Draft",
        params: { templateId: email.value.template_id, slug: email.value.slug },
      });
    };

    const completeSubmission = () => {
      store.dispatch("showSnackbar", "Draft example submitted successfully");
      goBackToDrafting();
    };

    return {
      email,
      goBackToDrafting,
      completeSubmission,
    };
  },
};
</script>

<style lang="scss" scoped></style>
