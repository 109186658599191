<template>
  <div class="alert" v-if="loggedIn && alert">
    <img alt="Alert information" class="alert-icon" src="/static/images/icons/info.png" />
    {{ alert }}
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["alert", "loggedIn"]),
  },
};
</script>
