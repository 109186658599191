<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 id="dialog_label" class="text-med">Create a Template</h2>
      <div class="modal-body create-template-modal-container">
        <div class="modal-form-field">
          <label for="create-a-template-modal-template-name" class="form-label bold">
            Template Name
          </label>
          <input
            class="input"
            id="create-a-template-modal-template-name"
            type="text"
            v-model="templateName"
          />
        </div>
        <div class="modal-form-field">
          <div class="form-label bold">What type of template is this?</div>
          <label class="label-with-decription" v-for="type in documentTypes" :key="type.id">
            <div class="input-with-decription">
              <input type="radio" name="template-type" v-model="documentType" :value="type.id" />
              {{ toTitleCase(type.id) }}
            </div>
            <div class="description-text" v-if="type.id === 'email'">Users will have the ability to send their drafts as emails directly from Lettersmith</div>
            <div class="description-text" v-else-if="type.id === 'document'">Users will have the ability to export their draft as a Microsoft Word document (.docx)</div>
          </label>
        </div>
        <div v-if="!collectionId" class="modal-form-field">
          <div class="form-label bold">
            Would you like to place this template inside a collection?
          </div>
          <p class="description-text">
            Collections let you organize templates together. You can share individual templates or
            collections with others.
          </p>
          <label>
            <input
              type="radio"
              name="place-in-collections"
              v-model="placeInCollections"
              :value="true"
            />
            Yes, place my template in a new or existing collection
          </label>
          <template v-if="placeInCollections">
            <div class="modal-form-field place-in-collection">
              <div class="template-list">
                <label
                  v-for="collection in allCollections"
                  :key="collection.id"
                  :class="{ disabled: !$can('change', collection) }"
                >
                  <input
                    type="checkbox"
                    :value="collection"
                    v-model="includeCollections"
                    :disabled="!$can('change', collection)"
                  />
                  {{ collection.name }}
                </label>
                <div class="text-input-option">
                  <input
                    class="create-new-checkbox"
                    type="checkbox"
                    aria-label="Create new collection"
                    v-model="addNewCollection"
                    @change="handleNewCollectionCheckboxChange"
                  />
                  <input
                    ref="newCollectionInput"
                    class="input place-in-collections"
                    id="create-a-template-modal-collection-name"
                    placeholder="New Collection Name"
                    type="text"
                    v-model="collectionName"
                    :disabled="!addNewCollection"
                  />
                </div>
              </div>
            </div>
          </template>
          <label>
            <input
              type="radio"
              name="place-in-collections"
              v-model="placeInCollections"
              :value="false"
            />
            No, don’t place my template in a collection
          </label>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="handleCreateTemplate" :disabled="!allowedToCreate">
        Create Template
      </button>
      <a href="javascript:void(0);" class="visually-hidden">End of dialog</a>
    </template>
  </ls-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    // For creating template from within a collection
    collectionId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      templateName: "",
      placeInCollections: null,
      collectionName: "",
      submitted: false,
      addNewCollection: false,
      includeCollections: [],
      documentType: null,
    };
  },
  created() {
    this.placeInCollections = this.collection !== null;
  },
  computed: {
    ...mapState("collection", { allCollections: "collections" }),
    ...mapState("template", {
      documentTypes: "documentTypes",
    }),
    allowedToCreate() {
      let hasValidName = this.templateName.trim() != "";
      let hasValidType = this.documentType !== null;
      let hasValidCollectionPlacementSelection = this.placeInCollections !== null;
      let hasValidCollectionName = !this.addNewCollection || this.collectionName.trim() != "";
      let hasValidCollectionSelection =
        !this.placeInCollections ||
        this.addNewCollection ||
        this.includeCollections.length > 0 ||
        this.collectionId !== null;

      return (
        hasValidName &&
        hasValidType &&
        hasValidCollectionPlacementSelection &&
        hasValidCollectionName &&
        hasValidCollectionSelection &&
        !this.submitted
      );
    },
  },
  methods: {
    ...mapActions("template", {
      createTemplate: "createTemplate",
      getAllTemplateChecklistItems: "getAllTemplateChecklistItems",
    }),
    ...mapActions("collection", {
      createCollection: "createCollection",
      refreshCollection: "refreshCollection",
    }),

    async handleCreateTemplate() {
      this.submitted = true;
      let templateCollections = [];

      if (this.placeInCollections) {
        if (this.collectionId) {
          templateCollections.push({ id: this.collectionId });
        } else {
          templateCollections = this.includeCollections;
          if (this.addNewCollection) {
            const newCollection = await this.createCollection({
              collection: { name: this.collectionName },
            });
            templateCollections.push(newCollection);
          }
        }
      }
      const newTemplate = await this.createTemplate({
        template: {
          name: this.templateName,
          document_type: this.documentType,
          collections: templateCollections.map((collection) => collection.id),
        },
      });
      await Promise.all(
        templateCollections.map((collection) => {
          this.refreshCollection({ collection });
        })
      );
      this.getAllTemplateChecklistItems();
      this.$emit("created-template", newTemplate);
    },
    handleNewCollectionCheckboxChange() {
      if (this.addNewCollection) {
        this.$nextTick(() => {
          this.$refs.newCollectionInput.focus();
        });
      }
    },
    toTitleCase(value) {
      return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
    },
  },
};
</script>
