<template>
  <div class="selected-draft-view text-small">
    <template v-if="draft">
      <a id="selected_draft" href="javascript:void(0);" class="visually-hidden">Selected draft</a>
      <draft-content-view-header
        :draft="draft"
        :allow-edit="allowEdit"
        :allow-remove="allowRemove"
        @edit="edit"
        @remove="$emit('remove')"
      />
      <draft-content
        class="draft-content"
        :draft="draft"
        :readonly="true"
        :remove="allowRemove"
        :hide-subject="true"
        @remove="$emit('remove')"
      />
    </template>
    <template v-else>
      <p>No draft selected</p>
    </template>
  </div>
</template>

<script>
import DraftContentViewHeader from "./DraftContentViewHeader.vue";
import DraftContent from "shared/components/draft/DraftContent.vue";

export default {
  components: {
    DraftContent,
    DraftContentViewHeader,
  },

  props: {
    draft: {
      type: Object,
      default: null,
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
    allowRemove: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    edit() {
      this.$emit("edit");
    },
  },
};
</script>
