<template>
  <li class="template-card">
    <router-link
      :to="{ name: 'Template', params: { templateId: template.id } }"
      :aria-label="template.name"
    >
      <div class="card-content dashboard-template-card">
        <header class="template-name">
          <div class="name">
            {{ template.name }}
          </div>
          <div class="role-type-wrapper">
            <Can I="change" :this="template">
              <button
                class="button-icon edit-icon"
                @click.prevent="showEditModal = true"
                :aria-label="`Edit ${template.name}`"
                aria-haspopup="true"
                ref="editTemplateTrigger"
              >
              </button>
            </Can>
            <document-type-tag :document-type="template.document_type" />
            <role-tag v-for="roleName in roleNames" :key="roleName" :role-name="roleName" />
          </div>
        </header>
        <div class="template-code">Template Code: {{ template.code }}</div>
        <div class="summary" :class="{'empty-description': !hasDescription}">
          <p class="summary-description text-small">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="11.733" height="15.917" viewBox="0 0 17.733 21.917">
              <g id="Group_1005" data-name="Group 1005" transform="translate(-619.274 -6060.263)">
                <path id="Icon_material-description" data-name="Icon material-description" d="M16.458,3H8.092A2.089,2.089,0,0,0,6.01,5.092L6,21.825a2.089,2.089,0,0,0,2.081,2.092h12.56a2.1,2.1,0,0,0,2.092-2.092V9.275ZM18.55,19.733H10.183V17.641H18.55Zm0-4.183H10.183V13.458H18.55Zm-3.137-5.229V4.569l3.9,3.9,1.847,1.847Z" transform="translate(613.774 6057.763)" fill="none" stroke="#142b66" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              </g>
            </svg> -->
            {{ descriptionText }}
          </p>
        </div>
        <div class="collections">
          <collection-select :template="template" />
        </div>
      </div>
    </router-link>
    <template-modal-edit
      v-if="showEditModal"
      :template="template"
      @close="hideAndRefocus"
      @updated-template="showEditModal = false"
    ></template-modal-edit>
  </li>
</template>

<script>
import RoleTag from "shared/components/dashboard/RoleTag.vue";
import CollectionSelect from "shared/components/collection/CollectionSelect.vue";
import TemplateModalEdit from "./TemplateModalEdit.vue";
import DocumentTypeTag from './TemplateDocumentTypeTag.vue';
import { convertToPlainText, truncateString } from 'shared/utils/truncate';

export default {
  components: {
    CollectionSelect,
    DocumentTypeTag,
    TemplateModalEdit,
    RoleTag,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEditModal: false,
    };
  },
  computed: {
    memberCount() {
      return this.template?.member_count || 0;
    },
    tagCount() {
      return this.template?.tags?.length || 0;
    },
    emailCount() {
      return this.template?.emails?.length || 0;
    },
    roleNames() {
      return this.template.roles.map((r) => {
        let elements = r.split(" ");
        return elements[elements.length - 1];
      });
    },
    hasDescription() {
      const template = this.template;

      return template.description.trim() !== "";
    },
    descriptionText() {
      let description = this.template.description;

      if (!this.hasDescription) {
        description = 'A description has not been provided yet.';
      }

      return truncateString(convertToPlainText(description), 140);
    }
  },
  methods: {
    hideAndRefocus() {
      this.showEditModal = false;
      this.$refs.editTemplateTrigger.focus();
    },
  },
};
</script>
