<template>

    <li ref="createTemplateButton" class="template-card create">
      <button @click="showCreateTemplateModal = true" class="create-card">
        <label class="text-small create-template-label">Create a Template</label>
      </button>
      <template-modal-create
        v-if="showCreateTemplateModal"
        :collection-id="collectionId"
        @close="hideAndRefocus"
        @created-template="handleNewTemplateCompletion"
      ></template-modal-create>
    </li>

</template>

<script>
import { mapActions } from "vuex";
import TemplateModalCreate from "./TemplateModalCreate.vue";

export default {
  components: {
    TemplateModalCreate,
  },

  props: {
    // For creating template from within a collection
    collectionId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      showCreateTemplateModal: false,
    };
  },

  methods: {
    ...mapActions(["showSnackbar"]),

    hideAndRefocus() {
      this.showCreateTemplateModal = false;
      this.$refs.createTemplateButton.focus();
    },
    handleNewTemplateCompletion(template) {
      this.showCreateTemplateModal = false;
      this.showSnackbar(`Successfully created ${template.name}`);
    },
  },
};
</script>
