import { subject } from "@casl/ability";

import CollectionsService from "../../../services/collections";

import { SET_COLLECTIONS } from "./mutations";

// initial state
const state = () => ({
  collections: [],
});

// getters
const getters = {
  getCollectionById: (state) => (id) => {
    return state.collections.find((c) => c.id === id);
  },

  getCollectionByTemplateId: (state) => (templateId) => {
    return state.collections.filter((c) => c.templates.includes(templateId));
  },
};

// mutations
const mutations = {
  [SET_COLLECTIONS](state, collections) {
    state.collections = collections.map((c) => {
      if (c.__caslSubjectType__ !== undefined) {
        return c;
      }
      return subject("TemplateCollection", c);
    });
  },
};

// actions
const actions = {
  async getCollections(store) {
    const response = await CollectionsService.all();
    store.commit(SET_COLLECTIONS, response.data);
    return store.state.collections;
  },

  async createCollection({ state, commit }, { collection }) {
    const response = await CollectionsService.create(collection);
    let newCollections = [...state.collections, response.data];
    commit(SET_COLLECTIONS, newCollections);
    return state.collections.find((c) => c.id === response.data.id);
  },

  async updateCollection({ state, commit }, { collection }) {
    const response = await CollectionsService.update(collection.id, collection);
    let newCollections = state.collections.slice();
    let collectionIndex = newCollections.findIndex((c) => c.id === collection.id);
    newCollections[collectionIndex] = response.data;
    commit(SET_COLLECTIONS, newCollections);
    return state.collections.find((c_1) => c_1.id === response.data.id);
  },

  async refreshCollection({ state, commit }, { collection }) {
    const response = await CollectionsService.show(collection.id);
    let newCollections = state.collections.slice();
    let collectionIndex = newCollections.findIndex((c) => c.id === collection.id);
    newCollections[collectionIndex] = response.data;
    commit(SET_COLLECTIONS, newCollections);
    return state.collections.find((c_1) => c_1.id === response.data.id);
  },

  async removeCollection({ state, commit, dispatch }, { collection }) {
    await CollectionsService.destroy(collection.id);
    let newCollections = state.collections.filter((c) => c.id !== collection.id);
    commit(SET_COLLECTIONS, newCollections);
    dispatch("template/getTemplates");
  },

  async leaveCollection({ state, commit, dispatch }, { collection }) {
    const membersResponse = await CollectionsService.listMembers(collection.id, true);
    const memberIds = membersResponse.data.map((membership) => membership.id);
    await Promise.all(memberIds.map((id) => 
      CollectionsService.destroyMember(collection.id, id)
    ));
    let newCollections = state.collections.filter((c) => c.id !== collection.id);
    commit(SET_COLLECTIONS, newCollections);
    dispatch("template/getTemplates");
  },

  // collection templates

  async addTemplateToCollection({ state, commit }, { template, collection }) {
    const response = await CollectionsService.addTemplate(collection.id, template);
    let newCollections = state.collections.map((c) => {
      if (c.id === response.data.id) {
        return response.data;
      }
      return c;
    });
    commit(SET_COLLECTIONS, newCollections);
    return true;
  },

  async removeTemplateFromCollection({ state, commit }, { template, collection }) {
    const response = await CollectionsService.removeTemplate(collection.id, template);
    let newCollections = state.collections.map((c) => {
      if (c.id === response.data.id) {
        return response.data;
      }
      return c;
    });
    commit(SET_COLLECTIONS, newCollections);
    return true;
  },
};

export const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default store;
