<template>
  <div class="details-container checklist-details">
    <header>
      <div class="header-container">
        <h2 class="text-med serif">{{ checklistName }}</h2>
        <onboarding-button
          @click.native="showOnboardingModal = true"
          ref="onboardingButton"
        ></onboarding-button>
        <span class="text-xsmall error-text bold">* Required</span>
      </div>
      <div class="button-container">
        <Can I="create_a_tag_for" :this="template">
          <button ref="createModal" class="button" @click="showCreateTagGroupModal = true">Add Folder</button>
        </Can>
        <Can I="create_a_tag_for" :this="template">
          <button
            ref="createModal"
            class="button"
            @click="showCreateTagModal = true"
            aria-haspopup="dialog"
          >
            Add Tag
          </button>
        </Can>
      </div>
    </header>
    <div class="card-container" v-if="groups.length > 0">
      <div class="table-header">
        <span class="row-item">Title</span>
        <span class="row-item">Description</span>
        <span class="row-item">Actions</span>
      </div>
      <draggable
        handle=".draggable-icon"
        v-if="groups.length > 0"
        class="tags"
        v-model="groups"
        :disabled="!$can('change', template)"
      >
        <checklist-group
          class="sortable"
          v-for="(group, i) in groups"
          :template="template"
          :group="group"
          :key="group.id"
          :can-move-up="i !== 0"
          :can-move-down="i < groups.length - 1"
          @move-up="moveGroupUp"
          @move-down="moveGroupDown"
          @delete="deleteGroup"
        />
      </draggable>
    </div>
    <div class="empty-state" v-else>
      <img alt="" class="empty-image" src="/static/images/empty-states/empty-tags.png" />
      <p class="text-med serif">You haven't added any tags!</p>
      <p>Tags like "introduction" and "sign-off" help guide users' writing.</p>
    </div>
    <checklist-group-modal-create
      v-if="showCreateTagGroupModal"
      :template="template"
      @close="
        showCreateTagGroupModal = false;
        $refs.createModal.focus();
      "
      @created-tag="showCreateTagGroupModal = false"
    ></checklist-group-modal-create>
    <checklist-item-modal-create
      v-if="showCreateTagModal"
      :template="template"
      @close="
        showCreateTagModal = false;
        $refs.createModal.focus();
      "
      @created-tag="showCreateTagModal = false"
    ></checklist-item-modal-create>
    <template-checklist-modal
      v-if="showOnboardingModal"
      :template="template"
      @close="closeOnboarding"
    ></template-checklist-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { clone, isEqual } from "lodash";
import Draggable from "vuedraggable";

import ChecklistItemModalCreate from "./ChecklistItemModalCreate.vue";
import ChecklistGroupModalCreate from "./ChecklistGroupModalCreate.vue";
import ChecklistGroup from "./ChecklistGroup.vue";
import ChecklistItem from "./ChecklistItem.vue";
import OnboardingButton from "../components/OnboardingButton.vue";
import TemplateChecklistModal from "shared/components/template/TemplateChecklistModal.vue";

export default {
  name: "TemplateChecklist",
  components: {
    ChecklistItemModalCreate,
    ChecklistGroupModalCreate,
    ChecklistGroup,
    ChecklistItem,
    Draggable,
    OnboardingButton,
    TemplateChecklistModal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCreateTagModal: false,
      showCreateTagGroupModal: false,
      showOnboardingModal: false,
    };
  },
  computed: {
    ...mapState("template", {
      allChecklistGroups: "allChecklistGroups",
      checklistGroups: "checklistGroups",
      allChecklistItems: "allChecklistItems",
      checklistItems: "checklistItems",
    }),

    groups: {
      get() {
        return this.checklistGroups;
      },
      async set(value) {
        if (!isEqual(value, this.groups)) {
          this.setChecklistGroups(value); // optimistic update
          const oldAllChecklistGroups = clone(this.allChecklistGroups); // backup for restoration
          try {
            await this.reorderTemplateChecklistGroups({ groups: value });
          } catch (error) {
            this.showSnackbar(error.message);
            this.setAllChecklistGroups(oldAllChecklistGroups);
          }
        }
      },
    },

    items: {
      get() {
        return this.checklistItems.filter((ci) => !ci.checklist_group_id);
      },
      async set(value) {
        if (!isEqual(value, this.items)) {
          this.setChecklistItems(value); // optimistic update
          const oldAllChecklistItems = clone(this.allChecklistItems); // backup for restoration
          try {
            await this.reorderTemplateChecklistItems({ items: value });
          } catch (error) {
            this.showSnackbar(error.message);
            this.setAllChecklistItems(oldAllChecklistItems);
          }
        }
      },
    },

    checklistName() {
      return this.template?.features?.checklist_name ?? "Checklist";
    },
  },

  methods: {
    ...mapActions(["showSnackbar"]),
    ...mapActions("template", {
      // checklist groups
      setChecklistGroups: "setChecklistGroups",
      setAllChecklistGroups: "setAllChecklistGroups",
      removeTemplateChecklistGroup: "removeTemplateChecklistGroup",
      reorderTemplateChecklistGroups: "reorderTemplateChecklistGroups",
      getAllTemplateChecklistGroups: "getAllTemplateChecklistGroups",

      // checklist items
      setChecklistItems: "setChecklistItems",
      setAllChecklistItems: "setAllChecklistItems",
      removeTemplateChecklistItem: "removeTemplateChecklistItem",
      reorderTemplateChecklistItems: "reorderTemplateChecklistItems",
      getAllTemplateChecklistItems: "getAllTemplateChecklistItems",
    }),

    async deleteItem(item) {
      const currentChecklistItems = this.items;
      if (currentChecklistItems.length <= 1) {
        this.showSnackbar("❌ Cannot delete the last checklist item.");
      } else {
        await this.removeTemplateChecklistItem({ item });
      }
      this.getAllTemplateChecklistItems();
    },

    async deleteGroup(group) {
      const currentChecklistGroups = this.groups;
      if (currentChecklistGroups.length <= 1) {
        this.showSnackbar("❌ Cannot delete the last checklist group.");
      } else {
        await this.removeTemplateChecklistGroup({ group });
      }
      this.getAllTemplateChecklistGroups();
    },

    moveItemUp(item) {
      this.moveItemDir({ item, up: true });
    },

    moveItemDown(item) {
      this.moveItemDir({ item, down: true });
    },

    moveItemDir({ item, up = false, down = false }) {
      let checklistItems = clone(this.items);
      let itemIndex = checklistItems.indexOf(item);
      let otherIndex = itemIndex + 1;
      if (up || !down) {
        otherIndex = itemIndex - 1;
      }
      checklistItems[itemIndex] = checklistItems[otherIndex];
      checklistItems[otherIndex] = item;

      this.items = checklistItems;
    },

    moveGroupUp(group) {
      this.moveGroupDir({ group, up: true });
    },

    moveGroupDown(group) {
      this.moveGroupDir({ group, down: true });
    },

    moveGroupDir({ group, up = false, down = false }) {
      let checklistGroups = clone(this.groups);
      let groupIndex = checklistGroups.indexOf(group);
      let otherIndex = groupIndex + 1;
      if (up || !down) {
        otherIndex = groupIndex - 1;
      }
      checklistGroups[groupIndex] = checklistGroups[otherIndex];
      checklistGroups[otherIndex] = group;

      this.groups = checklistGroups;
    },

    closeOnboarding() {
      this.showOnboardingModal = false;
      this.$refs.onboardingButton.$el.focus();
    },
  },
};
</script>
