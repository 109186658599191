<template>
  <div class="template-type" :class="docTypeClass">
    {{ titleCase(documentType) }}
  </div>
</template>

<script>
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    documentType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const titleCase = (str) =>
      str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    const docTypeClass = computed(() => `${props.documentType}-type`);

    return {
      docTypeClass,
      titleCase,
    };
  },
});
</script>
