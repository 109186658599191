<template>
  <tooltip @close="$emit('close')">
    <template #header>
      <div>How are Tags Helpful?</div>
    </template>
    <template #body>
      <div>
        The template owner has added tags to help you:
        <br /><br />
        <li class="no-list-styling">Think at a deeper level about your own writing</li>
        <li class="no-list-styling">Make sure your draft includes everything it needs</li>
        <li class="no-list-styling">
          Mark up your writing so graders and peer reviewers know a bit about what you're thinking
        </li>
      </div>
    </template>
  </tooltip>
</template>

<script>
import Tooltip from "shared/components/Tooltip.vue";

export default {
  name: "DraftChecklistTooltip",
  components: { Tooltip },
};
</script>
