<template>
  <li class="collection-card name">
    <router-link
      :to="{ name: 'Collection', params: { collectionId: collection.id } }"
      :aria-label="collection.name"
    >
      <div class="collection-content-container">
        <div class="collection-content">
          <img class="folder-icon" src="/static/images/icons/folder-icon.svg" alt="" />
          <span class="name">
            {{ collection.name }}
          </span>
          <div class="role-type-wrapper">
            <Can I="change" :this="collection">
              <button
                class="button-icon edit-icon"
                @click.prevent="showEditModal = true"
                :aria-label="`Edit ${collection.name}`"
                aria-haspopup="true"
                ref="editCollectionTrigger"
              >
              </button>
            </Can>
            <role-tag v-for="role in collection.roles" :key="role" :role-name="role" />
          </div>
        </div>
        <div class="collection-card-footer">
          <span class="template-count">
            {{ templateCount }} Template{{ templateCount == 1 ? "" : "s" }}
          </span>
          <div class="collection-code">Collection Code: {{ collection.code }}</div>
        </div>
      </div>
    </router-link>
    <edit-collection-modal
      v-if="showEditModal"
      :collection="collection"
      @close="hideAndRefocus"
      @updated-collection="showEditModal = false"
    />
  </li>
</template>

<script>
import RoleTag from "shared/components/dashboard/RoleTag.vue";
import EditCollectionModal from "./EditCollectionModal.vue";

export default {
  components: {
    RoleTag,
    EditCollectionModal,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEditModal: false,
    };
  },
  computed: {
    templateCount() {
      let collection = this.collection;
      return collection?.templates?.length || 0;
    },
  },
  methods: {
    hideAndRefocus() {
      this.showEditModal = false;
      this.$refs.editCollectionTrigger.focus();
    },
  },
};
</script>
