import { AxiosResponse } from "axios";

interface CanvasCourse {
  id: number;
  name: string;
  token_id: string;
}

interface CanvasAssociationSubmissionData {
  token: string;
  course_id: number;
  name: string;
}

interface CanvasAssociationService {
  addCanvasAssociation: (
    assetId: number,
    data: CanvasAssociationSubmissionData
  ) => Promise<AxiosResponse>;
  destroyCanvasAssociation: (assetId: number, associationId: number) => Promise<AxiosResponse>;
}

export async function addCanvasAssociation(
  assetId: number,
  course: CanvasCourse,
  service: CanvasAssociationService
): Promise<CanvasAssociationSubmissionData | null> {
  const submissionData = {
    token: course.token_id,
    course_id: course.id,
    name: course.name,
  };

  const response = await service.addCanvasAssociation(assetId, submissionData);

  if (response.status == 201) {
    return response.data;
  } else {
    return null;
  }
}

export async function removeCanvasAssociation(
  assetId: number,
  associationId: number,
  service: CanvasAssociationService
): Promise<boolean> {
  if (associationId === null) {
    return false;
  }

  const response = await service.destroyCanvasAssociation(assetId, associationId);
  return response.status === 204;
}
