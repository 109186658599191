<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 id="dialog_label" class="text-med">Add a New Folder</h2>
      <div class="modal-form-field">
        <label for="create-a-new-tag-modal-title" class="form-label bold">Title</label>
        <input class="input" id="create-a-new-tag-modal-title" type="text" v-model="title" />
      </div>
      <div class="modal-form-field">
        <label for="create-a-new-tag-modal-description" class="form-label bold">Description</label>
        <textarea
          class="input"
          id="create-a-new-tag-modal-description"
          v-model="description"
        ></textarea>
      </div>
      <div class="modal-form-field">
        <label for="existing-tag-field" class="form-label bold">Add existing tag(s)</label>
        <p class="description-text">
          Optional
        </p>
        <fieldset class="unstyled-fieldset">
          <div class="tags-list">
            <label
              v-for="tag in remainingTags"
              :key="tag.id"
            >
              <input
                type="checkbox"
                :value="tag.id"
                v-model="includeTags"
              />
              {{ tag.title }}
            </label>
          </div>
        </fieldset>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="handleCreateTagGroup" :disabled="!allowedToCreate">
        Create Folder
      </button>
    </template>
  </ls-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      title: "",
      description: "",
      required: false,
      includeTags: [],
    };
  },
  computed: {
    ...mapState("template", {
      allChecklistItems: "allChecklistItems",
    }),
    allowedToCreate() {
      let title = this.title;
      let saving = this.saving;

      return title.trim() != "" && !saving;
    },
    tags() {
      return this.allChecklistItems.filter((ci) => ci.template_id == this.template.id);
    },
    remainingTags() {
      return this.tags.filter((ci) => ci.is_default);
    },
  },
  methods: {
    ...mapActions("template", { createTemplateChecklistGroup: "createTemplateChecklistGroup" }),

    async handleCreateTagGroup() {
      this.saving = true;
      const newTagGroup = {
        title: this.title,
        template_id: this.template.id,
        items: this.includeTags
      };
      await this.createTemplateChecklistGroup({ group: newTagGroup });
      this.$emit("created-tag");
    },
  },
};
</script>
