<template>
  <div>
    <span class="row-item tag-title">
      <span class="tag-title-text"> {{ item.title }}</span>
      <span v-if="item.required" class="required"> * </span>
    </span>
    <span class="row-item">{{ item.description || "" }}</span>
    <span class="row-item table-actions">
      <button
        class="button-icon up-icon"
        @click="moveUp"
        aria-label="Move Up"
        :disabled="!canMoveUp"
      >
      </button>
      <button
        class="button-icon down-icon"
        @click="moveDown"
        aria-label="Move Down"
        :disabled="!canMoveDown"
      >
      </button>
      <button class="button-link edit" @click.prevent="showEditModal = true">Edit</button>
      <button class="button-link delete" @click="openDeleteItemModal">Delete</button>
    </span>
    <checklist-item-modal-edit
      v-if="showEditModal"
      :tag="item"
      @close="showEditModal = false"
      @updated-tag="showEditModal = false"
    ></checklist-item-modal-edit>
    <confirmation-dialog
      v-if="showConfirmDelete"
      accept-label="Delete"
      :danger-mode="true"
      @cancel="showConfirmDelete = false"
      @accept="deleteItem"
    >
      <template #message>
        <h2 class="text-med">
          Are you sure you want to delete tag "{{ item.title }}" ?
        </h2>
      </template>
    </confirmation-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ChecklistItemModalEdit from "./ChecklistItemModalEdit.vue";
import ConfirmationDialog from "shared/components/dashboard/ConfirmationDialog.vue";

export default {
  components: {
    ChecklistItemModalEdit,
    ConfirmationDialog,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    canMoveUp: {
      type: Boolean,
      default: false,
    },
    canMoveDown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEditModal: false,
      showConfirmDelete: false,
    };
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    moveUp() {
      this.$emit("move-up", this.item);
    },
    moveDown() {
      this.$emit("move-down", this.item);
    },
    deleteItem() {
      this.$emit("delete", this.item);
      this.showConfirmDelete = false;
    },
    openDeleteItemModal() {
      this.itemToDeleteId = this.item.id;
      this.showConfirmDelete = true;
    },
  },
};
</script>
