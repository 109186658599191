<template>
  <modal :visible="visible" @close="close">
    <template #content>
      <div>
        <h3 class="heading">Enter a name to leave feedback</h3>
      </div>
    </template>
    <template #footer>
      <div class="flex vc sb">
        <p v-if="errorMessage">
          {{ errorMessage }}
        </p>
        <input class="input margin-right" placeholder="Enter name here..." v-model="userName" />
        <button class="button full primary fill" :disabled="$v.userName.$error" @click="save">
          Save
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "shared/components/Modal";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Modal,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      errorMessage: null,
      userName: "",
    };
  },

  methods: {
    close() {
      this.userName = "";
      this.$emit("close");
    },

    save() {
      this.$emit("save", this.userName);
      this.close();
    },
  },

  validations: {
    userName: {
      required,
    },
  },
};
</script>
