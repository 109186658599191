<template>
  <ls-modal @close="$emit('close')" :visible="true" :ex-large="true">
    <template #content>
      <slot name="message">
        <h2 id="dialog_label" class="text-med">{{ message }}</h2>
      </slot>
      <div class="submission-details modal-form-field">
        <slot name="date"> </slot>
        <template v-if="hasValidUsernameSlot"> | </template>
        <slot name="username"> </slot>
      </div>
      <div class="modal-form-field">
        <div class="bold submission-modal-label">{{ labelForTitle }}</div>
        <slot name="subject">
          <div id="dialog_label" class="input submission-full-content">{{ subject }}</div>
        </slot>
      </div>
      <div class="modal-form-field">
        <div class="bold submission-modal-label">{{ labelForContent }}</div>
        <slot name="submission">
          <div id="dialog_label" class="input submission-full-content">{{ submission }}</div>
        </slot>
      </div>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    message: {
      type: String,
      default: "Set the 'message' property or use the 'message' slot to display content here",
    },
    subject: {
      type: String,
      default: "Set the 'message' property or use the 'message' slot to display content here",
    },
    submission: {
      type: String,
      default: "Set the 'submission' property or use the 'submission' slot to display content here",
    },
    labelForTitle: {
      type: String,
      default: "Title",
    },
    labelForContent: {
      type: String,
      default: "Content",
    },
  },
  computed: {
    hasValidUsernameSlot() {
      return this.$slots.username && this.$slots.username[0].text.trim();
    },
  },
};
</script>
