<template>
  <div ref="exampleDetailModalTrigger" tabindex="-1" @click="viewFullExample()" class="table-row clickable sortable">
    <span class="row-item smaller">
      <button
        class="button-icon draggable-icon"
        aria-label="Draggable Icon"
        aria-hidden="true"
        tabindex="-1"
      >
      </button>
      {{ example.subject }}
    </span>
    <div class="row-item content" v-html="truncatedExample" />
    <div class="row-item smaller">
      {{ example.tags.length }}
    </div>
    <div class="row-item smaller table-actions stacked">
      <button
        ref="unapproveButton"
        class="button-link edit"
        v-if="example.is_approved"
        @click.prevent.stop="unapprove"
      >
        Unapprove
      </button>
      <button ref="approveButton" class="button-link edit" v-else @click.prevent.stop="approve">
        Approve
      </button>
      <button class="button-link edit" @click.prevent.stop="update">Edit</button>
      <button
        class="button-link delete"
        @click.prevent.stop="deleteExample"
        aria-haspopup="dialog"
      >
        Delete
      </button>
    </div>
  </div>
</template>

<script>
import { truncateHTML } from "shared/utils";

export default {
  name: "ExampleAdminRow",

  props: {
    example: {
      type: Object,
      required: true,
    },
  },

  computed: {
    truncatedExample() {
      return `${truncateHTML(this.example.content)}...`;
    },
  },

  methods: {
    longExample(content) {
      return content.length > 100;
    },
    approve() {
      this.$emit("approve");
      // this.$refs.unapproveButton.focus();
    },
    unapprove() {
      this.$emit("unapprove");
      // this.$refs.approveButton.focus();
    },
    update() {
      this.$emit("update");
    },
    deleteExample() {
      this.$emit("delete");
    },
    moveUp() {
      this.$emit("move-up", this.item);
    },
    moveDown() {
      this.$emit("move-down", this.item);
    },
    viewFullExample() {
      this.$emit("view-full-example", { ...this.example });
    },
  },
};
</script>
