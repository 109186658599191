<template>
  <router-link custom :to="to" v-slot="{ href, navigate, isActive }">
    <li class="tab no-list-styling" :class="{ active: isActive }">
      <a :href="href" @click="navigate" :aria-current="isCurrent">
        <slot></slot>
      </a>
    </li>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isCurrent() {
      const destination = this.$router.resolve(this.to);
      if (this.$route.fullPath === destination.resolved.fullPath) {
        return "page";
      }
      return false;
    }
  }
};
</script>
