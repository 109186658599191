<template>
  <ls-modal @close="$emit('close')" :visible="true" :large="true" class="onboarding-modal">
    <template #content>
      <h2 id="dialog_label" class="text-med">Examples</h2>
      <div class="onboarding-section">
        <h3 class="onboarding-title bold">Description</h3>
        <p>In the Examples tab, you are able to browse examples the owner of this template has added. These will help you:</p>
        <ul class="no-list-styling">
          <li>See examples of similar writing to inspire your own</li>
          <li>See how others tagged their drafts </li>
        </ul>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="$emit('close')">Got It</button>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
};
</script>
