<template>
  <div class="user-menu" ref="menuEl">
    <button
      id="user-menu-button"
      class="button-transparent"
      aria-haspopup="true"
      aria-controls="user-menu"
      :aria-expanded="isOpen"
      @click="toggleMenu"
    >
      <span class="user-name text-smed">
        <img class="user-picture" alt="" :src="userImgSrc" />
        <span>{{ userName }} <i class="fas fa-caret-down hover-rotate"> </i></span>
      </span>
    </button>

    <ul
      id="user-menu"
      role="menu"
      aria-labelledby="user-menu-button"
      class="dropdown right slim"
      @keyup.down="focusNextMenuItem"
      @keyup.up="focusPreviousMenuItem"
      @keyup.esc="closeMenu(true)"
      @keydown.tab="closeMenu"
      :hidden="!isOpen"
    >
      <li class="text-small" role="none">
        <router-link :to="{ name: 'Account' }" custom v-slot="{ href }">
          <a :href="href" role="menuitem" tabindex="-1" @click.prevent="navigateToAccount">
            Account
          </a>
        </router-link>
      </li>
      <li class="text-small" role="none">
        <router-link :to="{ name: 'Dashboard' }" custom v-slot="{ href }">
          <a :href="href" role="menuitem" tabindex="-1" @click.prevent="navigateToDashboard">
            Dashboard
          </a>
        </router-link>
      </li>
      <li class="text-small" role="none">
        <router-link v-if=isAdmin :to="{ name: 'Admin' }" custom v-slot="{ href }">
          <a :href="href" role="menuitem" tabindex="-1" @click.prevent="navigateToAdmin">
            Site Administration
          </a>
        </router-link>
      </li>
      <li class="text-small" role="none">
        <button role="menuitem" tabindex="-1" @click="logout">Logout</button>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import {
  ref,
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  watch,
} from "@vue/composition-api";
import { useStore } from "shared/composables";
import { useRouter } from "shared/composables";
import TrackingService from "services/tracking";
import AuthService from "services/auth";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    const userName = computed(() => {
      const name = store.state.user.name;
      const firstName = name.indexOf(" ") !== -1 ? name.substr(0, name.indexOf(" ")) : name;
      return firstName || store.state.user.email;
    });
    const userImgSrc = computed(() => {
      return store.state.user.picture || store.state.defaultUserImgSrc;
    });
    const isAdmin = computed(() => {
      return store.state.user.is_admin;
    });

    const menuEl = ref(null);
    const getMenuItems = () => menuEl.value.querySelectorAll("[role=menuitem]");
    const focusedMenuItem = ref(0);
    const focusMenuItem = (itemNumber) => {
      if (itemNumber) {
        const menuItems = getMenuItems();
        menuItems[itemNumber - 1].focus({ focusVisible: true });
      } else {
        menuEl.value.querySelector("#user-menu-button").focus();
      }
    };

    watch(focusedMenuItem, (newMenuItem) => {
      focusMenuItem(newMenuItem);
    });

    const isOpen = ref(false);
    const openMenu = () => {
      isOpen.value = true;
      Vue.nextTick(() => (focusedMenuItem.value = 1));
    };
    const closeMenu = (keepFocus = false) => {
      isOpen.value = false;
      if (keepFocus) {
        focusedMenuItem.value = 0;
      }
    };
    const toggleMenu = () => {
      if (isOpen.value) {
        closeMenu();
      } else {
        openMenu();
      }
    };

    const handleOutsideClick = (e) => {
      const el = menuEl.value;
      const target = e.target;
      if (el !== target && !el.contains(target)) {
        closeMenu();
      }
    };

    const focusNextMenuItem = () => {
      const menuItems = getMenuItems();
      if (focusedMenuItem.value === menuItems.length) {
        focusedMenuItem.value = 1;
      } else {
        focusedMenuItem.value += 1;
      }
    };

    const focusPreviousMenuItem = () => {
      const menuItems = getMenuItems();
      if (focusedMenuItem.value === 1) {
        focusedMenuItem.value = menuItems.length;
      } else {
        focusedMenuItem.value -= 1;
      }
    };

    onMounted(() => {
      document.addEventListener("click", handleOutsideClick);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleOutsideClick);
    });

    const navigateToAccount = () => {
      const currentRoute = router.resolve(window.location);
      const sameRoute = currentRoute.route.name === "Account";
      closeMenu(sameRoute);
      if (!sameRoute) {
        router.push({ name: "Account" });
      }
    };

    const navigateToDashboard = () => {
      const currentRoute = router.resolve(window.location);
      const sameRoute = currentRoute.route.name === "Dashboard";
      closeMenu(sameRoute);
      if (!sameRoute) {
        router.push({ name: "Dashboard" });
      }
    };

    const navigateToAdmin = () => {
      const currentRoute = router.resolve(window.location);
      const sameRoute = currentRoute.route.name === "AdminHome";
      closeMenu(sameRoute);
      if (!sameRoute) {
        router.push({ name: "AdminHome" });
      }
    };

    const logout = () => {
      TrackingService.store({
        name: "UserLoggedOut",
        note: `From ${window.location}`,
        user_id: store.state.user.id,
      });
      AuthService.logout().then((response) => {
        const result = response.data;
        if (result.success) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      });
    };

    return {
      menuEl,
      userName,
      userImgSrc,
      isOpen,
      isAdmin,
      toggleMenu,
      closeMenu,
      focusNextMenuItem,
      focusPreviousMenuItem,
      navigateToAccount,
      navigateToDashboard,
      navigateToAdmin,
      logout,
    };
  },
});
</script>
