<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["toggleNav"]),

    closeSidebarPanel() {
      this.toggleNav();
    },
  },

  computed: {
    ...mapState(["isNavOpen"]),

    isPanelOpen() {
      return this.isNavOpen;
    },
  },
};
</script>
<style>
.sidebar {
  z-index: 5;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-backdrop,
.sidebar-panel {
  position: fixed;
  height: 100vh;
  top: 0;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  left: 0;
  cursor: pointer;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: #142b66;
  z-index: 999;
  padding: 70px 0 1rem 0;
}
</style>
