import axios from "axios";
import AuthService from "./auth";

const apiRoute = "/api/email-feedback/";

export default {
  store(data, isGuest = false) {
    const emailFeedbackAxios = AuthService.createCustomHeaderAxios(isGuest, true);
    return emailFeedbackAxios.post(apiRoute, data);
  },

  show(emailSlug, isGuest = false) {
    const emailFeedbackAxios = AuthService.createCustomHeaderAxios(isGuest, true);
    return emailFeedbackAxios.get(apiRoute + emailSlug + "/");
  },

  setResolution(feedbackId, isResolved) {
    return axios.put(
      `${apiRoute}resolution/${feedbackId}/`, {"is_resolved": isResolved}
    );
  },
};
