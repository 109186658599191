import Vue from "vue";
import Vuex from "vuex";

import DraftService from "../services/drafts";

import { store as admin } from "../modules/admin/store";
import { store as drafting } from "../modules/drafting";
import { store as template } from "../modules/template";
import { store as collection } from "../modules/collection";

export const StoreSymbol = Symbol();

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    admin,
    drafting,
    template,
    collection,
  },
  state: {
    alert: "",
    snackbar: {
      enabled: false,
      message: "",
    },
    loader: {
      enabled: false,
      message: "",
    },
    loggedIn: false,
    token: null,
    refreshToken: null,
    user: null,
    isGuest: false,
    isNavOpen: false,
    routeAfterLogin: null,
    tagPosition: 0,
    defaultUserImgSrc: "/static/images/default-user-image.png",
  },

  mutations: {
    SET_ALERT(state, alert) {
      state.alert = alert;
    },

    SET_SNACKBAR_MESSAGE(state, message) {
      state.snackbar.message = message;
    },

    SET_SNACKBAR_ENABLED(state, enabled) {
      state.snackbar.enabled = enabled;
    },

    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("id_token", JSON.stringify(state.token));
    },

    SET_REFRESH_TOKEN(state, refreshToken) {
      state.refreshToken = refreshToken;
      localStorage.setItem("id_refresh_token", JSON.stringify(state.refreshToken));
    },

    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(state.user));
    },

    SET_USER_PICTURE(state, newPicture) {
      Vue.set(state.user, "picture", newPicture);
      localStorage.setItem("user", JSON.stringify(state.user));
    },

    SET_USER_NAME(state, newName) {
      Vue.set(state.user, "name", newName);
      localStorage.setItem("user", JSON.stringify(state.user));
    },

    SET_LOGGED_IN(state, loggedIn) {
      state.loggedIn = loggedIn;
      localStorage.setItem("loggedIn", JSON.stringify(state.loggedIn));
    },

    SET_LOADER_ENABLED(state, enabled) {
      state.loader.enabled = enabled;
    },

    SET_LOADER_MESSAGE(state, message) {
      state.loader.message = message;
    },

    SET_GUEST(state, status) {
      localStorage.setItem("isGuest", status);
      state.isGuest = status;
    },

    SET_NAV(state, isNavOpen) {
      state.isNavOpen = isNavOpen;
    },

    SET_ROUTE_AFTER_LOGIN(state, route) {
      state.routeAfterLogin = route;
    },

    SET_TAG_POSITION(state, position) {
      state.tagPosition = position;
    },
  },

  actions: {
    toggleNav({ state, commit }) {
      commit("SET_NAV", !state.isNavOpen);
    },

    init(store) {
      let loggedIn = localStorage.getItem("loggedIn"),
        token = localStorage.getItem("id_token"),
        refreshToken = localStorage.getItem("id_refresh_token"),
        user = localStorage.getItem("user");

      store.commit(
        "SET_LOGGED_IN",
        loggedIn && loggedIn != "undefined" ? JSON.parse(loggedIn) : null
      );
      store.commit("SET_TOKEN", token && token != "undefined" ? JSON.parse(token) : null);
      store.commit(
        "SET_REFRESH_TOKEN",
        refreshToken && refreshToken != "undefined" ? JSON.parse(refreshToken) : null
      );
      store.commit("SET_USER", user && user != "undefined" ? JSON.parse(user) : null);
    },

    enableGreyHeader(store) {
      store.commit("ENABLE_GREY_HEADER");
    },

    hideLoader(store) {
      store.commit("SET_LOADER_MESSAGE", "");
      store.commit("SET_LOADER_ENABLED", false);
    },

    showLoader(store, message) {
      store.commit("SET_LOADER_MESSAGE", message);
      store.commit("SET_LOADER_ENABLED", true);
    },

    updateLoaderMessage(store, message) {
      store.commit("SET_LOADER_MESSAGE", message);
    },

    login(store, data) {
      store.commit("SET_LOGGED_IN", true);
      store.commit(`drafting/SET_DRAFT`, null);
      store.commit(`drafting/SET_EMAIL`, null);
      store.commit("SET_USER", data.user);
      store.commit("SET_TOKEN", data.token);
      store.commit("SET_REFRESH_TOKEN", data.refresh);
    },

    guestLogin(store, data) {
      store.commit("SET_LOGGED_IN", false);
      store.commit("SET_USER", data.user);
      store.commit("SET_TOKEN", data.token);
      store.commit("SET_REFRESH_TOKEN", data.refresh);
    },

    logout(store) {
      store.commit(`drafting/SET_DRAFT`, null);
      store.commit(`drafting/SET_EMAIL`, null);
      store.commit(`template/SET_ALL_TEMPLATES`, []);
      store.commit(`template/SET_CHECKLIST_ITEMS`, []);
      store.commit(`template/SET_CHECKLIST_GROUPS`, []);
      store.commit("SET_LOGGED_IN", false);
      store.commit("SET_USER", null);
      store.commit("SET_TOKEN", null);
      store.commit("SET_REFRESH_TOKEN", null);
      localStorage.clear();
    },

    hideSteps(store) {
      store.commit("SET_SHOW_STEPS", false);
    },

    showSteps(store) {
      store.commit("SET_SHOW_STEPS", true);
    },

    updateAlert(store, alert) {
      store.commit("SET_ALERT", alert);
    },

    showSnackbar(store, message) {
      store.commit("SET_SNACKBAR_ENABLED", true);
      store.commit("SET_SNACKBAR_MESSAGE", message);
    },

    hideSnackbar(store) {
      store.commit("SET_SNACKBAR_ENABLED", false);
      store.commit("SET_SNACKBAR_MESSAGE", "");
    },

    updateStep(store, step) {
      store.commit("SET_CURRENT_STEP", step);
    },

    updateToken(store, token) {
      store.commit("SET_TOKEN", token);
    },

    updateUser(store, user) {
      store.commit("SET_USER", user);
    },

    updateUserPicture(store, newPicture) {
      store.commit("SET_USER_PICTURE", newPicture);
    },

    updateUserName(store, newName) {
      store.commit("SET_USER_NAME", newName);
    },

    setGuest(store, status) {
      store.commit("SET_GUEST", status);
    },

    setRouteAfterLogin(store, route) {
      store.commit("SET_ROUTE_AFTER_LOGIN", route);
    },

    async createNewDraft({ dispatch }, { template }) {
      await dispatch("template/setCurrentTemplate", template);
      const { data: draft } = await DraftService.create({ template_id: template.id });
      dispatch("drafting/setCurrentDraft", draft);
      await dispatch("drafting/initEmail");
      return draft;
    },
  },

  getters: {},
});
