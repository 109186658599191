<template>
  <div class="drafts" v-if="drafts.length > 0">
    <p class="visually-hidden">
      Navigate the following tabs by using the up and down arrows. Press the d key to jump to the
      full view of a draft after you select it. Press the t key to return to the tab list.
    </p>
    <ul role="tablist" arial-label="Drafts" class="draft-items no-list-styling">
      <li
        v-for="(draft, index) in drafts"
        :key="draft.id"
        role="tab"
        :aria-selected="selectedDraft ? draft.id === selectedDraft.id : false"
        :id="`draft-${draft.id}`"
        tabindex="0"
        class="draft-item"
        :class="{ selected: selectedDraft == draft }"
        ref="draftTabs"
        @click="
          selectDraft(draft);
          $refs.draftTabs[index].focus();
        "
        @focus="selectDraft(draft)"
        @keyup.space="selectDraft(draft)"
        @keyup.enter="selectDraft(draft)"
        @keyup.up="handleKeyUp(index)"
        @keyup.down="handleKeyDown(index)"
      >
        <div class="draft-header">
          <div class="draft-subject bold">{{ draft.subject }}</div>
          <span v-if="draft.source_id" class="saved-example">Saved Example</span>
        </div>
        <p class="content" v-html="draft.content"></p>
      </li>
    </ul>

    <draft-content-view
      role="tabpanel"
      :aria-labelledby="selectedDraft ? `draft-${selectedDraft.id}` : ''"
      :draft="selectedDraft"
      :allow-edit="isMyDraft"
      :allow-remove="isMyDraft"
      @remove="handleRemove"
      @edit="handleEdit"
    />

    <confirmation-dialog
      v-if="showConfirmDelete"
      accept-label="Delete"
      :danger-mode="true"
      @cancel="showConfirmDelete = false"
      @accept="completeDraftDelete(selectedDraft)"
    >
      <template #message>
        <h2 id="dialog_label" class="text-med">Are you sure you want to delete this draft?</h2>
      </template>
    </confirmation-dialog>
  </div>
  <div class="empty-state" v-else>
    <img alt="" class="empty-image" src="/static/images/empty-states/empty-drafts.png" />
    <p class="text-med serif">You haven't composed any drafts!</p>
    <p>Once drafts are added, they will populate here.</p>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DraftContentView from "shared/components/draft/DraftContentView.vue";
import ConfirmationDialog from "shared/components/dashboard/ConfirmationDialog.vue";

export default {
  components: {
    ConfirmationDialog,
    DraftContentView,
  },
  props: {
    drafts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedDraft: null,
      showConfirmDelete: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    isMyDraft() {
      let draft = this.selectedDraft;
      let user = this.user;

      if (!draft) {
        return null;
      }

      return draft.user_id === user.id;
    },
  },
  methods: {
    ...mapActions("drafting", { setCurrentDraft: "setCurrentEmail" }),
    selectDraft(draft) {
      this.selectedDraft = draft;
      this.setCurrentDraft(draft);
    },
    handleRemove() {
      this.showConfirmDelete = true;
    },
    completeDraftDelete() {
      this.showConfirmDelete = false;
      let selectedDraft = this.selectedDraft;
      this.selectedDraft = null;
      this.$emit("delete", selectedDraft);
    },
    handleEdit() {
      this.$emit("edit", this.selectedDraft);
    },
    handleKeyUp(index) {
      if (!index) {
        let lastTabIndex = this.$refs.draftTabs.length - 1;
        this.$refs.draftTabs[lastTabIndex].click();
      } else {
        this.$refs.draftTabs[index - 1].click();
      }
    },
    handleKeyDown(index) {
      let lastTabIndex = this.$refs.draftTabs.length - 1;
      if (index == lastTabIndex) {
        this.$refs.draftTabs[0].click();
      } else {
        this.$refs.draftTabs[index + 1].click();
      }
    },
    focusSelectedDraft() {
      const selectedDraftAnchor = document.getElementById("selected_draft");
      selectedDraftAnchor?.focus();
    },
    focusFirstTab() {
      this.$refs.draftTabs[0]?.focus();
    },
  },
  mounted() {
    let _keyListener = function (e) {
      if (e.key === "d") {
        this.focusSelectedDraft();
      } else if (e.key === "t") {
        this.focusFirstTab();
      }
    };
    this._listener = _keyListener.bind(this);
    document.addEventListener("keydown", this._listener);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this._listener);
  },
};
</script>
