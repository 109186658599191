import store from "~/store";

import SubmissionDetail from "./views/SubmissionDetail.vue";

const SubmissionModule = () => import("./SubmissionModule.vue");

const requireAuth = (to, from, next) => {
  if (!store.state.loggedIn) {
    next({
      name: "Login",
      query: { next: to.fullPath },
    });
  } else if (
    store.state.loggedIn &&
    store.state.user.templates &&
    store.state.user.templates.length === 0
  ) {
    next({ name: "Dashboard" });
  } else {
    next();
  }
};

export const routes = [
  {
    path: "/submissions/:slug",
    component: SubmissionModule,
    beforeEnter: requireAuth,
    props: true,
    children: [
      {
        path: "",
        props: true,
        name: "SubmissionDetail",
        component: SubmissionDetail,
      },
    ],
  },
];
