<template>
  <ls-modal :show-close="false" :visible="true">
    <template #content>
      <slot name="message">
        <h2 id="dialog_label" class="text-med">{{ message }}</h2>
      </slot>
      <div v-if="errorMessage" class="error-text">
        {{ errorMessage }}
      </div>
      <div v-if="superConfirmMode" class="modal-form-field">
        <label for="confirmation-dialog-super-confirm-text-input" class="form-label bold"
          >Type “{{ superConfirmText }}” below to confirm</label
        >
        <input
          class="input"
          type="text"
          id="confirmation-dialog-super-confirm-text-input"
          v-model="superConfirmInputText"
        />
      </div>
    </template>
    <template #footer>
      <slot name="buttons">
        <div class="buttons">
          <button
            v-if="dangerMode"
            :disabled="superConfirmMode && !superConfirmed"
            class="button danger"
            tabindex="0"
            @click="$emit('accept')"
          >
            {{ acceptLabel }}
          </button>
          <button
            ref="cancelButton"
            v-if="cancelLabel"
            class="button-secondary cancel"
            tabindex="0"
            @click="$emit('cancel')"
          >
            {{ cancelLabel }}
          </button>
          <button
            ref="acceptButton"
            v-if="!dangerMode"
            :disabled="superConfirmMode && !superConfirmed"
            class="button accept"
            tabindex="0"
            @click="$emit('accept')"
          >
            {{ acceptLabel }}
          </button>
        </div>
      </slot>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    message: {
      type: String,
      default: "Set the 'message' property or use the 'message' slot to display content here",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    cancelLabel: {
      type: String,
      default: "Cancel",
    },
    acceptLabel: {
      type: String,
      default: "OK",
    },
    dangerMode: {
      type: Boolean,
      default: false,
    },
    superConfirmMode: {
      type: Boolean,
      default: false,
    },
    superConfirmText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      superConfirmInputText: "",
    };
  },
  computed: {
    superConfirmed() {
      return this.superConfirmInputText === this.superConfirmText;
    },
  },
  mounted() {
    let cancelButton = this.$refs.cancelButton;
    let acceptButton = this.$refs.acceptButton;

    document.addEventListener("keydown", this.escapeKeyHandler);

    if (this.dangerMode) {
      if (cancelButton) {
        cancelButton.focus();
      }
    } else {
      if (!this.superConfirmMode) {
        acceptButton.focus();
      }
    }
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.escapeKeyHandler);
  },
  methods: {
    escapeKeyHandler(event) {
      if (event.key === "Escape" || event.key === "Esc") {
        this.$emit("cancel");
      }
    },
  },
};
</script>
