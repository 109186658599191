<template>
  <div class="draft-view-header flex sb">
    <h2 class="draft-view-title">
      {{ draft.subject }} <span class="saved"> {{ draftUpdatedAt }}</span>
    </h2>
    <div class="links">
      <button class="button-link blue-text-link" :class="{ disabled: !allowEdit }" @click="edit">Edit</button>
      |
      <button class="button-link delete error-text" :class="{ disabled: !allowRemove }" @click="remove">Delete</button>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    draft: {
      type: Object,
      required: true,
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
    allowRemove: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    draftUpdatedAt() {
      return dayjs(this.draft.updated_at).format("MMM, D YYYY");
    },
  },

  methods: {
    edit() {
      if (this.allowEdit) {
        this.$emit("edit");
      }
    },
    remove() {
      if (this.allowRemove) {
        this.$emit("remove");
      }
    },
  },
};
</script>
