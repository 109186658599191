import axios from "axios";

const baseURL = "/api/collections/";

export default {
  all() {
    return axios.get(baseURL);
  },

  create(data) {
    return axios.post(baseURL, data);
  },

  show(id) {
    return axios.get(`${baseURL}${id}/`);
  },

  update(id, data) {
    return axios.put(`${baseURL}${id}/`, data);
  },

  destroy(id) {
    return axios.delete(`${baseURL}${id}/`);
  },

  join(code) {
    return axios.post(`/api/join-collection/`, { code });
  },

  addTemplate(id, template) {
    return axios.put(`${baseURL}${id}/template/`, {
      collection: id,
      template: template.id,
    });
  },

  removeTemplate(id, template) {
    return axios.delete(`${baseURL}${id}/template/`, {
      data: {
        collection: id,
        template: template.id,
      },
    });
  },

  listMembers(id, meOnly = false) {
    let url = `${baseURL}${id}/members/`;
    if (meOnly) {
      url = `${url}?self_only=true`;
    }
    return axios.get(url);
  },

  addMember(id, data) {
    return axios.post(`${baseURL}${id}/members/`, data);
  },

  updateMember(id, memberId, data) {
    return axios.put(`${baseURL}${id}/members/${memberId}/`, data);
  },

  destroyMember(id, memberId) {
    return axios.delete(`${baseURL}${id}/members/${memberId}/`);
  },

  bulkUserUpload(id, data) {
    return axios.post(`${baseURL}${id}/members/bulk-upload/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  listCanvasAssociations(id) {
    return axios.get(`${baseURL}${id}/canvas-associations/`);
  },
  addCanvasAssociation(id, data) {
    return axios.post(`${baseURL}${id}/canvas-associations/`, data);
  },
  updateCanvasAssociation(id, associationId, data) {
    return axios.put(`${baseURL}${id}/canvas-associations/${associationId}/`, data);
  },
  destroyCanvasAssociation(id, associationId) {
    return axios.delete(`${baseURL}${id}/canvas-associations/${associationId}/`);
  },
  importStudentsFromCanvasAssociation(id, associationId) {
    return axios.post(`${baseURL}${id}/canvas-associations/${associationId}/import-students/`);
  },
};
