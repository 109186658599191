<template>
  <ls-modal @close="$emit('close')" :visible="true" :large="true" class="onboarding-modal">
    <template #content>
      <h2 class="text-med">Settings</h2>
      <div class="onboarding-section">
        <h3 class="onboarding-title bold">Description</h3>
        <p>Your settings have been predetermined based on the template type you’ve chosen. Customize the settings keeping in mind the capabilities and information you would like your participant to have access to while they write their draft.</p>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="$emit('close')">Got It</button>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
};
</script>
