import LtiService from "../../../services/lti.ts";

import { SET_REGISTRATIONS } from "./mutations";

const state = () => ({
  registrations: [],
});

const getters = {
  getRegistrationById: (state) => (id) => {
    return state.registrations.find(reg => reg.id === id);
  },
  getDeploymentById: (state) => (id) => {
    const allDeployments = state.registrations.reduce(
      (prev, curr) => prev.concat(curr.deployments), []
    );
    return allDeployments.find(deployment => deployment.id === id);
  },
};

const mutations = {
  [SET_REGISTRATIONS](state, registrations) {
    state.registrations = registrations;
  },
};

const actions = {
  async getRegistrations(store) {
    const response = await LtiService.list();
    store.commit(SET_REGISTRATIONS, response.data);
    return store.state.registrations;
  },

  async createRegistration({ state, commit }, { registration }) {
    const response = await LtiService.create(registration);
    let newRistrations = [...state.registrations, response.data];
    commit(SET_REGISTRATIONS, newRistrations);
    return state.registrations.find(reg => reg.id === response.data.id);
  },

  async updateRegistration({ state, commit }, { id, registration }) {
    const response = await LtiService.update(id, registration);
    let otherRegistrations = state.registrations.filter(reg => reg.id !== id)
    let newRegistrations = [...otherRegistrations, response.data];
    commit(SET_REGISTRATIONS, newRegistrations);
    return state.registrations.find(reg => reg.id === id);
  },

  async createDeployment({ state, commit, getters }, { deployment }){
    const response = await LtiService.createDeployment(deployment);
    const newDeployment = response.data;
    let registration = { ...getters.getRegistrationById(newDeployment.registration) };
    registration.deployments = [...registration.deployments, newDeployment];
    const otherRegistrations = state.registrations.filter(
      reg => reg.id !== registration.id
    );
    const newRegistrations = [...otherRegistrations, registration];
    commit(SET_REGISTRATIONS, newRegistrations);
    return newDeployment;
  },

  async updateDeployment({ state, commit, getters}, { id, deployment }) {
    const response = await LtiService.updateDeployment(id, deployment);
    const newDeployment = response.data;
    let registration = { ...getters.getRegistrationById(newDeployment.registration) };
    registration.deployments = [
      ...registration.deployments.filter(dep => dep.id !== newDeployment.id),
      newDeployment
    ];
    const otherRegistrations = state.registrations.filter(
      reg => reg.id !== registration.id
    );
    const newRegistrations = [...otherRegistrations, registration];
    commit(SET_REGISTRATIONS, newRegistrations);
    return newDeployment;
  }
};

export const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default store;
