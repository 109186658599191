<template>
  <div class="tag-folder" v-if="!group.is_default">
    <div class="tag-accordion-header">
      <div class="tag-description-container">
        <button
          @click="toggleTagFolder"
          class="tag-folder-name button-link"
        >
          <slot>{{group.title}}</slot>
        </button>
        <button
          v-if="group.description"
          class="information-icon button-icon"
          @click="toggleDescription"
          aria-label="Toggle Folder Description"
        >
        </button>
        <transition name="fade">
          <div v-focus-trap class="tag-description" v-if="showDescription">
            <div class="tag-description-header">
              <div>Folder Description</div>
              <button
                class="remove-icon button-icon"
                @click="showDescription = false"
                aria-label="Close Folder Description"
              >
              </button>
            </div>
            <p tabindex="0">{{group.description}}</p>
          </div>
        </transition>
      </div>
      <button
        class="toggle-icon button-icon"
        :class="{ 'closed-icon': hideTags }"
        @click="toggleTagFolder"
        aria-label="Toggle Folder"
      >
      </button>
    </div>
    <ul class="no-list-styling" :class="{ closed: hideTags }" v-if="group.items.length > 0">
      <checklist-item
        class="individual-tag"
        v-for="item in group.items"
        :key="item.title"
        :item="item"
        :active="isActive(item)"
        :selected-item="selectedChecklistItem"
        @select="select"
        @deselect="deselect"
      >
      </checklist-item>
    </ul>
    <div :class="{ closed: hideTags }" class="empty-tag" v-else>No tags in this folder</div>
  </div>
  <!-- LOOSE TAGS -->
  <ul class="no-list-styling" v-else>
    <checklist-item
      class="loose-tag"
      v-for="item in group.items"
      :key="item.title"
      :item="item"
      :active="isActive(item)"
      :selected-item="selectedChecklistItem"
      @select="select"
      @deselect="deselect"
    >
    </checklist-item>
  </ul>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";
import ChecklistItem from "shared/components/checklist/ChecklistItem.vue";
import ChecklistTooltip from "./ChecklistTooltip.vue";

export default defineComponent({
  name: "ChecklistGroup",

  components: {
    ChecklistItem,
    ChecklistTooltip,
  },

  props: {
    taggedChecklistItemIds: {
      type: Array,
      required: true,
    },
    checklistName: {
      type: String,
      default: "Checklist",
    },
    group: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hideTags: false,
      showDescription: false,
    };
  },

  methods: {
    toggleTagFolder () {
      this.hideTags = !this.hideTags;
    },

    toggleDescription () {
      this.showDescription = !this.showDescription;
    },
  },

  emits: ["select", "selected-checklist-item-restored"],

  setup(props, context) {
    const showChecklistTooltip = ref(false);
    const selectedChecklistItem = ref(null);
    const showCross = ref(true);

    const select = (item) => {
      selectedChecklistItem.value = item;
      context.emit("select", item);
    };

    const deselect = () => {
      selectedChecklistItem.value = null;
      context.emit("selected-checklist-item-restored");
    };

    const isActive = (item) => {
      const result = !!props.taggedChecklistItemIds.includes(item.id);
      return result;
    };

    return {
      showChecklistTooltip,
      selectedChecklistItem,
      showCross,
      select,
      deselect,
      isActive,
    };
  },
});</script>
