import axios from "axios";

const draftsRoute = "/api/drafts/";

export default {
  list(templateId) {
    let params = {};
    if (templateId !== undefined) {
      params["template_id"] = templateId;
    }
    return axios.get(draftsRoute, { params });
  },

  get(id) {
    return axios.get(`${draftsRoute}${id}/`);
  },

  load(id) {
    return axios.get(`/api/new-drafts/${id}/`);
  },

  create(data) {
    return axios.post("/api/new-drafts/", data);
  },
};
