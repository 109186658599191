<template>
  <div :id="animationContainerId" />
</template>

<script>
import lottie from 'lottie-web'

export default {
  computed: {
    animationContainerId() {
      return `lottie-animation-${this._uid}`
    },
  },
  props: {
    jsonPath: {
      type: String,
      required: true,
    },
    lottieOptions: {
      type: Object,
      default: () => {},
    },
    speed: {
      type: Number,
      default: 1.5,
    },
  },
  methods: {
    prependPublicPath(path) {
      const publicPath = '/static'
      const basePath = publicPath.endsWith('/')
        ? publicPath.slice(0, -1)
        : publicPath
      const extPath = path.startsWith('/') ? path.slice(1) : path
      return `${basePath}/${extPath}`
    },
  },
  mounted() {
    const animation = lottie.loadAnimation({
      container: document.getElementById(this.animationContainerId),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: this.prependPublicPath(this.jsonPath),
      ...this.lottieOptions,
    })
    animation.setSpeed(this.speed)
  },
}
</script>

<style></style>
