<template>
  <ls-modal @close="$emit('close')" :visible="true" :large="true" class="onboarding-modal">
    <template #content>
      <h2 class="text-med">{{ checklistName }}</h2>
      <div class="onboarding-section">
        <h3 class="onboarding-title bold">Description</h3>
        <ul class="no-list-styling">
          <li>In the <b>{{ checklistName }}</b> tab, add a list of items you want your participants to include in their drafts.</li>
          <li>Think of this as an outline to help guide your writers as they draft.</li>
        </ul>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="$emit('close')">Got It</button>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  computed: {
    checklistName() {
      return this.template?.features?.checklist_name ?? "Checklist";
    },
  },
};
</script>
