<template>
  <ls-modal @close="$emit('close')" :visible="true" :large="true" class="onboarding-modal">
    <template #content>
      <h2 id="dialog_label" class="text-med">Submissions</h2>
      <div v-if="$can('change', template)" class="onboarding-section">
        <h3 class="onboarding-title bold">Description</h3>
        <p>In the <b>Submissions</b> tab, you can review the drafts your students have submitted.</p>
        <ul class="no-list-styling">
          <li>Click on the row to see a quick view.</li>
          <li>Leave feedback by clicking the "Peer Feedback" link.</li>
          <li>Click on the "Tagged Draft" link to see how students have tagged their writing.</li>
        </ul>
      </div>
      <div v-else class="onboarding-section">
        <h3 class="onboarding-title bold">Description</h3>
        <p>In the <b>Submissions</b> tab, you can review the drafts you have submitted. </p>
        <ul class="no-list-styling">
          <li>Click on the row to see a quick view.</li>
          <li>View feedback by clicking the "Peer Feedback" link.</li>
          <li>Click on the "Tagged Draft" link to view how you have tagged your writing.</li>
        </ul>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="$emit('close')">Got It</button>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
};
</script>
