<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 id="dialog_label" class="text-med">Edit Tag Folder</h2>
      <div class="modal-body">
        <div class="modal-form-field">
          <label for="edit-tag-modal-title" class="form-label bold">Title</label>
          <input class="input" id="edit-tag-modal-title" type="text" v-model="title" />
        </div>
        <div class="modal-form-field">
          <label for="edit-tag-modal-description" class="form-label bold">Description</label>
          <textarea class="input" id="edit-tag-modal-description" v-model="description"></textarea>
        </div>
        <div class="modal-form-field">
          <label class="form-label bold">
            Which tags would you like inside this folder?
          </label>
          <p class="description-text">
            Check the loose tags you would like to move into this folder.
            Uncheck the tags you would like to remove from this folder and they will become loose tags.
          </p>
          <fieldset class="unstyled-fieldset">
            <div class="tags-list">
              <li class="no-list-styling">Current Tagged Items</li>
              <div class="tag-groups" v-if="currentTags.length > 0">
                <label
                  v-for="tag in currentTags"
                  :key="tag.id"
                >
                  <input
                    type="checkbox"
                    :value="tag.id"
                    v-model="includeTags"
                  />
                  {{ tag.title }}
                </label>
              </div>
              <div class="tag-groups empty" v-else>No tags in this folder</div>
              <li class="no-list-styling">Loose Tags</li>
              <div v-if="looseTags.length > 0">
                <label
                  v-for="tag in looseTags"
                  :key="tag.id"
                >
                  <input
                    type="checkbox"
                    :value="tag.id"
                    v-model="includeTags"
                  />
                  {{ tag.title }}
                </label>
              </div>
              <div class="empty" v-else>No loose tags</div>
            </div>
          </fieldset>
        </div>
        <div class="modal-form-field">
          <fieldset class="unstyled-fieldset">
            <div class="tags-list tag-groups-list" v-if="otherTagGroups.length > 0">
              <label for="existing-tag-field" class="form-label bold">Other Tag Folders</label>
              <p class="description-text">
                Check the tags that exist in a seperate folder to move them from their current folder into this one.
              </p>
              <div class="tag-groups"
                v-for="group in otherTagGroups"
                :key="group.id"
              >
                <li class="no-list-styling">{{ group.title }}</li>
                <div v-if="group.items.length > 0">
                  <label
                    v-for="tag in group.items"
                    :key="tag.id"
                  >
                    <input
                      type="checkbox"
                      :value="tag.id"
                      v-model="includeTags"
                    />
                    {{ tag.title }}
                  </label>
                </div>
                <div class="empty" v-else>No tags in this folder</div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="handleSave" :disabled="!allowedToSave">Save</button>
    </template>
  </ls-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      title: this.group.title,
      description: this.group.description,
      currentTags: this.group.items,
      includeTags: [],
    };
  },
  computed: {
    ...mapState("template", {
      allChecklistItems: "allChecklistItems",
      checklistItems: "checklistItems",
      allChecklistGroups: "allChecklistGroups",
      checklistGroups: "checklistGroups",
    }),
    allowedToSave() {
      let title = this.title;
      let saving = this.saving;

      return title.trim() != "" && !saving;
    },
    looseTags() {
      return this.allChecklistItems.filter((ci) => ci.template_id==this.template.id && ci.is_default==true);
    },
    otherTagGroups() {
      return this.allChecklistGroups.filter((ci) => ci.template_id==this.template.id && !ci.is_default && ci.id != this.group.id)
    }
  },
  methods: {
    ...mapActions("template", {
      updateTemplateChecklistGroup: "updateTemplateChecklistGroup",
      getAllTemplateChecklistItems: "getAllTemplateChecklistItems",
    }),
    async handleSave() {
      this.saving = true;
      const newGroup = Object.assign({}, this.group, {
        title: this.title,
        description: this.description,
        is_default: false,
        items: this.includeTags
      });
      await this.updateTemplateChecklistGroup({ group: newGroup });
      this.getAllTemplateChecklistItems();
      this.$emit("updated-tag");
    },
  },
  mounted() {
    var tagIds = []
    for (var tag in this.currentTags) {
      tagIds.push(this.currentTags[tag]["id"])
    }
    this.includeTags = this.includeTags.concat(tagIds)
  }
};
</script>
