import { computed, onUnmounted, ref, Ref } from "@vue/composition-api";
import { useRoute, useRouter, useStore } from "shared/composables";

type DraftingStoreData = {
  isDataReady: Ref<boolean>;
};

export function useGuestStoreData(slug: string): DraftingStoreData {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const isDataReady = ref(false);

  const currentEmail = computed(() => store.state.drafting.currentEmail);
  const currentDraft = computed(() => store.state.drafting.currentDraft);

  const currentTemplate = computed(() => store.state.template.currentTemplate);
  const checklistItems = computed(() => store.state.template.checklistItems);
  const checklistGroups = computed(() => store.state.template.checklistGroups);

  store.dispatch("drafting/setInGuestFeedback", true);
  onUnmounted(() => store.dispatch("drafting/setInGuestFeedback", false));

  if (!store.state.loggedIn) {
    store.dispatch("setGuest", true);
    store.dispatch("setRouteAfterLogin", route.fullPath);
  } else {
    store.dispatch("setGuest", false);
    store.dispatch("setRouteAfterLogin", null);
  }

  store.dispatch(
    "updateAlert",
    "Thanks for reviewing my draft, can you read my draft and then provide feedback in the comments below."
  );

  const loadTemplate = async (templateId: number) => {
    if (checklistItems.value.length === 0 || !currentTemplate.value) {
      await store.dispatch("template/getTemplate", templateId);
    }
  };

  const loadData = async () => {
    store.dispatch("showLoader", "Loading checklist items");

    await store.dispatch("drafting/getDraftWithFeedback", slug);
    const payload = await store.dispatch("drafting/getGuestLoginPayload", slug);

    if (!currentDraft.value) {
      await store.dispatch("drafting/getDraft", slug);
    }
    if (!currentEmail.value) {
      await store.dispatch("drafting/initEmail", slug);
    }

    if (payload) {
      store.dispatch("guestLogin", payload);
      await store.dispatch("template/getChecklistGroupsByTemplate", {
        templateId: currentEmail.value.template_id,
        isGuest: true,
      });
      await store.dispatch("template/getChecklistItemsByTemplate", {
        templateId: currentEmail.value.template_id,
        isGuest: true,
      });
    } else {
      await store.dispatch("template/getChecklistGroupsByTemplate", {
        templateId: currentEmail.value.template_id,
      });
      await store.dispatch("template/getChecklistItemsByTemplate", {
        templateId: currentEmail.value.template_id,
      });
    }
    await loadTemplate(currentEmail.value.template_id);
  };

  loadData()
    .then(() => (isDataReady.value = true))
    .catch(() => {
      store.dispatch("showSnackbar", "❌ You don't have access to this template.");
      router.push({ name: "Dashboard" });
    })
    .finally(() => store.dispatch("hideLoader"));

  return { isDataReady };
}

// export default useStoreData;
