<template>
  <div class="container column flex vc hc text-small">
    <p v-if="errorMessage !== null">{{ message }}</p>
    <p v-else>{{ errorMessage }}</p>
  </div>
</template>

<script>
import AuthService from "services/auth";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    message: {
      type: String,
      default: "Please wait…",
    },
  },
  data() {
    return {
      errorMessage: null,
    };
  },
  computed: {
    ...mapState(["loggedIn"]),
  },

  mounted() {
    let destination = { name: "Dashboard" };
    let nextUrl = this.$route.query.next;
    if (nextUrl) {
      destination = { path: nextUrl };
    }

    if (this.loggedIn) {
      this.$router.replace(destination);
    } else {
      AuthService.loginSuccess()
        .then((response) => this.login(response.data))
        .then(() => {
          this.$router.replace(destination);
        })
        .catch((error) => {
          this.errorMessage = error.toString();
        });
    }
  },

  methods: {
    ...mapActions(["login"]),
  },
};
</script>
