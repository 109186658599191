<template>
  <div class="container">
    <a class="skip-to-main" href="#main">Skip to main</a>
    <span class="visually-hidden" aria-live="polite"> New page: {{ pageHeadingOne }} </span>
    <router-view> </router-view>
    <screen-loader></screen-loader>
    <snackbar></snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageHeadingOne: "",
    };
  },
  watch: {
    $route(to) {
      this.pageHeadingOne = to.name;
    },
  },
};
</script>
