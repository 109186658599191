<template>
  <div class="container external-page layout-not-logged-in">
    <et-header class="external" />
    <div class="container">
      <div class="narrow-container">
        <h1 class="serif text-large text-highlight-primary">Set your account password.</h1>
        <form @submit.prevent="submitConfirmation" class="text-center">
          <input
            type="password"
            class="input"
            autocomplete="current-password"
            placeholder="Password"
            v-model="password1"
          />
          <input
            type="password"
            class="input"
            autocomplete="current-password"
            placeholder="Confirm Password (same as above)"
            v-model="password2"
          />
          <button type="submit" class="button text-small" :disabled="!password1 || !password2">
            Complete Account Setup
          </button>
          <p v-if="errorMessage" class="error-text-lighter text-small">{{ errorMessage }}</p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AuthService from "services/auth";

export default {
  props: {
    uidb64: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      password1: "",
      password2: "",
      errorMessage: "",
    };
  },
  computed: {
    userId() {
      return atob(this.uidb64);
    },
  },
  methods: {
    ...mapActions(["login", "setRouteAfterLogin"]),

    submitConfirmation() {
      let data = {
        user_id: this.userId,
        password1: this.password1,
        password2: this.password2,
        token: this.token,
      };

      AuthService.confirmRegistration(data)
        .then((response) => {
          let result = response.data;

          if (response.status == 200) {
            this.login({
              token: result.token,
              refresh: result.refresh,
              user: result.user,
            });
            this.$router.push({ name: "Dashboard" });
          } else {
            if (result.token) {
              this.errorMessage = "The signup link is invalid";
            } else {
              this.errorMessage = result.non_field_errors.join(", ");
            }
          }
        })
        .catch((response) => {
          let result = response.data;

          if (result.token) {
            this.errorMessage = "The signup link is invalid";
          } else {
            this.errorMessage = result.non_field_errors.join(", ");
          }
        });
    },
  },
};
</script>
