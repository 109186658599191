<template>
  <div class="container flex column">
    <et-header>
      <burger></burger>
    </et-header>
    <div class="container interior flex flex-fill">
      <div class="email-sidebar-desktop">
        <nav class="email-sidebar account">
          <ul class="no-list-styling">
            <li class="no-hover">
              <img class="back" src="/static/images/icons/back.png" alt="" />
              <router-link class="accent-yellow" :to="{ name: 'Dashboard' }">
                Back To Dashboard
              </router-link>
            </li>
            <li class="active">Account</li>
          </ul>
        </nav>
      </div>
      <slide-out-side-nav>
        <div class="email-sidebar account">
          <ul class="no-list-styling">
            <li class="no-hover">
              <img class="back" src="/static/images/icons/back.png" />
              <router-link class="accent-yellow" :to="{ name: 'Dashboard' }">
                Back To Dashboard
              </router-link>
            </li>
            <li class="active">Account</li>
          </ul>
        </div>
      </slide-out-side-nav>
      <main id="main" class="container-min flex column admin-wrapper">
        <div class="breadcrumb-navigation">
          <router-link class="button-link" :to="{ name: 'Dashboard' }">Dashboard</router-link>
          <img alt="" src="/static/images/icons/arrow-dark-blue.png" />
          <p class="current-location">Account</p>
        </div>
        <div class="admin-header">
          <h1 class="title serif">Account</h1>
        </div>
        <div class="admin-content account">
          <div class="admin-item picture">
            <div class="bold text-med">Picture</div>
            <div class="account-input-item">
              <img :src="user.picture || defaultUserImgSrc" alt="" class="user-picture" />
              <label for="user-picture-upload" class="button-link image-upload"> Upload Photo </label>
            </div>
            <input
              type="file"
              accept=".png,.jpg,.jpeg"
              name="user-picture-upload"
              value="user-picture-upload"
              id="user-picture-upload"
              class="visually-hidden"
              @change="uploadPicture"
            />
          </div>
          <div class="admin-item">
            <div class="bold text-med">Name</div>
            <div class="account-input-item">
              <div>{{ user.name }}</div>
              <a class="button-link" href="#" @click="isUpdateNameModalVisible = true">Update Name</a>
            </div>
          </div>
          <div class="admin-item">
            <div class="bold text-med">Email</div>
            <div class="account-input-item">
              {{ user.email }}
            </div>
          </div>
          <div class="admin-item" v-if="!user.is_google_account">
            <div class="bold text-med">Password</div>
            <div class="account-input-item">
              <div>•••••••</div>
              <a href="#" @click="isResetPasswordModalVisible = true">Change Password</a>
            </div>
          </div>
          <canvas-account-widget />
        </div>
      </main>
    </div>

    <reset-password-modal
      :visible="isResetPasswordModalVisible"
      @close="isResetPasswordModalVisible = false"
    ></reset-password-modal>

    <update-name-modal
      :visible="isUpdateNameModalVisible"
      @close="isUpdateNameModalVisible = false"
    ></update-name-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import UserService from "services/user";

import ResetPasswordModal from "../components/ResetPasswordModal.vue";
import UpdateNameModal from "../components/UpdateNameModal.vue";
import CanvasAccountWidget from "../components/CanvasAccountWidget.vue";

export default {
  name: "Account",

  components: {
    ResetPasswordModal,
    UpdateNameModal,
    CanvasAccountWidget,
  },

  data() {
    return {
      errorMessage: "",
      isUpdateNameModalVisible: false,
      isResetPasswordModalVisible: false,
    };
  },

  computed: {
    ...mapState(["user", "defaultUserImgSrc"]),
    userImgSrc() {
      return this.user.picture || this.defaultUserImgSrc;
    },
  },

  beforeCreate() {
    UserService.getMe()
      .then((response) => {
        if (response.data.success) {
          this.updateUser(response.data.data);
        }
      })
      .catch((err) => {
        this.errorMessage = err.data.error;
      });
  },

  methods: {
    ...mapActions(["updateUser", "updateUserPicture", "showSnackbar"]),

    uploadPicture(event) {
      const file = event.target.files[0];
      const userPictureForm = new FormData();
      userPictureForm.append("email", this.user.email);
      userPictureForm.append("picture", file);

      UserService.updatePicture(userPictureForm)
        .then((response) => {
          if (response.data.success) {
            let imgSrc = response.data.data.picture;
            let img = new Image();
            img.src = `${imgSrc}?time=${new Date().getTime()}`;
            img.onload = () => {
              this.updateUserPicture(img.src);
              this.showSnackbar("Successfully updated your image!");
            };
          } else {
            this.errorMessage = response.data.error;
          }
        })
        .catch((err) => {
          this.errorMessage = err.data.error;
        });
    },
  },
};
</script>
