import { TypedEmitter } from "tiny-typed-emitter";
import Delta from "quill-delta";
import Quill, { QuillOptionsStatic } from "quill";
import Attributor from "parchment/src/attributor/attributor";
import { Blot } from "parchment/src/blot/abstract/blot";
import ImageEdit from "quill-image-edit-module";
import { merge } from "lodash";
import { sanitize } from "dompurify";

import { Range, CoreEditor, CoreEditorEvents, CoreEditorConfig } from "libs/editors/core";
import {
  TaggableConfig,
  TaggableEvents,
  TaggableExtension,
} from "../extensions/taggable.extension";
import { HighlightableExtension, HighlightColor } from "../extensions/highlightable.extension";
import { EmailTag, TagList } from "../../checklist";

interface QuillModule {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defs: { [key: string]: any };
}

const DEFAULT_QUILL_OPTIONS = {
  theme: "snow",
  boundary: document.body,
  modules: {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] as string[] }, { background: [] as string[] }],
      [{ font: [] as string[] }],
      [{ align: [] as string[] }],
      ["clean"],
      ["link", "image", "video"],
    ],
    imageEdit: {
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
    clipboard: {
      matchVisual: false,
    },
  },
  placeholder: "Insert text here ...",
  readOnly: false,
};

interface QuillEditorConfig extends CoreEditorConfig, TaggableConfig {
  options?: QuillOptionsStatic;
}

interface QuillEditorEvents extends CoreEditorEvents, TaggableEvents {}

export class QuillEditor
  extends TypedEmitter<QuillEditorEvents>
  implements CoreEditor, TaggableExtension, HighlightableExtension
{
  private quill;
  private modules: QuillModule[] = [{ path: "modules/imageEdit", defs: ImageEdit }];
  private attributors: Attributor[] = [];
  private blots: Blot[] = [];

  private text: string;
  private html: string;
  tagList: TagList;
  toBeDeletedTagIds: number[] = [];

  constructor(initConfig: QuillEditorConfig) {
    super();
    // The order of method calls matters! DON'T CHANGE RANDOMLY!
    this.registerHighlighting();

    this.modules.forEach((mod) => Quill.register(mod.path, mod.defs));
    this.attributors.forEach((attributor) => Quill.register(attributor));
    this.blots.forEach((blot) => Quill.register(blot));

    const { container, enabled, options, text, html, tags, label } = initConfig;
    const localOptions = merge(DEFAULT_QUILL_OPTIONS, options);

    this.quill = new Quill(container, localOptions);
    this.quill.root.setAttribute("role", "textbox");
    this.quill.root.setAttribute("aria-multiline", "true");
    if (label) {
      this.quill.root.setAttribute("aria-label", label);
    } else {
      this.quill.root.setAttribute("aria-label", "Rich Text Editor");
    }
    this.quill.clipboard.addMatcher("span[style]", (node, delta) => {
      // Removes inline styles when content is pasted from Google docs or similar.
      return delta.compose(new Delta().retain(delta.length(), {
        background: null, color: null
      }));
    });
    this.init(enabled, text, html);
    this.setTagList(tags);
  }

  init(enabled: boolean, text?: string, html?: string): void {
    if (text) {
      this.setText(text);
    } else if (html) {
      this.setHTML(html);
    }
    this.quill.enable(enabled);

    // EVENTS

    this.quill.on("selection-change", (range) => {
      if (!range) {
        this.emit("blur", this.quill);
      } else if (range.length === 0) {
        this.emit("focus", this.quill);
      } else if (range.length > 0) {
        this.emit("selected", {
          position: {
            start: this.quill.getBounds(range.index),
            end: this.quill.getBounds(range.index + range.length),
          },
          range,
        });
      }
    });

    this.quill.on("text-change", (delta, oldDelta, source) => {
      let html = this.getHTML();
      this.text = this.quill.getText();
      if (html === "<p><br></p>") html = "";
      this.html = html;
      this.emit("input", this.html);

      this.updateAllCurrentTags(delta);

      if (source === "user") {
        // Programmatic updates(source === "api") should not trigger event emission
        // User updates should trigger HTTP calls to the backend
        this.emit("text-change", { delta, oldDelta, source, html });
        this.emitTagUpdates();
      }
    });

    this.emit("ready", this.quill);
  }

  // SETTERS

  setText(content: string): void {
    this.quill.setText(content);
  }

  setHTML(html: string): void {
    const cleanHTML = sanitize(html);
    this.quill.clipboard.dangerouslyPasteHTML(cleanHTML);
  }

  replaceText(range: Range, replacedText: string): void {
    this.quill.updateContents(
      new Delta().retain(range.index).delete(range.length).insert(replacedText)
    );
  }

  // GETTERS

  getText(start: number, end: number): string {
    return this.quill.getText(start, end - start);
  }

  getTextByRange(range: Range): string {
    const { index, length } = range;
    return this.quill.getText(index, length);
  }

  getHTML(): string {
    return this.quill.root.innerHTML;
  }

  // EDITOR BEHAVIORS

  blur(): void {
    this.quill.blur();
  }

  focus(): void {
    this.quill.focus();
  }

  select(start: number, end: number): void {
    this.quill.setSelection(start, end - start);
  }

  enable(): void {
    this.quill.enable(true);
  }

  disable(): void {
    this.quill.enable(false);
  }

  // TAGGING-RELATED METHODS

  setTagList(tags: EmailTag[]): void {
    this.tagList = new TagList(tags);
  }

  updateAllCurrentTags(delta: Delta): void {
    const position = delta.ops.filter((op) => op.retain).pop()?.retain ?? 0;

    // handle deletion first because we want to remove tags eagerly
    // i.e. we want to remove a tag when it's text gets replaced
    delta.ops
      .filter((op) => op.delete)
      .map((op) => this.updateTagsPositions(position, op.delete, true));

    delta.ops.forEach((change) => {
      if (change.insert && typeof change.insert === "string") {
        this.updateTagsPositions(position, change.insert.length, false);
      }
    });

    // "toBeDeletedTags" should have been deleted by the time the updating request is sent
    // so we avoid updating them by excluding them from "tags-changed" event payload, i.e. this.tagList
    this.toBeDeletedTagIds.forEach((tagId) => {
      this.tagList.removeById(tagId);
    });
  }

  private emitTagUpdates() {
    if (this.toBeDeletedTagIds.length > 0) {
      this.emit("tags-deleted", this.toBeDeletedTagIds);
      // optimistically assume that tags will be deleted successfully
      this.toBeDeletedTagIds = [];
    }
    // this.tagList now contains only shifted tags and no deleted tags
    this.emit("tags-changed", this.tagList);
  }

  /**
   * Update tags' start and end based on editing position and length of content change
   * @param {Number} position - The position where editing happens
   * @param {Number} lengthOfChange - The change of content length, can be negative or positive
   * @param {Boolean} isDeletion - Whether or not the update is a deletion operation
   */
  updateTagsPositions(position: number, lengthOfChange: number, isDeletion: boolean): void {
    if (!isDeletion) {
      // Update the edited tags and shift tags that come after the edited tag
      const insertedTag = this.tagList.atPosition(position);
      const shiftedTags = this.tagList.afterPosition(position);

      insertedTag.tags.forEach((tag) => {
        const newStart = tag.start;
        const newEnd = tag.end + lengthOfChange;
        this.updateTag(tag, newStart, newEnd);
      });

      shiftedTags.tags.forEach((tag) => {
        const newStart = tag.start + lengthOfChange;
        const newEnd = tag.end + lengthOfChange;
        this.updateTag(tag, newStart, newEnd);
      });
    } else {
      const deletionStart = position;
      const deletionEnd = position + lengthOfChange;

      // Update tags that get deleted in the middle
      const partiallyDeletedAtMiddleTags = this.tagList
        .atPosition(deletionStart)
        .atPosition(deletionEnd);

      // Update tags that gets partially deleted at the end
      const partiallyDeletedAtEndTags = this.tagList
        .atPosition(deletionStart)
        .notAtPosition(deletionEnd);

      // Update tags that get deleted if there's any
      const deletedTags = this.tagList.inRange(deletionStart, deletionEnd);

      // Update tags that gets partially deleted at the beginning
      const partiallyDeletedAtStartTags = this.tagList
        .atPosition(deletionEnd)
        .notAtPosition(deletionStart);

      // update tags that get shifted only
      const shiftedTags = this.tagList.afterPosition(deletionEnd);

      if (partiallyDeletedAtMiddleTags.tags.length > 0) {
        partiallyDeletedAtMiddleTags.tags.forEach((tag) => {
          const newStart = tag.start;
          const newEnd = tag.end - lengthOfChange;
          this.updateTag(tag, newStart, newEnd);
        });
      }

      if (partiallyDeletedAtEndTags.tags.length > 0) {
        partiallyDeletedAtEndTags.tags.forEach((tag) => {
          const newStart = tag.start;
          const newEnd = deletionStart;
          this.updateTag(tag, newStart, newEnd);
        });
      }

      if (deletedTags.tags.length > 0) {
        deletedTags.tags.forEach((tag) => {
          this.updateToBeDeletedTagsIds(tag.id);
        });
      }

      if (partiallyDeletedAtStartTags.tags.length > 0) {
        partiallyDeletedAtStartTags.tags.forEach((tag) => {
          const newStart = deletionStart;
          const newEnd = tag.end - lengthOfChange;
          this.updateTag(tag, newStart, newEnd);
        });
      }

      if (shiftedTags.tags.length > 0) {
        shiftedTags.tags.forEach((tag) => {
          const newStart = tag.start - lengthOfChange;
          const newEnd = tag.end - lengthOfChange;
          this.updateTag(tag, newStart, newEnd);
        });
      }
    }
  }

  updateTag(tag: EmailTag, newStart: number, newEnd: number): void {
    const range = {
      index: newStart,
      length: newEnd - newStart,
    };

    const newContent =
      range.length > 0 ? this.quill.getText(range.index, range.length) : this.quill.getText(0);

    const updatedTag = {
      ...tag,
      start: newStart,
      end: newEnd,
      content: newContent,
    };

    this.tagList.updateById(updatedTag);
  }

  updateToBeDeletedTagsIds(tagId: number): void {
    this.toBeDeletedTagIds.push(tagId);
  }

  // HIGHLIGHTING
  registerHighlighting(): void {
    const Parchment = Quill.import("parchment");
    const config = { scope: Parchment.Scope.INLINE };
    const HighlightClass = new Parchment.Attributor.Class("highlight", "highlight", config);
    this.attributors.push(HighlightClass);
  }

  highlightText(start: number, end: number, color: HighlightColor): void {
    this.quill.formatText(start, end - start, { highlight: `bg-${color}` });
  }

  unhighlightText(start: number, end: number): void {
    this.quill.formatText(start, end - start, { highlight: false });
  }

  removeAllHighlighting(): void {
    this.unhighlightText(0, this.quill.getLength());
  }

  highlightTags(tags: EmailTag[], oldTags: EmailTag[] = []): void {
    oldTags.forEach((tag) => {
      this.unhighlightText(tag.start, tag.end);
    });
    tags.forEach((tag) => {
      this.highlightText(tag.start, tag.end, tag.color);
    });
  }
}
