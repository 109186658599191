export function computeWordCount(text: string): number {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, "text/html");

  const leafElements = Array.from(doc.body.getElementsByTagName("*"))
    .filter(el => !el.firstElementChild);

  const wordCounts = leafElements.map(el => {
    const words = (el.textContent || "").trim().split(/\s+/);
    return words.length;
  });

  return wordCounts.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}