<template>
  <div class="container external-page layout-not-logged-in">
    <et-header class="external"></et-header>
    <div class="container">
      <div class="narrow-container">
        <h1>You've been invited to join the {{ template.name }} template</h1>
        <form @submit.prevent="submitSignup">
          <input class="input" placeholder="Full name" v-model="signupForm.name" />
          <input
            type="password"
            class="input"
            placeholder="Password"
            v-model="signupForm.password"
          />
          <button type="submit" class="button primary fill full" :disabled="$v.signupForm.$error">
            Sign up now
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import router from "~/router";
import AuthService from "services/auth";

export default {
  props: {
    token: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      errorMessage: "",
      template: null,
      user: null,
      signupForm: {
        name: "",
        password: "",
      },
    };
  },

  created() {
    this.$v.$touch();
    this.$store.dispatch("showLoader", "Retrieving signup information");
    AuthService.validateInviteToken(this.token)
      .then(({ data: result }) => {
        if (result.success) {
          this.template = result.data.template;
          this.user = result.data.user;
          this.$store.dispatch("hideLoader");
        } else {
          this.$router.push({ name: "Signup " });
          this.$store.dispatch("hideLoader");
        }
      })
      .catch(() => {
        this.$router.push({ name: "Signup " });
        this.$store.dispatch("hideLoader");
      });
  },

  methods: {
    ...mapActions(["login"]),

    submitSignup() {
      this.errorMessage = "";

      AuthService.inviteRegister({
        user_id: this.user.id,
        name: this.signupForm.name,
        password: this.signupForm.password,
      })
        .then(({ data: result }) => {
          if (result.success) {
            this.login({
              token: result.data.token,
              user: result.data.user,
            });
            router.push({
              name: "Dashboard",
            });
          } else {
            this.errorMessage = result.error;
          }
        })
        .catch((errorResponse) => {
          this.errorMessage = errorResponse.data.error;
        });
    },

    selectType() {},
  },

  validations: {
    signupForm: {
      name: {
        required,
      },

      password: {
        required,
      },
    },
  },
};
</script>
