<template>
  <ls-modal @close="$emit('close')" :visible="true" :large="true" class="onboarding-modal">
    <template #content>
      <h2 id="dialog_label" class="text-med">Drafts</h2>
      <div class="onboarding-section">
        <h3 class="onboarding-title bold">Description</h3>
        <ul v-if="$can('change', template)" class="no-list-styling">
          <li>Click the "Compose New Draft" button to preview how the drafting interface will look for participants.</li>
        </ul>
        <ul v-else class="no-list-styling">
          <li>In the Drafts tab, you can create new drafts by clicking the "Compose New Draft" button. You also have the ability to edit or delete existing drafts.</li>
          <li>Once you begin drafting, use the Checklist of tags to help guide your writing. Your instructors have included examples so you can see how others might have tagged similar writing.</li>
        </ul>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="$emit('close')">Got It</button>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
};
</script>
