<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 class="text-med">Edit Tag</h2>
      <div class="modal-form-field">
        <label for="edit-tag-modal-title" class="form-label bold">Title</label>
        <input class="input" id="edit-tag-modal-title" type="text" v-model="title" />
      </div>
      <div class="modal-form-field">
        <label for="edit-tag-modal-description" class="form-label bold">Description</label>
        <textarea class="input" id="edit-tag-modal-description" v-model="description"></textarea>
      </div>
      <div class="modal-form-field">
        <label for="requirements-fieldset" class="form-label bold">Requirements</label>
        <p class="description-text">
          You can determine if you would like to make this tag required in order for writers to move
          forward with their draft.
        </p>
        <fieldset class="unstyled-fieldset" id="requirements-fieldset">
          <label>
            <input type="checkbox" v-model="required" />
            Make this tag required
          </label>
        </fieldset>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="handleSave" :disabled="!allowedToSave">Save</button>
    </template>
  </ls-modal>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      title: this.tag.title,
      description: this.tag.description,
      required: this.tag.required,
    };
  },
  computed: {
    allowedToSave() {
      let title = this.title;
      let saving = this.saving;

      return title.trim() != "" && !saving;
    },
  },
  methods: {
    ...mapActions("template", { updateTemplateChecklistItem: "updateTemplateChecklistItem" }),
    async handleSave() {
      this.saving = true;
      const newItem = Object.assign({}, this.tag, {
        title: this.title,
        description: this.description,
        required: this.required,
      });
      await this.updateTemplateChecklistItem({ item: newItem });
      this.$emit("updated-tag");
    },
  },
};
</script>
