<template>
  <li>
    <div class="row-item">{{ member.name }}</div>
    <div class="row-item">{{ member.email }}</div>
    <div class="row-item">{{ member.role }}</div>
    <div class="row-item table-actions">
      <button
        ref="editButton"
        class="button-link edit"
        v-if="canEdit"
        @click="$emit('edit', member)"
        aria-haspopup="dialog"
      >
        Edit
      </button>
      <button
        class="button-link delete"
        v-if="canDelete"
        ref="deleteButton"
        @click="$emit('delete', member)"
        aria-haspopup="dialog"
      >
        Delete
      </button>
      <div class="empty-text" v-else>No Actions Available</div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    focusDeleteButton() {
      this.$refs.deleteButton.focus();
    },
  },
};
</script>
