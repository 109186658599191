<template>
  <div class="draft-container flex-fill">
    <div class="left-panel">
      <nav aria-label="Primary navigation">
        <drafting-side-bar class="mobile-hidden tablet-hidden email-sidebar draft-navigation">
          <li
            :class="{
              active: checkComponentActive(activeLeftComponent, 'ChecklistView'),
            }"
            class="mobile-hidden tablet-hidden nav-item no-list-styling"
          >
            <button
              class="button-icon checklist-icon"
              aria-label="Toggle Checklist"
              @click="loadLeftComponent('ChecklistView')"
              :aria-expanded="checkComponentActive(activeLeftComponent, 'ChecklistView') ? 'true' : 'false'"
              aria-controls="checklist-panel"
            >
            </button>
            <span class="nav-title">{{ checklistName }}</span>
          </li>
          <li
            v-if="showDescriptionPanel"
            :class="{
              active: checkComponentActive(activeLeftComponent, 'DescriptionView'),
            }"
            class="nav-item no-list-styling"
          >
            <button
              class="button-icon description-icon"
              aria-label="Toggle Description"
              @click="loadLeftComponent('DescriptionView')"
              :aria-expanded="checkComponentActive(activeLeftComponent, 'DescriptionView') ? 'true' : 'false'"
              aria-controls="description-panel"
            >
            </button>
            <span class="nav-title">Description</span>
          </li>
        </drafting-side-bar>
      </nav>
      <transition name="slide-fade" mode="out-in">
        <keep-alive>
          <component
            v-if="activeLeftComponent"
            :is="activeLeftComponent"
            class="mobile-hidden tablet-hidden"
          ></component>
        </keep-alive>
      </transition>
    </div>

    <editor-view ref="editorView"> </editor-view>

    <div class="right-panel">
      <transition name="slide-fade-right" mode="out-in">
        <keep-alive>
          <component v-if="activeRightComponent" :is="activeRightComponent"></component>
        </keep-alive>
      </transition>
      <nav aria-label="Secondary navigation">
        <drafting-side-bar class="email-sidebar draft-navigation">
          <li
            :class="{
              active: checkComponentActive(activeRightComponent, 'ChecklistView'),
            }"
            class="nav-item no-list-styling smaller-screen-only"
          >
            <button
              class="button-icon checklist-icon"
              aria-label="Toggle Checklist"
              @click="loadRightComponent('ChecklistView')"
              :aria-expanded="checkComponentActive(activeRightComponent, 'ChecklistView') ? 'true' : 'false'"
              aria-controls="checklist-panel"
            >
            </button>
            <span class="nav-title">{{ checklistName }}</span>
          </li>
          <li
            v-if="showDescriptionPanel"
            :class="{
              active: checkComponentActive(activeRightComponent, 'DescriptionView'),
            }"
            class="nav-item no-list-styling smaller-screen-only"
          >
            <button
              class="button-icon description-icon"
              aria-label="Toggle Description"
              @click="loadRightComponent('DescriptionView')"
              :aria-expanded="checkComponentActive(activeRightComponent, 'DescriptionView') ? 'true' : 'false'"
              aria-controls="description-panel"
            >
            </button>
            <span class="nav-title">Description</span>
          </li>
          <li
            :class="{
              active: checkComponentActive(activeRightComponent, 'ExampleView'),
            }"
            class="nav-item no-list-styling"
          >
            <button
              class="button-icon examples-icon"
              aria-label="Toggle Examples"
              @click="loadRightComponent('ExampleView')"
              :aria-expanded="checkComponentActive(activeRightComponent, 'ExampleView') ? 'true' : 'false'"
              aria-controls="examples-panel"
            >
            </button>
            <span class="nav-title">Examples</span>
          </li>
          <li
            :class="{
              active: checkComponentActive(activeRightComponent, 'CommentView'),
            }"
            class="nav-item no-list-styling"
          >
            <button
              class="button-icon comments-icon"
              aria-label="Toggle Comments"
              @click="loadRightComponent('CommentView')"
              :aria-expanded="checkComponentActive(activeRightComponent, 'CommentView') ? 'true' : 'false'"
              aria-controls="comments-panel"
            >
            </button>
            <span class="nav-title">Comments</span>
          </li>
        </drafting-side-bar>
      </nav>
    </div>
  </div>
</template>

<script>
import { computed, ref, onUnmounted } from "@vue/composition-api";

import ChecklistView from "../components/ChecklistView.vue";
import CommentView from "../components/CommentView.vue";
import DescriptionView from "../components/DescriptionView.vue";
import DraftingSideBar from "../components/DraftingSideBar.vue";
import EditorView from "../components/EditorView.vue";
import ExampleView from "../components/ExampleView.vue";

import { useStore } from "shared/composables";

export default {
  name: "Drafting",

  components: {
    EditorView,
    DescriptionView,
    DraftingSideBar,
    ExampleView,
    CommentView,
    ChecklistView,
  },

  props: {},

  setup() {
    const store = useStore();
    const editorView = ref(null);
    const template = computed(() => store.state.template.currentTemplate);
    const showDescriptionPanel = computed(() => template.value.description.trim() !== "");
    const checklistName = computed(() => template.value?.features?.checklist_name ?? "Tags");

    // Left Panel
    let activeLeftComponent = ref(ChecklistView);
    const loadLeftComponent = (componentName) => {
      if (componentName === activeLeftComponent.value?.name) {
        activeLeftComponent.value = null;
      } else {
        switch (componentName) {
          case "ChecklistView":
            activeLeftComponent.value = ChecklistView;
            break;
          case "DescriptionView":
            activeLeftComponent.value = DescriptionView;
            break;
          default:
            break;
        }
      }
    };

    // Right Panel
    let activeRightComponent = ref(ExampleView);
    const loadRightComponent = (componentName) => {
      if (componentName === activeRightComponent.value?.name) {
        activeRightComponent.value = null;
      } else {
        switch (componentName) {
          case "ExampleView":
            activeRightComponent.value = ExampleView;
            break;
          case "CommentView":
            activeRightComponent.value = CommentView;
            break;
          case "DescriptionView":
            activeRightComponent.value = DescriptionView;
            break;
          case "ChecklistView":
            activeRightComponent.value = ChecklistView;
            break;
          default:
            break;
        }
      }
    };

    // UI Logic
    const checkComponentActive = (componentObj, componentName) => {
      return componentObj?.name === componentName;
    };

    // Data Logic
    onUnmounted(async () => {
      store.dispatch("drafting/setIsDisplayingAllTags", true);

      const email = editorView.value.email;
      const draftUpdates = {
        subject: email.subject,
        content: email.content,
      };
      await store.dispatch("drafting/updateOrSaveEmail", draftUpdates);
    });

    return {
      editorView,
      activeLeftComponent,
      loadLeftComponent,
      activeRightComponent,
      loadRightComponent,
      checkComponentActive,
      showDescriptionPanel,
      checklistName,
    };
  },
};
</script>
